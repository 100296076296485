import { Close, DeleteOutline, Edit, Save } from '@mui/icons-material';
import { styled } from '@mui/material';
import InteractiveTable, {
  Row,
  useRowsEdit,
} from 'components/InteractiveTable';
import { TableActions } from 'components/Table';
import { numberToCurrencyString } from 'further-ui/utils';
import useTransactionTypesManagement from 'hooks/lp/data/accounts/useTransactionTypesManagement';

const Actions = styled(TableActions)({
  paddingRight: '24px',
});

const Table = styled(InteractiveTable)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
}));

type TransactionType = {
  id: string;
  accountId: string;
  name: string;
  runningBalance?: number;
  transactionsCount?: number;
};

type Props = {
  accountId: string;
  vehicleId: string;
  transactionTypes: Array<TransactionType>;
};

const TransactionTypesTable: React.FC<Props> = ({
  accountId,
  vehicleId,
  transactionTypes,
}) => {
  const { deleteTransactionType } = useTransactionTypesManagement();
  const { editedRowId, rowsEditModel, editRow, cancelRowEdit, saveRow } =
    useRowsEdit();

  const { updateTransactionType } = useTransactionTypesManagement();

  const handleRowEdit = (row: Row) => {
    updateTransactionType.mutate({
      vehicleId,
      transactionTypeId: row.id ?? '',
      name: row.name,
    });
    return row;
  };

  return (
    <Table
      hideHeader
      fullHeight
      columns={[
        {
          field: 'name',
          editable: true,
          flex: 1,
        },
        {
          field: 'runningBalance',
          flex: 1,
          renderCell: ({ row }) => numberToCurrencyString(row.runningBalance),
        },
        {
          field: 'actions',
          width: 124,
          renderCell: ({ row }) => {
            return (
              <Actions
                actions={
                  editedRowId !== row.id
                    ? [
                        {
                          label: 'Edit',
                          icon: Edit,
                          color: 'primary',
                          onClick: () => editRow(row.id ?? ''),
                        },
                        {
                          disabled: !!row.transactionsCount,
                          tooltip: row.transactionsCount
                            ? 'This transaction type cannot be deleted while it has linked transactions. Please see the General Ledger to review all linked transactions.'
                            : undefined,
                          label: 'Delete',
                          icon: DeleteOutline,
                          color: 'error',
                          onClick: () =>
                            deleteTransactionType.mutate({
                              transactionTypeId: row.id ?? '',
                              vehicleId,
                            }),
                        },
                      ]
                    : [
                        {
                          label: 'Save',
                          icon: Save,
                          color: 'primary',
                          onClick: saveRow,
                        },
                        {
                          label: 'Cancel',
                          icon: Close,
                          color: 'default',
                          onClick: cancelRowEdit,
                        },
                      ]
                }
              />
            );
          },
        },
      ]}
      id={`account-${accountId}-transaction-types-table`}
      rows={transactionTypes ?? []}
      disableTableConfiguration
      disableSorting
      rowsEditModel={rowsEditModel}
      onRowEditSubmit={handleRowEdit}
    />
  );
};

export default TransactionTypesTable;
