import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { useNotification } from 'hooks/ui/useNotification';

interface ArchiveIncompleteInvestmentResponse {
  success: boolean;
}

function useArchiveIncompleteInvestment() {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation({
    mutationFn: async (id: string) => {
      const { data } = await api.delete<
        ApiResponse<ArchiveIncompleteInvestmentResponse>
      >(`investment/incomplete-investment/${id}`);
      return data.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['incomplete-investments'],
        refetchType: 'all',
      });
      notification.success('Subscription archived successfully.');
    },
    onError: () => {
      notification.error('Failed to archive subscription.');
    },
  });
}

export default useArchiveIncompleteInvestment;
