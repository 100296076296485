import { useState, useEffect } from 'react';
import PageContainer from 'components/PageContainer';
import { useGetRole } from 'hooks/ui/useGetRole';
import '@silevis/reactgrid/styles.css';
import ReactGridForm from './ReactGridForm';
import PreviewTable from './PreviewTable';
import FullPageSuccess from 'components/FullPageSuccess';
import { INVESTOR_UPLOAD } from 'adminConstants/routes';
import { useFunds } from 'hooks/data/fund/useFunds';
import { useAdvisers } from 'hooks/data/adviser/useAdvisers';
import { makeStyles } from 'tss-react/mui';
import { useListFirmBankDetails } from 'hooks/data/firm/useFirmBankDetails';
import { firmBankDetailsDisplay } from 'further-ui/utils';
import { FirmSelector } from 'components/FirmSelector';
import { useDisclosure } from 'further-ui/hooks';
import AlertDialog from 'components/AlertDialog';
import PageContent from 'components/PageContent';
import Heading from 'components/Heading';

const STEPS = {
  CREATE_GRID_DATA: 0,
  PREVIEW_UPLOAD: 1,
  UPLOAD_SUCCESS: 2,
};

const Wrapper = ({ children }) => (
  <PageContainer heading="Investors: Upload investors and subscriptions">
    {children}
  </PageContainer>
);

const useStyles = makeStyles()(() => ({
  firmSelector: {
    marginBottom: 24,
    maxWidth: '20rem',
  },
  paragraph: {
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '82rem',
    gap: 8,
  },
}));

const UploadInvestorInvestment: React.FC = () => {
  const { classes } = useStyles();
  const [gridDropdownsData, setGridDropdownsData] = useState({
    tranche: [],
    adviser: [],
  });
  const [activeStep, setActiveStep] = useState(STEPS.CREATE_GRID_DATA);
  const [uploadPreviewState, setUploadPreviewState] = useState({
    totalInvestors: 0,
    existingInvestorAccounts: [],
  });
  const { firmId: usersFirmId, isSuperAdmin } = useGetRole();
  const [firmId, setFirmId] = useState(usersFirmId);
  const firmIdChangeDisclosure = useDisclosure();

  const { tranches } = useFunds({
    firmId,
    fullyDeployed: false,
    disabled: !firmId,
  });
  const { data } = useAdvisers({ params: { firmId } });

  const firmBankDetails = useListFirmBankDetails(firmId);

  useEffect(
    function setDropdownListsData() {
      if (tranches.data && data?.adviser) {
        const tranchesList = tranches.data.map(
          ({ _id, fundName, firmId: { firmName } }) => ({
            label: `${firmName} ${fundName}`,
            value: _id,
          }),
        );

        const advisersList = data.adviser.map(({ _id, fullName, email }) => ({
          label: fullName ? `${fullName} ${email}` : email,
          value: _id,
        }));

        const bankDetailsList = firmBankDetails?.data?.map((bankDetails) => ({
          label: firmBankDetailsDisplay(bankDetails),
          value: bankDetails._id,
        }));

        const listSorter = ({ label: labelA }, { label: labelB }) =>
          labelA.localeCompare(labelB);

        tranchesList.sort(listSorter);
        advisersList.sort(listSorter);
        if (bankDetailsList?.length) bankDetailsList.sort(listSorter);

        setGridDropdownsData((gridDropdownsData) => ({
          ...gridDropdownsData,
          tranche: tranchesList,
          adviser: advisersList,
          new_investor_legal_entity_adviser: advisersList,
          new_investor_adviser: advisersList,
          paid_to: bankDetailsList,
        }));
      }
    },
    [tranches.data, data?.adviser, firmBankDetails?.data],
  );

  const onFirmChange = (newFirmId: string) => {
    if (firmId && firmId !== newFirmId) {
      firmIdChangeDisclosure.stageAction({ newFirmId });
    } else {
      setFirmId(newFirmId);
    }
  };

  const onFirmChangeConfirm = () => {
    setFirmId(firmIdChangeDisclosure.actionProps.newFirmId);
    firmIdChangeDisclosure.onClose();
  };

  const uploadPreviewSet = (uploadPreviewState) => {
    setUploadPreviewState(uploadPreviewState);
    setActiveStep(STEPS.PREVIEW_UPLOAD);
  };

  const uploadSuccessSet = () => {
    setActiveStep(STEPS.UPLOAD_SUCCESS);
  };

  if (activeStep === STEPS.CREATE_GRID_DATA)
    return (
      <Wrapper>
        <PageContent>
          {isSuperAdmin && (
            <div className={classes.firmSelector}>
              <FirmSelector
                onChange={onFirmChange}
                firmId={firmId}
                placeholder="Select a firm"
              />
            </div>
          )}
          {firmId && (
            <>
              <Heading variant="h2">
                Input your investors and subscriptions into the table
              </Heading>
              <div className={classes.paragraph}>
                <p>
                  <b>How to use this feature:</b> The below upload table can be
                  used for uploading one or more investors and subscriptions at
                  the same time. Type or paste information into cells to
                  continue. Leaving this page without submitting can cause
                  entered data to be lost. If an email address is already in use
                  on the platform and the date of birth matches that account,
                  any associated subscriptions will be added to the relevant
                  existing investor's account.
                </p>
              </div>
              <ReactGridForm
                gridDropdownsData={gridDropdownsData}
                uploadPreviewSet={uploadPreviewSet}
              />
            </>
          )}
        </PageContent>
        <AlertDialog
          open={firmIdChangeDisclosure.isOpen}
          onClose={firmIdChangeDisclosure.onClose}
          onConfirm={onFirmChangeConfirm}
          title="Change firm?"
          content="This will reset the values in the tranche, adviser and paid to dropdowns."
        />
      </Wrapper>
    );

  if (activeStep === STEPS.PREVIEW_UPLOAD)
    return (
      <Wrapper>
        <PageContent>
          <PreviewTable
            totalInvestors={uploadPreviewState.totalInvestors}
            existingInvestorAccounts={
              uploadPreviewState.existingInvestorAccounts
            }
            handleSubmitCallback={uploadSuccessSet}
            handleBack={() => setActiveStep(STEPS.CREATE_GRID_DATA)}
            tranches={tranches?.data}
            firmId={firmId}
          />
        </PageContent>
      </Wrapper>
    );

  if (activeStep === STEPS.UPLOAD_SUCCESS) {
    return (
      <Wrapper>
        <FullPageSuccess
          title="Your investors and subscriptions have been uploaded successfully"
          additionalText={`
                Congratulations, these investors and subscriptions have been
                successfully uploaded and are visible within your investor and
                subscription databases.`}
          proceedText="Create another upload"
          proceedLink={INVESTOR_UPLOAD}
          proceedCallback={() => {
            setActiveStep(STEPS.CREATE_GRID_DATA);
          }}
        />
      </Wrapper>
    );
  }

  return null;
};

export default UploadInvestorInvestment;
