import React from 'react';
import { useGetRole } from 'hooks/ui/useGetRole';
import SingleFirmEmailToggle from './SingleFirmEmailToggle';
import MultiFirmEmailToggle from './MultiFirmEmailToggle';
import RecordRow from 'components/RecordRow';
import Spacing from 'components/Spacing';
import DefaultAdviserRow from './DefaultAdviserRow';
import SuperAdminDefaultAdviserRow from './SuperAdminDefaultAdviserRow';

type Props = {
  investorDetail: {
    _id: string;
    requiresHardCopyReports?: boolean;
    dob: string;
    firmSpecificSettings: Array<{
      firmId: string;
      firmName: string;
      disableAllEmails: boolean;
      defaultAdviserId?: string;
    }>;
  };
};

const InvestorCommunication: React.FC<Props> = ({ investorDetail }) => {
  const { firmId, isSuperAdmin } = useGetRole();

  return (
    <Spacing>
      <RecordRow label="System emails">
        {isSuperAdmin ? (
          <MultiFirmEmailToggle
            investorId={investorDetail._id}
            firmSpecificSettings={investorDetail.firmSpecificSettings}
          />
        ) : (
          <SingleFirmEmailToggle
            investorId={investorDetail._id}
            //@ts-expect-error
            firmId={firmId}
            emailsCurrentlyDisabled={
              !!investorDetail.firmSpecificSettings?.[0]?.disableAllEmails
            }
          />
        )}
      </RecordRow>
      <RecordRow label="Requires hard copy reports">
        {investorDetail?.requiresHardCopyReports ? 'Yes' : 'No'}
      </RecordRow>
      <RecordRow label="Default adviser">
        {isSuperAdmin ? (
          <SuperAdminDefaultAdviserRow investorDetail={investorDetail} />
        ) : (
          <DefaultAdviserRow investorDetail={investorDetail} />
        )}
      </RecordRow>
    </Spacing>
  );
};

export default InvestorCommunication;
