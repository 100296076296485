import React from 'react';
import Table, { Column } from 'components/Table';
import ChildRowContent from './ChildRowContent';
import { Api } from 'further-types/investment';
import { TableRowType } from './types';
import { PaginationState } from 'further-types/utils';

interface InvestmentTableProps {
  columns: Array<Column<TableRowType>>;
  data: Api.GetIncompleteInvestmentsResult;
  paginationProps: PaginationState;
  isLoading: boolean;
}

const InvestmentTable: React.FC<InvestmentTableProps> = ({
  columns,
  data,
  paginationProps,
  isLoading,
}) => (
  <Table
    columns={columns}
    onRequestSort={paginationProps.handleRequestSort}
    order={paginationProps.order}
    orderBy={paginationProps.orderBy}
    tablebody={
      data?.result.map((item) => ({
        ...item.mainRecord,
        investorId: item._id.investorId,
        subsequentInvestment: item.subsequentInvestment,
        previousCarts: item.previousCarts,
        actions: null,
      })) || []
    }
    onPageChange={paginationProps.handleChangePage}
    onRowsPerPageChange={paginationProps.handleChangeRowsPerPage}
    page={paginationProps.page}
    rowsPerPage={paginationProps.rowsPerPage}
    count={data?.total ?? 0}
    pagination={true}
    loading={isLoading}
    variant="nohover"
    collapsedContent={(row: TableRowType) => (
      <ChildRowContent previousCarts={row.previousCarts} columns={columns} />
    )}
    maintainExpandedState={true}
    getRowId={(row) => row._id}
  />
);

export default InvestmentTable;
