import { useState, useEffect } from 'react';
import TextField from 'components/FormElements/AppTextInput';
import {
  Delete,
  Edit,
  FileCopy,
  Dvr,
  LinkOutlined,
  CloudDownload,
} from '@mui/icons-material';
import Table, { TableActions } from 'components/Table';
import PageContainer from 'components/PageContainer';
import {
  fundStatusMap,
  fundStatusToLabel,
} from 'adminConstants/fundProperties';
import {
  TRANCHE_ADD,
  createTrancheEditRoute,
  TRANCHE_BESPOKE_LINK,
} from 'adminConstants/routes';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import { useSortTranches } from 'hooks/ui/useSortTranches';
import { useFunds } from 'hooks/data/fund/useFunds';
import AlertDialog from 'components/AlertDialog';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useTags } from 'hooks/data/tag/useTags';
import AppAutocomplete from 'components/FormElements/AppAutocomplete';
import useFilters from 'hooks/ui/useFilters';
import { createTrancheOnboardingLink } from 'helpers/trancheLink/trancheLink';
import { useFund } from 'hooks/data/fund/useFund';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';
import { useNotification } from 'hooks/ui/useNotification';
import { useGetRole } from 'hooks/ui/useGetRole';
export const url = process.env.REACT_APP_STAGE;

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Tranches', isActive: true },
];

const fundStatus = Object.entries(fundStatusToLabel).map(([value, name]) => ({
  name,
  value,
}));

const Tranche = () => {
  const [isExportLoading, setIsExportLoading] = useState(false);
  const exceptionHandler = useApiExceptionHandler();
  const pagination = usePagination({
    id: 'tranches-table',
  });
  const notification = useNotification();

  useEffect(() => {
    const qs = new URLSearchParams(window.location.search);
    const fundStatusParam = qs.get('fundStatus');
    if (fundStatusParam) {
      const foundStatus = fundStatus.find(
        (status) => status.value === fundStatusParam,
      );
      if (foundStatus) {
        handleChangeFilters({ fundStatus: foundStatus });
      }
    }
  }, []);

  const { filters, handleChangeFilters } = useFilters<{
    firmId: { _id: string; firmName: string } | null;
    fundStatus: { value: string; name: string } | null;
    tagId: { _id: string; name: string } | null;
    fundId: { _id: string; label: string } | null;
    startDate?: Date;
    endDate?: Date;
  }>('tranches-table', {
    defaultFilters: {
      firmId: null,
      fundStatus: null,
      tagId: null,
      fundId: null,
      startDate: undefined,
      endDate: undefined,
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const queryParams = {
    firmId: filters?.firmId?._id,
    fundStatus: filters?.fundStatus?.value,
    tagId: filters.tagId?._id,
    fundId: filters.fundId?._id,
    startDate: filters.startDate,
    endDate: filters.endDate,
    includeInvestmentsSummary: true,
    keyName: pagination.orderBy,
    page: pagination.page === 0 ? 1 : pagination.page,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
  };

  const { isSuperAdmin } = useGetRole();

  const { tags } = useTags();
  const { remove, duplicate } = useFund();
  const { tranches } = useFunds(queryParams);
  const { tranches: allTranches, exportFunds } = useFunds({
    leanResponse: true,
  });
  const { exportFund } = useFund();
  const sortedTranches = useSortTranches(allTranches?.data);
  const { firms } = useFirm({ params: {} });

  const [copyFlag, setCopyFlag] = useState(false);
  const quickRanges = setDefinedRanges();
  const deleteDisclosure = useDisclosure();
  const [createPermission, deletePermission, updatePermission] =
    useGetPermissions(['create:tranche', 'delete:tranche', 'edit:tranche']);

  const columns = [
    {
      label: 'Firm name',
      key: 'firmId',
      render: (elm) => elm?.firmId?.firmName,
    },
    {
      label: 'Tranche name',
      key: 'fundName',
      render: (elm) =>
        elm?.tag?.isSyndicate ? `${elm.fundName} (Syndicate)` : elm.fundName,
    },
    {
      label: 'First subscription date',
      key: 'firstInvestmentDate',
      render: (elm) => dateToLabel(elm.firstInvestmentDate),
    },
    {
      label: 'Close date',
      key: 'closedDate',
      render: (elm) => elm.closedDate && dateToLabel(elm.closedDate),
    },
    {
      label: 'Investors',
      key: 'investorCount',
      sort: false,
      render: (elm) => String(elm.investorCount),
    },
    {
      label: 'Total subscription amount',
      key: 'totalInvestment',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.totalInvestment),
    },
    {
      label: 'Status',
      key: 'fundStatus',
      render: (elm) =>
        elm.fundStatus === 1
          ? 'Open'
          : elm.fundStatus === 0
          ? 'Closed'
          : 'Draft',
    },
    {
      label: 'Type',
      key: 'type',
      sort: false,
      render: (elm) => elm?.tag?.[0]?.name,
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              visible: updatePermission,
              link: createTrancheEditRoute(elm._id),
              label: 'Edit',
              icon: Edit,
              color: 'primary',
            },
            {
              label: 'Duplicate',
              icon: FileCopy,
              color: 'primary',
              onClick: () => {
                setCopyFlag(!copyFlag);
                duplicate.mutate(elm._id);
              },
            },
            {
              label: 'Download tranche data',
              icon: CloudDownload,
              color: 'primary',
              onClick: () =>
                exportFund(elm?._id, elm?.fundName, elm?.firmId?.firmName),
            },
            {
              visible:
                elm?.firmId?.domain && elm.fundStatus === fundStatusMap.open,
              label: 'Make custom subscription link',
              icon: LinkOutlined,
              color: 'primary',
              link: {
                pathname: TRANCHE_BESPOKE_LINK,
                state: { trancheId: elm._id },
              },
            },
            {
              visible:
                elm?.firmId?.domain && elm.fundStatus === fundStatusMap.open,
              label: 'Copy tranche URL to clipboard',
              icon: Dvr,
              color: 'primary',
              onClick: () => {
                navigator.clipboard.writeText(createTrancheOnboardingLink(elm));
                notification.success('Tranche link copied to clipboard');
              },
            },
            {
              visible: deletePermission,
              label: 'Delete',
              icon: Delete,
              color: 'error',
              onClick: () => deleteDisclosure.stageAction(elm),
            },
          ]}
        />
      ),
    },
  ];

  const handleConfirmDelete = async () => {
    const { _id } = deleteDisclosure.actionProps || {};
    remove.mutate(_id);
    if (tranches?.data?.length === 1) {
      pagination.pageBack();
    }
    deleteDisclosure.onClose();
  };

  const handleExportFunds = async () => {
    try {
      setIsExportLoading(true);
      await exportFunds({ ...queryParams, includeExportData: true });
    } catch (e: any) {
      exceptionHandler(e, 'Error when exporting subscription transfer', true);
    } finally {
      setIsExportLoading(false);
    }
  };

  return (
    <PageContainer heading="Tranches" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="tranches-table"
          buttons={[
            {
              visible: createPermission,
              link: TRANCHE_ADD,
              label: 'Add Tranche',
            },
            {
              loading: isExportLoading,
              onClick: handleExportFunds,
              label: 'Export tranches',
            },
          ]}
        >
          {isSuperAdmin && (
            <Filter>
              <AppAutocomplete
                id="firmId"
                options={firms.data?.result ?? []}
                getOptionLabel={(option) => option.firmName}
                filterSelectedOptions
                value={filters.firmId}
                onChange={(_, newValue) => {
                  handleChangeFilters({ firmId: newValue });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select firm"
                  />
                )}
              />
            </Filter>
          )}
          <Filter>
            <AppAutocomplete
              id="fundId"
              options={sortedTranches ?? []}
              getOptionLabel={(option) => option.label}
              value={filters.fundId}
              filterSelectedOptions
              onChange={(_, newValue) => {
                handleChangeFilters({ fundId: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Tranche"
                />
              )}
            />
          </Filter>
          <Filter>
            <AppAutocomplete
              name="tagId"
              options={tags?.data ?? []}
              getOptionLabel={(option) => option?.name}
              filterSelectedOptions
              value={filters.tagId}
              onChange={(_, newValue) => {
                handleChangeFilters({ tagId: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Fund type"
                />
              )}
            />
          </Filter>
          <Filter>
            <AppAutocomplete
              id="status"
              options={fundStatus?.reverse()}
              getOptionLabel={(option) => option.name}
              value={filters.fundStatus}
              filterSelectedOptions
              onChange={(_, newValue) => {
                handleChangeFilters({ fundStatus: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select tranche status"
                />
              )}
            />
          </Filter>
          <Filter>
            <MuiDateRangePicker
              dateRange={{
                startDate: filters.startDate,
                endDate: filters.endDate,
              }}
              onDateRangeChange={(data) => {
                handleChangeFilters({
                  startDate: data.startDate,
                  endDate: data.endDate,
                });
              }}
              placeholder="Search by close date"
              definedRanges={quickRanges}
            />
          </Filter>
        </PageFilters>

        <Table
          columns={columns}
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={tranches.data || []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={tranches.total}
          pagination
          loading={tranches.isFetching}
          variant="nohover"
        />

        <AlertDialog
          open={deleteDisclosure.isOpen}
          onClose={deleteDisclosure.onClose}
          onConfirm={handleConfirmDelete}
          title={`Are you sure you want to delete this tranche?`}
          content={
            <p>
              This cannot be undone and all information relating to the tranche
              will be lost.
            </p>
          }
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Delete tranche',
          }}
        />
      </PageContent>
    </PageContainer>
  );
};

export default Tranche;

function setDefinedRanges() {
  const currentYear = new Date().getFullYear();
  const ranges: Array<{ label: string; startDate: Date; endDate: Date }> = [];

  for (let i = 0; i < 7; i++) {
    const year = currentYear - i;
    ranges.push({
      label: `${year}`,
      startDate: new Date(`${year}-01-01`),
      endDate: new Date(`${year}-12-31`),
    });
  }

  return ranges;
}
