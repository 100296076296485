import { FC } from 'react';
import { TextField } from '@mui/material';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import { useFirm } from 'hooks/data/firm/useFirm';

type Firm = {
  _id: string;
  firmName: string;
};

interface Props {
  firmId?: string;
  onChange: (firmId: string, firm: Firm | null) => void;
  placeholder?: string;
  allowDeselection?: boolean;
  deselectionLabel?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  filterFirm?: (firm: Firm) => boolean;
}

export const FirmSelector: FC<Props> = ({
  firmId,
  onChange,
  placeholder = 'Firm',
  filterFirm,
  disabled,
  error,
  helperText,
  ...rest
}) => {
  const { firms } = useFirm({ params: {} });

  const filteredFirms = filterFirm
    ? firms.data?.result?.filter(filterFirm)
    : firms.data?.result;

  return (
    <Autocomplete
      id="firmId"
      options={filteredFirms ?? []}
      getOptionLabel={(option) => option.firmName}
      filterSelectedOptions
      value={filteredFirms?.find((firm) => firm._id === firmId) ?? null}
      disabled={disabled || firms.isLoading || filteredFirms?.length === 0}
      onChange={(_, newValue) => {
        onChange(newValue?._id as string, newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          error={error}
          helperText={helperText}
        />
      )}
      {...rest}
    />
  );
};
