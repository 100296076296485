import React from 'react';
import Table, { TableActions } from 'components/Table';
import commonColumns from 'helpers/withdrawals/commonColumns';
import { numberToCurrencyString } from 'further-ui/utils';
import { AttachFile } from '@mui/icons-material';
import DeleteAction from 'components/Actions/Delete';

type Props = any;

const QueuedWithdrawalsTable: React.FC<Props> = ({
  queuedWithdrawals,
  removeWithdrawalFromQueue,
  firmId,
}) => {
  const columns = [
    ...commonColumns(firmId),
    {
      label: 'Gross withdrawal amount',
      key: 'grossWithdrawalAmount',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm?.withdrawalDetails?.amountToWithdraw),
    },
    {
      label: 'Withdrawal fee',
      key: 'withdrawalFee',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm?.withdrawalDetails?.withdrawalFee),
    },
    {
      label: 'Net withdrawal amount',
      key: 'netWithdrawalAmount',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(
          (elm?.withdrawalDetails?.amountToWithdraw || 0) -
            (elm?.withdrawalDetails?.withdrawalFee || 0),
        ),
    },
    {
      label: 'Notes',
      key: 'note',
      sort: false,
      render: (elm) => elm?.withdrawalDetails?.note || '-',
    },

    {
      label: '',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              visible: !!elm?.withdrawalDetails?.attachment?.signedGetUrl,
              icon: AttachFile,
              link: elm?.withdrawalDetails?.attachment?.signedGetUrl,
              label: 'View attachment',
              external: true,
            },
            {
              actionComponent: DeleteAction,
              onClick: () => removeWithdrawalFromQueue(elm._id),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Table columns={columns} tablebody={queuedWithdrawals} variant="nohover" />
  );
};

export default QueuedWithdrawalsTable;
