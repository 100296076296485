import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DropzoneComponent from 'components/Dropzone';
import FieldRow from 'components/FormElements/FieldRow';
import CkEditor from 'components/CkEditor';
import RecordView from 'components/RecordView';

const useStyles = makeStyles()(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type Props = any;

const ConfirmationEmailCustomisation: React.FC<Props> = ({
  confirmationEmailCustomisation,
  handleCancel,
  handleClickConfirmationEmailCustomisation,
  id,
  handleInputChangeEmailCustomisation,
  disabled = false,
}) => {
  const { classes } = useStyles();

  return (
    <RecordView>
      <FieldRow
        title={`Customise "What to expect now"`}
        centerTitle
        tooltipText="Adding text here will replace the default 'What to expect now' section text into the 'Payment received' system email. Change this if you have a specific process you wish to explain to investors once they have made their subscription payment."
      >
        <CkEditor
          //@ts-expect-error
          maxlength={220}
          onBlur={(_, editor) => {
            handleInputChangeEmailCustomisation(
              'whatToExpectNow',
              editor.getData(),
            );
          }}
          value={confirmationEmailCustomisation.whatToExpectNow}
        />
      </FieldRow>
      <FieldRow
        title="Add additional attachment"
        informationText="If you wish to add a PDF file to the confirmation email, please attach it here. Please note that the Offering document is automatically attached to this email."
      >
        <DropzoneComponent
          name="additionalAttachment"
          accept={{
            'application/pdf': ['.pdf'],
          }}
          onDropFile={(files) =>
            handleInputChangeEmailCustomisation('additionalAttachment', files)
          }
          files={
            confirmationEmailCustomisation?.additionalAttachment
              ? [
                  {
                    preview:
                      confirmationEmailCustomisation?.additionalAttachmentPreview,
                    fileName:
                      confirmationEmailCustomisation?.additionalAttachment,
                  },
                ]
              : []
          }
          onRemove={() =>
            handleInputChangeEmailCustomisation('additionalAttachmentRemove')
          }
        />
      </FieldRow>

      <FieldRow>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handleClickConfirmationEmailCustomisation}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </FieldRow>
    </RecordView>
  );
};

export default ConfirmationEmailCustomisation;
