import { FC, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import { FieldRow } from 'components/FormElements';
import { AppSimpleSelect } from 'components/FormElements';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  Box,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AllocationRule } from 'further-types/lp/allocations';
import api from 'lib/trpcClient';
import {
  numberToDisplayString,
  numberToCurrencyString,
} from 'further-ui/utils';
import { useForm } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import AllocationsFormBody from './AllocationsFormBody';
import { capitalize, sumBy } from 'lodash';
import { Allocations, FormValues } from './types';

const StyledAccordion = styled(Accordion)({
  width: '100%',
  borderColor: '#D8D8D8',
});

const AccordionHeader = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(4),
  color: theme.palette.text.darkTitle,
  fontSize: theme.fontSizes?.regular,
  fontWeight: 'bold',

  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
  },
}));

const AccordionBody = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.header,
  padding: 0,
}));

const AccordionSection = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'noBorderBottom',
})<{ noBorderBottom?: boolean }>(({ theme, noBorderBottom }) => ({
  padding: theme.spacing(4),
  borderBottom: noBorderBottom ? 'none' : `2px solid #D8D8D8`,
}));

const TableContainer = styled('table')(({ theme }) => ({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',
  fontSize: theme.fontSizes?.sm,
  color: theme.palette.text.darkBodyText,
  textAlign: 'left',
  borderCollapse: 'collapse',
}));

const EmptyRowCell = styled('td')(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
}));

const TableHeading = styled('th')(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const FooterRow = styled('tr')(({ theme }) => ({
  color: theme.palette.text.darkTitle,
  fontSize: theme.fontSizes?.regular,
  fontWeight: 'bold',
}));

const FooterCell = styled('td')(({ theme, fontWeight }) => ({
  paddingRight: theme.spacing(2),
  fontWeight,
}));

type Props = {
  onAllocationsChange: (allocations: Array<Allocations>) => void;
  vehicleId: string;
  inputAmount: number;
  inputLabel: string;
  currency: string;
  fixedStartingValues?: Array<Allocations>;
};

const AllocationsTable: FC<Props> = ({
  onAllocationsChange,
  vehicleId,
  inputAmount,
  inputLabel,
  currency,
  fixedStartingValues,
}) => {
  const [allocationRule, setAllocationRule] = useState(
    AllocationRule.ByCommitmentAndClosingDate,
  );

  const initialAllocations = api.allocations.generateAllocations.useQuery(
    { allocationRule, vehicleId, amount: inputAmount },
    { enabled: !fixedStartingValues },
  );

  const { control, watch, trigger } = useForm<FormValues>({
    values: {
      allocations:
        initialAllocations.data?.allocations ?? fixedStartingValues ?? [],
    },
    mode: 'onChange',
  });

  const { fields } = useFieldArray({ control, name: 'allocations' });
  const allocations = watch('allocations');

  // send data to parent when allocations change
  useEffect(() => {
    const validateAndAct = async () => {
      const isValid = await trigger();
      if (isValid) onAllocationsChange(allocations);
    };
    validateAndAct();
  }, [allocations, trigger]);

  return (
    <GridContainer>
      <Grid size={{ sm: 12, md: 12, lg: 8 }}>
        <FieldRow title="Allocation rule">
          <AppSimpleSelect
            value={allocationRule}
            fullWidth
            options={[
              {
                value: AllocationRule.ByCommitmentAndClosingDate,
                label: 'By commitment and closing date',
              },
            ]}
            onChange={(e) =>
              setAllocationRule(e.target.value as AllocationRule)
            }
          />
        </FieldRow>
      </Grid>
      <StyledAccordion defaultExpanded>
        <AccordionHeader expandIcon={<ExpandMoreIcon />}>
          Review and edit allocation
        </AccordionHeader>
        <AccordionBody>
          <AccordionSection>
            <TableContainer>
              <thead>
                <tr>
                  <TableHeading>Investor</TableHeading>
                  <TableHeading>Total commitment</TableHeading>
                  <TableHeading>Unallocated commitment</TableHeading>
                  <TableHeading>Close date</TableHeading>
                  <TableHeading>
                    {capitalize(inputLabel)} allocated
                  </TableHeading>
                  <TableHeading>
                    {capitalize(inputLabel)} allocated (%)
                  </TableHeading>
                  <TableHeading>Exclude investor from allocation</TableHeading>
                </tr>
              </thead>
              <tbody>
                {allocations.length === 0 || initialAllocations.isFetching ? (
                  <tr>
                    <EmptyRowCell colSpan={7}>
                      {initialAllocations.isFetching ? (
                        <CircularProgress size={18} />
                      ) : allocations.length === 0 ? (
                        'No commitments found'
                      ) : (
                        ''
                      )}
                    </EmptyRowCell>
                  </tr>
                ) : (
                  <AllocationsFormBody
                    fields={fields}
                    control={control}
                    isFixed={!!fixedStartingValues}
                  />
                )}
              </tbody>
            </TableContainer>
          </AccordionSection>
          <AccordionSection noBorderBottom>
            <TableContainer>
              <tbody>
                <FooterRow>
                  <FooterCell>{allocations.length} investors</FooterCell>
                  <FooterCell />
                  <FooterCell />
                  <FooterCell fontWeight="normal">
                    Total {inputLabel}
                  </FooterCell>
                  <FooterCell>
                    {currency}
                    {numberToCurrencyString(
                      sumBy(allocations, 'allocationAmount'),
                    )}
                  </FooterCell>
                  <FooterCell>
                    {numberToDisplayString(
                      sumBy(allocations, 'allocationPercentage'),
                    )}
                    %
                  </FooterCell>
                  <FooterCell />
                </FooterRow>
              </tbody>
            </TableContainer>
          </AccordionSection>
        </AccordionBody>
      </StyledAccordion>
    </GridContainer>
  );
};

export default AllocationsTable;
