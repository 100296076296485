import React from 'react';

import GridContainer from 'components/GridContainer';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FirmSelector } from 'components/FirmSelector';
import TextField from 'components/FormElements/AppTextInput';
import { Link } from 'react-router-dom';
import { UPLOAD_SHEET_ADD } from 'adminConstants/routes';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import { useGetRole } from 'hooks/ui/useGetRole';

const ListFilters = ({ classes, filters, handleChangeFilters, pagination }) => {
  const { isSuperAdmin } = useGetRole();

  return (
    <React.Fragment>
      <GridContainer>
        {isSuperAdmin && (
          <Grid size={{ xs: 12, md: 3 }}>
            <FirmSelector
              firmId={filters.firmId}
              onChange={(selectedFirmId) => {
                handleChangeFilters({ firmId: selectedFirmId });
              }}
              placeholder="Select a firm"
            />
          </Grid>
        )}
        <Grid size={{ xs: 12, md: 3 }}>
          <TextField
            type="search"
            name="companyName"
            placeholder="Search by company name"
            fullWidth
            value={filters.companyName}
            onChange={(event) => {
              handleChangeFilters({ companyName: event.target.value });
              pagination.toFirstPage();
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <MuiDateRangePicker
            dateRange={filters.dateRange}
            onDateRangeChange={(data) => {
              handleChangeFilters({
                dateRange: data,
              });
            }}
            placeholder="Select date"
          />
        </Grid>
        <Grid size={{ xs: 12, md: isSuperAdmin ? 3 : 6 }}>
          <div className={classes.addRoleBtn}>
            <Link to={UPLOAD_SHEET_ADD}>
              <Button color="primary" variant="contained">
                Add Share Allocations
              </Button>
            </Link>
          </div>
        </Grid>
      </GridContainer>
    </React.Fragment>
  );
};
export default ListFilters;
