import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/investment';

function useIncompleteInvestments(params: Api.GetIncompleteInvestmentsParams) {
  return useQuery({
    queryKey: ['incomplete-investments', params],
    queryFn: async () => {
      const { data } = await api.get<
        ApiResponse<Api.GetIncompleteInvestmentsResult>
      >('investment/incomplete-investment', {
        params,
      });
      return data.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

export default useIncompleteInvestments;
