import { useState } from 'react';
import { useGetRole } from 'hooks/ui/useGetRole';
import { dateToLabel } from 'further-ui/utils';
import { InvestorType } from 'further-types/investor';
import useInvestorCertification from 'hooks/data/investor/useInvestorCertification';
import { useFirm } from 'hooks/data/firm/useFirm';
import useSkipInvestmentCoolOff from 'hooks/data/investmentCoolOff/useSkipInvestmentCoolOff';
import useInvestmentCoolOff from 'hooks/data/investmentCoolOff/useInvestmentCoolOff';
import RecordRow from 'components/RecordRow';
import Spacing from 'components/Spacing';
import Button from 'components/Button';
import { ArrowDropDown } from '@mui/icons-material';
import SearchableMenu from 'components/SearchableMenu';

type Props = {
  id: string;
};

const CoolOffStatus: React.FC<Props> = ({ id }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const { isSuperAdmin } = useGetRole();
  const { certification } = useInvestorCertification(id);
  const { firms } = useFirm({ params: {} });
  const skipCoolOff = useSkipInvestmentCoolOff();
  const { data: coolOffs = [] } = useInvestmentCoolOff(id);

  const professionalClientCertification = (certification.data ?? []).find(
    ({ forceExpired, investorType }) =>
      !forceExpired && investorType === InvestorType.ProfessionalClient,
  );

  const handleSkipCoolOff = (firm: any) => {
    skipCoolOff.mutate({
      investorId: id,
      firmId: firm._id,
    });
  };

  return (
    <>
      <RecordRow label="24-hour cool off">
        <Spacing size="sm">
          {!coolOffs.length && <span>N/A</span>}
          {coolOffs.map((coolOff) => (
            <div key={coolOff.firm?._id}>
              {isSuperAdmin && coolOff.firm?.firmName && (
                <span>{coolOff.firm.firmName} - </span>
              )}
              {coolOff && !!professionalClientCertification ? (
                <>
                  <span>Exempt as a professional client </span>
                  <span>
                    {dateToLabel(
                      professionalClientCertification.certificationPerformedAt,
                      undefined,
                      true,
                    )}
                  </span>
                </>
              ) : (
                <>
                  {coolOff && coolOff.isElapsed && (
                    <>
                      <span>Complete - </span>
                      <span>
                        {dateToLabel(coolOff.elapsesAfter, undefined, true)}
                      </span>
                    </>
                  )}
                  {coolOff && !coolOff.isElapsed && (
                    <>
                      <span>Pending </span>
                      <span>
                        (until{' '}
                        {dateToLabel(coolOff.elapsesAfter, undefined, true)})
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          ))}
          {isSuperAdmin && (
            <Spacing size="sm" alignItems="flex-start">
              <Button
                aria-controls="select-firm-menu"
                aria-haspopup="true"
                color="primary"
                variant="contained"
                size="extra-small"
                endIcon={<ArrowDropDown />}
                onClick={(event) => setMenuAnchorEl(event.currentTarget)}
              >
                Skip cool off for firm
              </Button>
            </Spacing>
          )}
        </Spacing>
      </RecordRow>
      {menuAnchorEl && isSuperAdmin && (
        <SearchableMenu
          id="select-firm-menu"
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          options={firms.data?.result ?? []}
          getOptionLabel={(firm) => firm.firmName}
          onSelect={(firm) => {
            handleSkipCoolOff(firm);
            setMenuAnchorEl(null);
          }}
          searchPlaceholder="Search for firm"
        />
      )}
    </>
  );
};

export default CoolOffStatus;
