import React, { useContext } from 'react';
import useColumnsConfig from '../hooks/useColumnsConfig';
import { GridColumnsInitialState } from '@mui/x-data-grid-pro';

type TableContextType = {
  columnsConfig: GridColumnsInitialState;
  handleConfigChange: (config: GridColumnsInitialState) => void;
};

const TableContext = React.createContext<TableContextType>({
  columnsConfig: {},
  handleConfigChange: () => {},
});

const TableContextProvider: React.FC<{
  children: React.ReactNode;
  tableId: string;
}> = ({ children, tableId }) => {
  return (
    <TableContext.Provider value={useColumnsConfig(tableId)}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = () => useContext(TableContext);

export default TableContextProvider;
