import { useDispatch } from 'react-redux';
import { sumBy } from 'lodash';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloudDownload } from '@mui/icons-material';

import Table from 'components/Table';
import { dateToLabel, numberToDisplayString } from 'further-ui/utils';
import { useAllocations } from 'hooks/data/allocation/useAllocations';
import { exportToCustodian } from '@redux/actions/UploadShareHolding';
import { useNotification } from 'hooks/ui/useNotification';

const useStyles = makeStyles()(() => ({
  summaryRow: {
    backgroundColor: '#FBFBFA',
    marginTop: 4,
  },
  summaryValue: {
    color: '#282525',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '10px 10px 10px 6px',
  },
}));

type Props = {
  companyId?: string;
};

const AllocationRecords: React.FC<Props> = ({ companyId }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notification = useNotification();
  const allocations = useAllocations({
    params: {
      companyId,
    },
  });
  const handleDownload = (id) => {
    // @ts-expect-error to be removed soon anyway
    dispatch(exportToCustodian(id, notification));
  };
  const columns = [
    {
      label: 'Date',
      key: 'date',
      sort: false,
      render: (elm) => dateToLabel(elm?.date),
    },
    {
      label: 'Shares',
      key: 'totalNumberOfShare',
      sort: false,
      render: (elm) => numberToDisplayString(elm?.totalNumberOfShare),
    },
    {
      label: 'Investors',
      key: 'numberOfInvestors',
      sort: false,
      render: (elm) => numberToDisplayString(elm?.numberOfInvestors),
    },
    {
      label: 'Actions',
      key: 'actions',
      render: (elm) => (
        <IconButton
          title="Download"
          onClick={() => handleDownload(elm._id)}
          size="large"
        >
          <CloudDownload color="primary" />
        </IconButton>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      tablebody={allocations?.data?.uploadShareHoldings ?? []}
      loading={allocations?.isFetching}
      TableFooter={
        <tfoot>
          <tr className={classes.summaryRow}>
            <td className={classes.summaryValue} align="right">
              Total shares held
            </td>
            <td className={classes.summaryValue}>
              {numberToDisplayString(
                sumBy(
                  allocations?.data?.uploadShareHoldings,
                  'totalNumberOfShare',
                ),
              )}
            </td>
            <td />
            <td />
          </tr>
        </tfoot>
      }
    />
  );
};

export default AllocationRecords;
