import { z } from 'zod';
import { validatePassword, validateUkSortCode } from 'further-ui/utils';
import { AccountType, Api, InvestorType } from 'further-types/investor';

const commonInvestorRules = {
  email: z
    .string()
    .min(1, 'Please enter email.')
    .email('Invalid email address.'),
  externalInvestorId: z.string(),
  investorType: z.nativeEnum(InvestorType).optional(),
  isClamingTax: z.boolean(),
  isEmailVerify: z.boolean(),
  dateRegister: z.date().optional().nullable(),
  address: z.array(
    z.object({
      address1: z.string().min(1, 'Please enter address line 1.'),
      address2: z.string().optional(),
      city: z.string().min(1, 'Please enter city/town.'),
      postcode: z.string().min(1, 'Please enter postal or zip code.'),
      country: z.string().min(1, 'Please select country.'),
      isApplyAddress: z.boolean().optional(),
    }),
    { required_error: 'Please provide at least one address.' },
  ),
  isVulnerableClient: z.boolean(),
  isDeceased: z.boolean(),
  requiresHardCopyReports: z.boolean(),
  isUkRemittanceTaxpayer: z.boolean(),
  dateOfDeath: z.date().nullable().optional(),
  defaultAdviserId: z.string().nullable().optional(),
  newPassword: z
    .string()
    .optional()
    .superRefine((value, context) => {
      const passwordError = validatePassword(value ?? '');
      if (value && passwordError) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: passwordError,
        });
      }
    }),
};

const bankAndTaxRules = {
  bankAccountName: z.string().optional(),
  bankAccountNumber: z.string().optional(),
  bankName: z.string().optional(),
  bankSortCode: z.string().optional(),
  iban: z.string().optional(),
  swift: z.string().optional(),
  isUsPerson: z.boolean().optional(),
  taxResidencies: z.array(
    z.object({
      country: z.string(),
    }),
  ),
};

const individualInvestorSchema = z.object({
  accountType: z.literal(AccountType.Individual),
  ...commonInvestorRules,
  ...bankAndTaxRules,
  firstName: z.string().min(1, 'Please enter first name.'),
  middleName: z.string(),
  lastName: z.string().min(1, 'Please enter last name.'),
  phoneNumber: z.string().min(1, 'Please enter phone number.'),
  dob: z.date({ required_error: 'Please enter date of birth.' }),
  profession: z.string(),
  nationalInsuranceNumber: z.string().optional(),
  nationality: z.string().min(1, 'Please select nationality.'),
  uniqueTaxpayerReference: z.string().optional(),
  otherNationality: z
    .array(
      z.object({
        country: z.string().min(1, 'Please select other nationality.'),
      }),
    )
    .optional(),
});

const legalEntitySchema = z.object({
  accountType: z.literal(AccountType.LegalEntity),
  ...bankAndTaxRules,
  ...commonInvestorRules,
  registeredName: z.string().min(1, 'Please enter registered ma,e.'),
  registrationNumber: z.string().min(1, 'Please enter registration number.'),
  registrationDate: z.date({
    required_error: 'Please enter date of birth.',
  }),
  registrationState: z.string().min(1, 'Please enter registration state.'),
  registeredTaxNumber: z.string().optional(),
  keyContactFirstName: z.string().min(1, 'Please enterkey contact first name.'),
  keyContactSurname: z.string().min(1, 'Please enter key contact surname.'),
  keyContactPhoneNumber: z
    .string()
    .min(1, 'Please enter key contact phone number.'),
});

export const investorSchema = z
  .discriminatedUnion('accountType', [
    individualInvestorSchema,
    legalEntitySchema,
  ])
  .superRefine((values, context) => {
    if (values.bankAccountNumber && values.bankAccountNumber.length !== 8) {
      context.addIssue({
        path: ['bankAccountNumber'],
        code: z.ZodIssueCode.custom,
        message: 'Please enter an 8 digit account number.',
      });
    }
    if (values.bankSortCode && !validateUkSortCode(values.bankSortCode)) {
      context.addIssue({
        path: ['bankSortCode'],
        code: z.ZodIssueCode.custom,
        message: 'Please enter a 6 digit sort code.',
      });
    }

    if (values.isDeceased && !values.dateOfDeath) {
      context.addIssue({
        path: ['dateOfDeath'],
        code: z.ZodIssueCode.custom,
        message: 'Please provide a date of death.',
      });
    }
  });

export type InvestorFormValues = Required<z.infer<typeof investorSchema>>;

export const getInitialValues = (investorDetails: Api.GetInvestorResponse) => ({
  //Common values
  accountType:
    investorDetails?.accountType === AccountType.LegalEntity
      ? AccountType.LegalEntity
      : AccountType.Individual,
  email: investorDetails?.email ?? '',
  dateRegister: investorDetails?.dateRegister
    ? new Date(investorDetails.dateRegister)
    : null,
  investorType: investorDetails?.investorType,
  isClamingTax: !!investorDetails?.isClamingTax,
  isEmailVerify: investorDetails ? !!investorDetails.isEmailVerify : true,
  externalInvestorId: investorDetails?.externalInvestorId ?? '',
  address: investorDetails?.address?.length
    ? investorDetails.address
    : [
        {
          isApplyAddress: true,
          address1: '',
          address2: '',
          city: '',
          postcode: '',
          country: '',
        },
      ],
  otherNationality: investorDetails?.otherNationality ?? [],
  taxResidencies: investorDetails?.taxResidencies?.length
    ? investorDetails.taxResidencies
    : [{ country: '' }],
  isVulnerableClient: !!investorDetails?.isVulnerableClient,
  isDeceased: !!investorDetails?.isDeceased,
  requiresHardCopyReports: !!investorDetails?.requiresHardCopyReports,
  isUkRemittanceTaxpayer: !!investorDetails?.isUkRemittanceTaxpayer,
  dateOfDeath: investorDetails?.dateOfDeath
    ? new Date(investorDetails.dateOfDeath)
    : null,

  //Individual investor
  firstName: investorDetails?.firstName ?? '',
  middleName: investorDetails?.middleName ?? '',
  lastName: investorDetails?.lastName ?? '',
  phoneNumber: investorDetails?.phoneNumber ?? '',
  dob: investorDetails?.dob ? new Date(investorDetails.dob) : null,
  profession: investorDetails?.profession ?? '',
  nationalInsuranceNumber: investorDetails?.nationalInsuranceNumber ?? '',
  uniqueTaxpayerReference: investorDetails?.uniqueTaxpayerReference ?? '',
  nationality: investorDetails?.nationality,

  //Legal entity
  registeredName: investorDetails?.registeredName ?? '',
  registrationNumber: investorDetails?.registrationNumber ?? '',
  registrationDate: investorDetails?.registrationDate
    ? new Date(investorDetails.registrationDate)
    : null,
  registrationState: investorDetails?.registrationState ?? '',
  registeredTaxNumber: investorDetails?.registeredTaxNumber ?? '',
  keyContactFirstName: investorDetails?.keyContactFirstName ?? '',
  keyContactSurname: investorDetails?.keyContactSurname ?? '',
  keyContactPhoneNumber: investorDetails?.keyContactPhoneNumber ?? '',
  defaultAdviserId:
    investorDetails?.firmSpecificSettings?.[0]?.defaultAdviserId ?? '',

  //Bank and tax details
  bankAccountName: investorDetails?.bankDetails?.[0]?.bankAccountName ?? '',
  bankAccountNumber: investorDetails?.bankDetails?.[0]?.bankAccountNumber ?? '',
  bankName: investorDetails?.bankDetails?.[0]?.bankName ?? '',
  bankSortCode: investorDetails?.bankDetails?.[0]?.bankSortCode ?? '',
  iban: investorDetails?.bankDetails?.[0]?.iban ?? '',
  swift: investorDetails?.bankDetails?.[0]?.swift ?? '',
  isUsPerson: !!investorDetails?.bankDetails?.[0]?.isUsPerson,
  newPassword: '',
});
