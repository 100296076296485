import React from 'react';
import useCompanies from 'hooks/data/company/useCompanies';
import { sortBy } from 'lodash';
import { getCompanyLabel } from 'further-ui/utils';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

type Props = {
  handleChangeCallback: (value: string) => void;
  firmId?: string;
  value?: string;
  includeShareClassInLabel?: boolean;
  includeFirmNameInLabel?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const CompanyListSelect: React.FC<Props> = ({
  handleChangeCallback,
  firmId,
  value,
  includeShareClassInLabel = true,
  includeFirmNameInLabel = true,
  error,
  helperText,
  disabled,
}) => {
  const { data: companies } = useCompanies({ params: {} });

  const companiesWithLabels = sortBy(
    companies?.result
      .filter((company) => {
        //@ts-expect-error
        if (firmId) return company?.firmId?._id === firmId;
        return true;
      })
      .map((company = {}) => {
        return {
          // @ts-expect-error
          id: company._id,
          // @ts-expect-error
          label: getCompanyLabel(company, {
            includeShareClass: includeShareClassInLabel,
            includeFirmName: includeFirmNameInLabel,
          }),
        };
      }),
    (item) => item?.label?.toLowerCase() ?? '',
  );

  const selectedCompany = companiesWithLabels.find(
    (company) => company.id === value,
  );

  return (
    <Autocomplete
      disabled={!companiesWithLabels.length}
      options={companiesWithLabels}
      getOptionLabel={(option) => option.label || ''}
      filterSelectedOptions
      value={selectedCompany}
      onChange={(_event, selectedCompany) => {
        handleChangeCallback(selectedCompany?.id);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select company"
          error={error}
          helperText={helperText}
          disabled={disabled}
          name="companyId"
        />
      )}
    />
  );
};

export default CompanyListSelect;
