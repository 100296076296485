import React from 'react';
import InteractiveTable, { Column } from 'components/InteractiveTable';
import { dateToLabel } from 'further-ui/utils';
import { Transaction } from './types';

type Props = {
  parentTransactionId: string;
  subtransactions: Transaction['subtransactions'];
};

const columns: Array<Column> = [
  {
    field: 'transactionDate',
    headerName: 'Date',
    renderCell: (cell) => {
      return dateToLabel(cell.value);
    },
    flex: 1,
  },
  {
    field: 'transactionType',
    headerName: 'Transaction type',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
  {
    field: 'amountIncVat',
    headerName: 'Amount (LCY)',
    flex: 1,
  },
  {
    field: 'amountExVat',
    headerName: 'Amount (TCY)',
    flex: 1,
  },
  {
    field: 'counterpartyLabel',
    headerName: 'Counterparty',
    flex: 1,
  },
  {
    field: 'bankAccountLabel',
    headerName: 'Bank account',
    flex: 1,
  },
];

const Subtransactions: React.FC<Props> = ({
  subtransactions,
  parentTransactionId,
}) => {
  return (
    <InteractiveTable
      id={`general-ledger-subtransactions-${parentTransactionId}`}
      disableTableConfiguration
      disableSorting
      columns={columns}
      rows={subtransactions ?? []}
      disablePagination
      fullHeight
    />
  );
};

export default Subtransactions;
