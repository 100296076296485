import { FC } from 'react';
import { useLayoutContext } from 'contexts/LayoutContext';
import PageLoader from 'components/PageLoader';
import { Alert } from '@mui/material';

const VehicleSelectionGate: FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const { selectedVehicleId, isVehicleSwitching } = useLayoutContext();

  if (selectedVehicleId && !isVehicleSwitching) {
    return children;
  }

  if (isVehicleSwitching) {
    return <PageLoader />;
  }

  return <Alert severity="info">No vehicles found</Alert>;
};

export default VehicleSelectionGate;
