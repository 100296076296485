import { Fragment } from 'react';
import { format, parseISO } from 'date-fns';
import { dateofBirthToLabel, dateToLabel } from 'further-ui/utils';
import DownloadTransferButton from '../DownloadTransferButton';
import RecordRow from 'components/RecordRow';
import Spacing from 'components/Spacing';

type Props = {
  investorDetail: any;
};

const LegalEntityDetails: React.FC<Props> = ({ investorDetail }) => {
  return (
    <Spacing size="sm">
      <RecordRow label="Registered name">
        {investorDetail?.registeredName}
      </RecordRow>

      <RecordRow label="Registration number">
        {investorDetail?.registrationNumber}
      </RecordRow>

      <RecordRow label="Registration date">
        {investorDetail?.registrationDate
          ? dateofBirthToLabel(investorDetail?.registrationDate)
          : '-'}
      </RecordRow>

      <RecordRow label="Registration state">
        {investorDetail?.registrationState}
      </RecordRow>

      <RecordRow label="Email address">
        {investorDetail?.email || '-'}
      </RecordRow>

      <RecordRow label="Key contact first name">
        {investorDetail?.keyContactFirstName || '-'}
      </RecordRow>

      <RecordRow label="Key contact surname">
        {investorDetail?.keyContactSurname || '-'}
      </RecordRow>

      <RecordRow label="Key contact phone number">
        {investorDetail?.keyContactPhoneNumber || '-'}
      </RecordRow>

      <RecordRow label="Date registered">
        {investorDetail?.createdAt
          ? format(parseISO(investorDetail?.createdAt), 'dd/MM/yyyy')
          : '-'}
      </RecordRow>

      <RecordRow label="Last login">
        {dateToLabel(investorDetail.lastLoginDate, '-', true, true)}
      </RecordRow>

      <RecordRow label="Email verified">
        {investorDetail?.isEmailVerify ? 'Yes' : 'No'}
      </RecordRow>

      {(investorDetail.address ?? []).map((single, index) => (
        <Fragment key={index}>
          <RecordRow
            label={index === 0 ? 'Current address' : 'Previous address'}
          >
            <div>
              <div>{single?.address1}</div>
              <div>{single?.address2}</div>
              <div>{single?.city}</div>
              <div>
                {typeof single?.postcode === 'string'
                  ? single?.postcode.toUpperCase()
                  : single?.postcode}
              </div>
              <div>{single?.country}</div>
            </div>
          </RecordRow>

          {index === 0 && (
            <RecordRow label="This was the entity's registered address before 3 years?">
              {investorDetail?.address && investorDetail?.address.length === 1
                ? 'Yes'
                : 'No'}
            </RecordRow>
          )}
        </Fragment>
      ))}

      <RecordRow label="External investor ID">
        {investorDetail?.externalInvestorId || '-'}
      </RecordRow>

      <RecordRow label="Vulnerable client">
        {investorDetail?.isVulnerableClient ? 'Yes' : 'No'}
      </RecordRow>

      <RecordRow label="Deceased" direction="row">
        <span>{investorDetail?.isDeceased ? 'Yes' : 'No'}</span>
        {investorDetail?.isDeceased && (
          <DownloadTransferButton
            investorId={investorDetail._id}
            investorName={investorDetail.registeredName}
          />
        )}
      </RecordRow>

      {investorDetail?.isDeceased && (
        <RecordRow label="Date of death">
          {investorDetail?.dateOfDeath
            ? dateofBirthToLabel(investorDetail?.dateOfDeath)
            : '-'}
        </RecordRow>
      )}

      <RecordRow label="UK remittance taxpayer (BIR)">
        {investorDetail?.isUkRemittanceTaxpayer ? 'Yes' : 'No'}
      </RecordRow>
    </Spacing>
  );
};

export default LegalEntityDetails;
