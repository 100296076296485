import { Button, Switch } from '@mui/material';
import TextField from 'components/FormElements/AppTextInput';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import FormHelperText from '@mui/material/FormHelperText';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { makeStyles } from 'tss-react/mui';
import FieldRow from 'components/FormElements/FieldRow';
import { TrancheVisibility } from 'further-types/fund';
import { FirmSelector } from 'components/FirmSelector';
import ButtonGroup from 'components/ButtonGroup';
import RecordView from 'components/RecordView';
import { FundStatus } from 'adminConstants/fundProperties';

const useStyles = makeStyles()(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  switchLabel: {
    margin: '0 10px',

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
}));

type Props = any;

const BasicInformation: React.FC<Props> = ({
  errorTextBasic,
  handleCancel,
  handleChangeTag,
  handleClick,
  handleDateChange,
  id,
  investment,
  setValue,
  tagList,
  value,
  disabled = false,
  hasPrerequisitesForPublishing,
  isSyndicate,
}) => {
  const { classes } = useStyles();

  const isDraft = value.fundStatus === FundStatus.DRAFT;
  const isOpen = value.fundStatus === FundStatus.OPEN;

  return (
    <RecordView>
      <FieldRow title="Firm*" centerTitle>
        <FirmSelector
          firmId={value.firmId}
          onChange={(firmId) => setValue({ ...value, firmId })}
          placeholder="Select firm"
          allowDeselection={false}
          error={errorTextBasic.firmId}
          helperText={errorTextBasic.firmId}
          required
        />
      </FieldRow>
      <FieldRow title="Tranche name*" centerTitle>
        <TextField
          required
          name="tancheName"
          fullWidth
          onChange={(event) =>
            setValue({ ...value, fundName: event.target.value })
          }
          value={value.fundName}
          placeholder="Enter the tranche name"
          variant="outlined"
          error={errorTextBasic.fundName}
          helperText={errorTextBasic.fundName}
        />
      </FieldRow>
      <FieldRow
        title="Tranche status*"
        centerTitle
        tooltipText={
          !hasPrerequisitesForPublishing && !isOpen
            ? `To publish this tranche, please first select your payment account in “Bank details”${
                !isSyndicate
                  ? ' and add your Legal agreement in “Tranche subscription documentation”.'
                  : '.'
              }`
            : ''
        }
      >
        <AppRadioButton
          required
          key="open"
          name="fundStatus"
          label="Open"
          value={1}
          checked={value.fundStatus === 1}
          onChange={() => setValue({ ...value, fundStatus: 1 })}
          error={errorTextBasic.fundStatus}
          helperText={errorTextBasic.fundStatus}
          disabled={!hasPrerequisitesForPublishing && !isOpen}
        />
        <AppRadioButton
          key="closed"
          name="fundStatus"
          label="Closed"
          value={0}
          checked={value.fundStatus === 0}
          onChange={() => setValue({ ...value, fundStatus: 0 })}
        />
        {!investment.length && (
          <AppRadioButton
            key="draft"
            name="fundStatus"
            label="Draft"
            value={2}
            checked={isDraft}
            onChange={() => setValue({ ...value, fundStatus: 2 })}
            error={errorTextBasic.fundStatus}
            helperText={errorTextBasic.fundStatus}
          />
        )}
      </FieldRow>

      <FieldRow title="Show on firm link?" centerTitle>
        <span className={classes.switchLabel}>Only show on tranche link</span>
        <Switch
          key="trancheVisibility"
          checked={value.trancheVisibility === TrancheVisibility.ShowOnFirm}
          onChange={(event) =>
            setValue({
              ...value,
              trancheVisibility: event.target.checked
                ? TrancheVisibility.ShowOnFirm
                : TrancheVisibility.ShowOnTranche,
            })
          }
        />
        <span className={classes.switchLabel}>Show on firm link</span>
      </FieldRow>

      <FieldRow title="Fund type*" centerTitle>
        {tagList.map((item, index) => (
          <AppRadioButton
            key={index}
            name="tag"
            label={item.name}
            value={item._id}
            checked={value.tag === item?._id}
            onChange={(event) => handleChangeTag(event.target.value, item)}
          />
        ))}
        {errorTextBasic.tag && (
          <FormHelperText error={true}>{errorTextBasic.tag}</FormHelperText>
        )}
      </FieldRow>

      <FieldRow title="Professional clients only" centerTitle>
        <span className={classes.switchLabel}>No</span>
        <Switch
          key="isProfessionalClientsOnly"
          checked={value.isProfessionalClientsOnly}
          onChange={(event) =>
            setValue({
              ...value,
              isProfessionalClientsOnly: event.target.checked,
            })
          }
        />
        <span className={classes.switchLabel}>Yes</span>
      </FieldRow>

      <FieldRow title="Start date" centerTitle>
        <AppDatePicker
          name="startDate"
          value={value.startDate}
          onChange={(date) => {
            setValue({ ...value, startDate: date });
          }}
        />
      </FieldRow>

      <FieldRow title="Scheduled close date*" centerTitle>
        <AppDatePicker
          required
          name="finishDate"
          value={value.finishDate}
          onChange={handleDateChange}
          error={errorTextBasic.finishDate}
          helperText={errorTextBasic.finishDate}
        />
      </FieldRow>
      {id && (
        <FieldRow title="Close date" centerTitle>
          <AppDatePicker
            name="closedDate"
            value={value.closedDate}
            onChange={(date) => {
              setValue({ ...value, closedDate: date });
            }}
          />
        </FieldRow>
      )}

      <ButtonGroup>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className={classes.marginLeftBtn}
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={() => handleClick()}
        >
          {id ? 'Update' : 'Save'}
        </Button>
      </ButtonGroup>
    </RecordView>
  );
};

export default BasicInformation;
