import { Button } from '@mui/material';
import { nationality } from 'further-ui/labels';
import { investorTypeLabel, InvestorType } from 'further-types/investor';

import FieldRow from 'components/FormElements/FieldRow';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import AppPasswordInput from 'components/FormElements/AppPasswordTextField';
import AppSelectBox from 'components/FormElements/AppSelectBox';

import AppRadioButton from 'components/FormElements/AppRadioButton';
import InvestorAddress from './InvestorAddress';
import { Control, Controller, useWatch } from 'react-hook-form';
import SwitchField from './SwitchField';
import TextField from './TextField';
import { InvestorFormValues } from 'helpers/investor/investorForm';
import { useGetRole } from 'hooks/ui/useGetRole';
import AdviserSelect from 'components/AdviserSelect';
import useResendVerificationLinkMutation from 'hooks/data/user/useResendVerificationLink';
import { UserType } from 'further-types/user';
import { useFirm } from 'hooks/data/firm/useFirm';

type Props = {
  id?: string;
  isSuperAdmin: boolean;
  control: Control<InvestorFormValues>;
};

const EntityDetails: React.FC<Props> = ({ id, isSuperAdmin, control }) => {
  const isDeceased = useWatch({ control, name: 'isDeceased' });
  const { isFirmManager } = useGetRole();
  const isEmailVerified = useWatch({ control, name: 'isEmailVerify' });
  const { firmId } = useGetRole();
  const { firm } = useFirm({ params: { firmId } });
  const {
    mutate: resendVerificationLink,
    isPending: isPendingResendVerificationLink,
  } = useResendVerificationLinkMutation();

  const isDisabled =
    isEmailVerified || isPendingResendVerificationLink || isSuperAdmin;

  const handleResendVerificationLink = () => {
    if (!id) throw new Error('Investor ID is required');

    resendVerificationLink({
      id,
      userType: UserType.Investor,
      domain: firm?.data?.domain,
    });
  };

  return (
    <>
      <FieldRow title="Registered name*" centerTitle>
        <TextField name="registeredName" control={control} />
      </FieldRow>
      <FieldRow title="Registration number*" centerTitle>
        <TextField name="registrationNumber" control={control} />
      </FieldRow>
      <FieldRow title="Registration date*" centerTitle>
        <Controller
          name="registrationDate"
          control={control}
          render={({
            field: { ref, onChange, value, ...rest },
            fieldState: { error },
          }) => (
            <AppDatePicker
              {...rest}
              maxDate={new Date()}
              inputRef={ref}
              error={!!error}
              value={value ?? null}
              helperText={error?.message}
              openOnFocus
              onChange={(date) => {
                onChange(date);
              }}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Registration state*" centerTitle>
        <Controller
          name="registrationState"
          control={control}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <AppSelectBox
              {...rest}
              data={nationality}
              valueKey="Country"
              labelKey="Country"
              variant="outlined"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </FieldRow>
      {/* Address */}
      <InvestorAddress control={control} isLegalEntity />
      <FieldRow title="Email address*" centerTitle>
        <TextField name="email" control={control} />
      </FieldRow>
      {isSuperAdmin && id && (
        <FieldRow title="Password" centerTitle>
          <Controller
            name="newPassword"
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <AppPasswordInput
                {...rest}
                inputRef={ref}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      )}
      <FieldRow title="Key contact first name*" centerTitle>
        <TextField name="keyContactFirstName" control={control} />
      </FieldRow>
      <FieldRow title="Key contact surname*" centerTitle>
        <TextField name="keyContactSurname" control={control} />
      </FieldRow>
      <FieldRow title="Key contact phone number*" centerTitle>
        <TextField name="keyContactPhoneNumber" control={control} />
      </FieldRow>
      {id ? (
        <FieldRow title="Date registered*" centerTitle>
          <Controller
            name="registrationDate"
            control={control}
            render={({
              field: { ref, onChange, value, ...rest },
              fieldState: { error },
            }) => (
              <AppDatePicker
                {...rest}
                disabled
                inputRef={ref}
                value={value ?? null}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      ) : null}
      {isSuperAdmin ? (
        <FieldRow title="Investor type" centerTitle>
          <Controller
            name="investorType"
            control={control}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <AppSelectBox
                {...rest}
                data={[
                  {
                    value: InvestorType.ProfessionalClient,
                    label: investorTypeLabel[InvestorType.ProfessionalClient],
                  },
                ]}
                valueKey="value"
                labelKey="label"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      ) : null}
      {id ? (
        <FieldRow title="Email verified?" centerTitle>
          <Controller
            name="isEmailVerify"
            control={control}
            render={({
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              field: { ref, onChange, value, ...rest },
              fieldState: { error },
            }) => (
              <AppRadioButton
                {...rest}
                label={'Yes'}
                checked={!!value}
                error={!!error}
                helperText={error?.message}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
              />
            )}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleResendVerificationLink}
            disabled={isDisabled}
          >
            Resend verification email
          </Button>
        </FieldRow>
      ) : null}
      <FieldRow title="External investor ID" centerTitle>
        <TextField name="externalInvestorId" control={control} />
      </FieldRow>
      <FieldRow title="Vulnerable client" centerTitle>
        <span>No</span>
        <SwitchField name="isVulnerableClient" control={control} />
        <span>Yes</span>
      </FieldRow>
      <FieldRow title="Deceased" centerTitle>
        <span>No</span>
        <SwitchField name="isDeceased" control={control} />
        <span>Yes</span>
      </FieldRow>
      {isDeceased && (
        <FieldRow title="Date of death*" centerTitle>
          <Controller
            name="dateOfDeath"
            control={control}
            render={({
              field: { ref, onChange, value, ...rest },
              fieldState: { error },
            }) => (
              <AppDatePicker
                {...rest}
                maxDate={new Date()}
                inputRef={ref}
                error={!!error}
                value={value ?? null}
                helperText={error?.message}
                openOnFocus
                onChange={(date) => {
                  onChange(date);
                }}
              />
            )}
          />
        </FieldRow>
      )}
      <FieldRow title="Requires hard copy reports" centerTitle>
        <span>No</span>
        <SwitchField name="requiresHardCopyReports" control={control} />
        <span>Yes</span>
      </FieldRow>
      <FieldRow title="Will the investor be claiming tax relief?" centerTitle>
        <span>No</span>
        <SwitchField name="isClamingTax" control={control} />
        <span>Yes</span>
      </FieldRow>
      <FieldRow title="UK remittance taxpayer (BIR)" centerTitle>
        <span>No</span>
        <SwitchField name="isUkRemittanceTaxpayer" control={control} />
        <span>Yes</span>
      </FieldRow>
      {isFirmManager && !id && (
        <FieldRow title="Default adviser" centerTitle>
          <Controller
            name="defaultAdviserId"
            control={control}
            render={({ field }) => (
              <AdviserSelect
                adviserId={field.value}
                onChange={(adviser) => field.onChange(adviser?._id ?? null)}
                placeholder="Select default adviser"
                fullWidth
                id="defaultAdviserId"
              />
            )}
          />
        </FieldRow>
      )}
    </>
  );
};

export default EntityDetails;
