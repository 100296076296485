import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import Heading from 'components/Heading';
import { groupBy, round, sumBy } from 'lodash';
import { numberToCurrencyString } from 'further-ui/utils';
import { RowsGroup } from 'components/InteractiveTable';
import { Row } from 'components/Layout';
import { PaginationState } from 'further-types/utils';
import { Section } from './types';
import Account from './Account';

const StyledAccordion = styled(Accordion)({
  fontFamily: 'IBMPlexSans',
  borderBottom: 0,
  '&:not(:first-of-type)': {
    borderTop: 0,
  },
});

const StickyAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'sticky',
  top: 0,
  zIndex: 10,
  borderBottom: `1px solid ${theme.palette.borderColor?.dark}`,
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
});

const AccordionHeader = styled(Row)(({ theme }) => ({
  width: '100%',
  paddingRight: theme.spacing(2),
}));

type Props = {
  sectionTitle: string;
  section: Section;
  pagination: PaginationState;
  loading?: boolean;
};

const SectionContent: React.FC<Props> = ({
  sectionTitle,
  section,
  pagination,
  loading,
}) => {
  const groupedRows: RowsGroup<(typeof section.transactions)[number]> = {};

  const transactionsByAccountId = groupBy(section.transactions, 'accountId');
  const accounts = Object.keys(transactionsByAccountId);

  Object.values(groupBy(section.transactions, 'accountId')).forEach((rows) => {
    groupedRows[rows[0].accountId] = {
      title: rows[0].accountLabel,
      rows: rows.map((row) => ({
        ...row,
        isExpandable: !!row.subtransactions?.length,
      })),
      summary: {
        accountLabel: `Subtotal - ${rows[0].accountLabel}`,
        amountIncVat: numberToCurrencyString(
          round(sumBy(rows, 'amountIncVat'), 2),
        ),
        amountExVat: numberToCurrencyString(
          round(sumBy(rows, 'amountExVat'), 2),
        ),
      },
    };
  });

  return (
    <StyledAccordion defaultExpanded className="section-accordion">
      <StickyAccordionSummary
        expandIcon={<ExpandMoreIcon color="primary" />}
        aria-controls={`section-content-${sectionTitle}`}
        id={`section-header-${sectionTitle}`}
      >
        <AccordionHeader centered justify="space-between">
          <Heading variant="h3" noMargin>
            {sectionTitle}
          </Heading>
          <Heading variant="h6" noMargin>
            {numberToCurrencyString(section.total)}
          </Heading>
        </AccordionHeader>
      </StickyAccordionSummary>
      <StyledAccordionDetails>
        {accounts.map((account) => (
          <Account
            loading={loading}
            key={account}
            accountLabel={transactionsByAccountId[account][0].accountLabel}
            accountId={account}
            pagination={pagination}
            transactions={transactionsByAccountId[account]}
          />
        ))}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default SectionContent;
