import { useEffect, useState } from 'react';
import { Button, styled, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import FieldRow from 'components/FormElements/FieldRow';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { useGetRole } from 'hooks/ui/useGetRole';
import { NumericFormat } from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import {
  numberToCurrencyString,
  getCompanyLabel,
  numberToDisplayString,
} from 'further-ui/utils';
import SharePriceInfoTooltip from '../ExitDetailsForm/SharePriceInfoTooltip';
import { TaxReliefElegibleType } from 'further-types/exit';
import TaxReliefElegibleAllocations from '../ExitDetailsForm/TaxReliefElegibleAllocations';
import GridContainer from 'components/GridContainer';
import { useAllocations } from 'hooks/data/allocation/useAllocations';
import ButtonGroup from 'components/ButtonGroup';
import getAllocationLabel from 'utils/allocationLabel';

const Row = styled('div')(() => ({
  display: 'flex',
  maxWidth: '20rem',
  gridGap: '1rem',
  '& > div': {
    width: '100%',
  },
}));

const Li = styled('li')(() => ({
  marginLeft: '1rem',
}));

const EditExitDetailsForm = ({
  previousExitDetails,
  onSubmit,
  exitDetailsStepCompleted,
}) => {
  const { isSuperAdmin } = useGetRole();

  const { data, isLoading: isLoadingAllocations } = useAllocations({
    params: {
      firmId: previousExitDetails.firmId,
      companyId: previousExitDetails.selectedCompany?._id,
    },
  });

  const allocations = data?.uploadShareHoldings.map((allocation) => ({
    _id: allocation._id,
    allocationName: getAllocationLabel(allocation),
  }));

  const [exitDate, setExitDate] = useState(new Date());
  const [sharePrice, setSharePrice] = useState<string>();
  const [taxableValueSharePrice, setTaxableValueSharePrice] =
    useState<string>();
  const [chargeAccruedFees, setChargeAccruedFees] = useState();
  const [taxReliefElegible, setTaxReliefElegible] =
    useState<TaxReliefElegibleType>();
  const [taxReliefElegibleAllocationIds, setTaxReliefElegibleAllocationIds] =
    useState([]);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const disableEdit = exitDetailsStepCompleted;

  useEffect(() => {
    if (previousExitDetails.exitDate) {
      setExitDate(previousExitDetails.exitDate);
    }
    if (previousExitDetails.sharePrice) {
      setSharePrice(previousExitDetails.sharePrice);
    }

    if (previousExitDetails.taxableValueSharePrice) {
      setTaxableValueSharePrice(previousExitDetails.taxableValueSharePrice);
    }

    if (previousExitDetails.taxReliefElegible) {
      setTaxReliefElegible(previousExitDetails.taxReliefElegible);
      setTaxReliefElegibleAllocationIds(
        previousExitDetails.taxReliefElegibleAllocationIds ?? [],
      );
    }
  }, [
    previousExitDetails.exitDate,
    previousExitDetails.sharePrice,
    previousExitDetails.taxableValueSharePrice,
  ]);

  const validate = () => {
    const errorsToSet: Record<string, string> = {};
    if (!exitDate) {
      errorsToSet.exitDate = 'Please select the date the exit happened';
    }
    if (!sharePrice || Number(sharePrice) < previousExitDetails.sharePrice) {
      errorsToSet.sharePrice = `The received value share price must be equal to or greater than the original received value share price of ${numberToCurrencyString(
        previousExitDetails.sharePrice,
      )}. To edit an exit to a lower overall share price, please delete the original exit and process it again.`;
    }
    if (chargeAccruedFees !== false && chargeAccruedFees !== true) {
      errorsToSet.chargeAccruedFees = `Please select an option`;
    }
    if (!taxableValueSharePrice && Number(taxableValueSharePrice) !== 0) {
      errorsToSet.taxableValueSharePrice =
        'Please enter a value for the taxable value share price';
    }
    if (Number(sharePrice) > Number(taxableValueSharePrice)) {
      errorsToSet.taxableValueSharePrice = `The taxable value share price must be equal to or greater than the received value share price.`;
    }
    if (!taxReliefElegible) {
      errorsToSet.taxReliefElegible = `Please select an option`;
    }
    if (
      taxReliefElegible === TaxReliefElegibleType.Partial &&
      !taxReliefElegibleAllocationIds?.length
    ) {
      errorsToSet.taxReliefElegibleAllocationIds = `Please select at least one allocation`;
    }

    setErrors(errorsToSet);

    // return false if there's any errors
    return !Object.keys(errorsToSet).length;
  };

  const allAllocationsSelected =
    previousExitDetails.shareAllocationUploadIds?.length ===
    allocations?.length;

  const noAllocationsSelected =
    !previousExitDetails.shareAllocationUploadIds?.length;

  const someAllocationsSelected =
    previousExitDetails.shareAllocationUploadIds?.length > 0 &&
    previousExitDetails.shareAllocationUploadIds?.length < allocations?.length;

  return (
    <>
      {isSuperAdmin && (
        <FieldRow title="Firm">
          <Row>{previousExitDetails.firmName}</Row>
        </FieldRow>
      )}
      <>
        <FieldRow title="Exit amendment date" centerTitle>
          <Row>
            <AppDatePicker
              name="exitDate"
              value={exitDate}
              //@ts-expect-error
              onChange={setExitDate}
              disableFuture={true}
              helperText={errors.exitDate}
              error={!!errors.exitDate}
              disabled={disableEdit}
            />
          </Row>
        </FieldRow>
        <FieldRow title="Company" centerTitle>
          <Row>{getCompanyLabel(previousExitDetails.selectedCompany)}</Row>
        </FieldRow>
        <FieldRow title="Allocations" centerTitle>
          <Row>
            {someAllocationsSelected && !isLoadingAllocations && (
              <ul>
                {allocations
                  ?.filter((allocation) =>
                    previousExitDetails.shareAllocationUploadIds.includes(
                      allocation._id,
                    ),
                  )
                  .map((allocation) => (
                    <Li key={allocation._id}>{allocation.allocationName}</Li>
                  ))}
              </ul>
            )}
            {(allAllocationsSelected || noAllocationsSelected) && (
              <span>All allocations selected</span>
            )}
            {isLoadingAllocations && <span>Loading...</span>}
          </Row>
        </FieldRow>
        <FieldRow title="Shares to be sold" centerTitle>
          <Row>{numberToDisplayString(previousExitDetails.sharesToBeSold)}</Row>
        </FieldRow>
        <FieldRow
          title="Taxable value share price"
          centerTitle
          tooltipText="This is typically the share price agreed in any legal sale documentation."
        >
          <Row>
            <NumericFormat
              onValueChange={({ value }) => setTaxableValueSharePrice(value)}
              value={taxableValueSharePrice}
              prefix="£"
              allowNegative={false}
              allowLeadingZeros={false}
              thousandSeparator={true}
              customInput={AppTextInput}
              error={!!errors.taxableValueSharePrice}
              helperText={errors.taxableValueSharePrice}
              disabled={disableEdit}
            />
          </Row>
        </FieldRow>
        <FieldRow
          title="Received value share price"
          centerTitle
          tooltipText={
            <span>
              This must be less than or equal to the{' '}
              <em>taxable value share price</em>, depending on whether all sale
              proceeds have been received or not.
            </span>
          }
        >
          <Row>
            <NumericFormat
              onValueChange={({ value }) => setSharePrice(value)}
              value={sharePrice}
              prefix="£"
              allowNegative={false}
              allowLeadingZeros={false}
              thousandSeparator={true}
              customInput={AppTextInput}
              error={!!errors.sharePrice}
              helperText={errors.sharePrice}
              disabled={disableEdit}
            />
          </Row>
        </FieldRow>
        <FieldRow
          title="Charge accrued fees"
          centerTitle
          tooltipText="Deduct any fees that have been accrued to date but not paid by the investor. The maximum fee chargeable to any investor on exit is their full exit amount. Any accrued fees not charged now will remain on the system for charging at a later date."
        >
          <AppRadioButton
            name="chargeAccruedFees"
            label="Yes"
            value={1}
            checked={chargeAccruedFees === true}
            onChange={(event) => {
              // @ts-expect-error
              setChargeAccruedFees(event.target.value === '1');
            }}
            error={!!errors.chargeAccruedFees}
            disabled={disableEdit}
          />
          <AppRadioButton
            name="chargeAccruedFees"
            label="No"
            value={0}
            checked={chargeAccruedFees === false}
            onChange={(event) => {
              // @ts-expect-error
              setChargeAccruedFees(event.target.value === '1');
            }}
            error={!!errors.chargeAccruedFees}
            disabled={disableEdit}
          />
          {/* Using Typography to display error message for correct alignment of error message and inputs */}
          {errors.chargeAccruedFees && (
            <div>
              <Typography variant="caption" color="error">
                {errors.chargeAccruedFees}
              </Typography>
            </div>
          )}
        </FieldRow>
        <FieldRow
          title="S/EIS eligible exit"
          centerTitle
          tooltipText={
            <span>
              <span>
                Editing the S/EIS status of this exit will change the status
                from the original exit’s date, not the date of this edit.
              </span>
              <br />
              <br />
              <span>
                If this exit takes place over three years since your last EIS
                subscription in the company, select <em>Yes</em>. If this exit
                takes place within three years of your first EIS subscription in
                the company, select <em>No</em>. Where some tranches invested
                over three years before this exit, but others invested more
                recently, select Partial and choose the allocations that are
                S/EIS eligible (i.e. select those that invested over three years
                before this exit).
              </span>
            </span>
          }
        >
          <AppRadioButton
            name="taxReliefElegible"
            label="Yes"
            value={1}
            checked={taxReliefElegible === TaxReliefElegibleType.Yes}
            onChange={(_, checked) => {
              if (checked) {
                setTaxReliefElegible(TaxReliefElegibleType.Yes);
              }
            }}
            error={!!errors.taxReliefElegible}
            disabled={disableEdit}
          />
          <AppRadioButton
            name="taxReliefElegible"
            label="No"
            value={0}
            checked={taxReliefElegible === TaxReliefElegibleType.No}
            onChange={(_, checked) => {
              if (checked) {
                setTaxReliefElegible(TaxReliefElegibleType.No);
              }
            }}
            error={!!errors.taxReliefElegible}
            disabled={disableEdit}
          />
          <AppRadioButton
            name="taxReliefElegible"
            label="Partial"
            value={0}
            checked={taxReliefElegible === TaxReliefElegibleType.Partial}
            onChange={(_, checked) => {
              if (checked) {
                setTaxReliefElegible(TaxReliefElegibleType.Partial);
              }
            }}
            error={!!errors.taxReliefElegible}
            disabled={disableEdit}
          />
          {/* Using Typography to display error message for correct alignment of error message and inputs */}
          {errors.taxReliefElegible && (
            <div>
              <Typography variant="caption" color="error">
                {errors.taxReliefElegible}
              </Typography>
            </div>
          )}
        </FieldRow>
        {taxReliefElegible === TaxReliefElegibleType.Partial && (
          <TaxReliefElegibleAllocations
            firmId={previousExitDetails.firmId}
            companyId={previousExitDetails.selectedCompany?._id}
            taxReliefElegibleAllocationIds={taxReliefElegibleAllocationIds}
            //@ts-expect-error
            onChange={setTaxReliefElegibleAllocationIds}
            disabled={disableEdit}
            error={errors.taxReliefElegibleAllocationIds}
          />
        )}
      </>

      <GridContainer>
        <Grid size={{ md: 5, xs: 12 }}>
          <SharePriceInfoTooltip />
        </Grid>
        <Grid size={{ md: 7, xs: 12 }}>
          {!disableEdit && (
            <ButtonGroup>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (validate())
                    onSubmit({
                      ...previousExitDetails,
                      exitDate,
                      sharePrice: Number(sharePrice),
                      taxableValueSharePrice: Number(taxableValueSharePrice),
                      chargeAccruedFees,
                      taxReliefElegible,
                      taxReliefElegibleAllocationIds,
                    });
                }}
              >
                Next
              </Button>
            </ButtonGroup>
          )}
        </Grid>
      </GridContainer>
    </>
  );
};

export default EditExitDetailsForm;
