import React from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { makeStyles } from 'tss-react/mui';
import TableSortLabel from '@mui/material/TableSortLabel';
import { InfoOutlined } from '@mui/icons-material';
import { Checkbox, Tooltip } from '@mui/material';
import { Column } from './types';

const useStyles = makeStyles()((theme) => ({
  tableCellRoot: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 12,
    color: theme.palette.text.tableText,
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.background.header,
    borderBottom: '0 none',
    '&:first-of-type': {
      paddingLeft: 24,
    },
  },
  centered: {
    textAlign: 'center',
  },
  tableCellCollapsible: {
    minWidth: 50,
  },
  noSortingColumnSpacing: {
    marginRight: '18px',
  },
  tooltipIcon: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    marginLeft: '0.25rem',
  },
}));

type Props<T> = {
  columns: Array<Column<T>>;
  onRequestSort?: (event: React.MouseEvent<unknown>, columnKey: string) => void;
  order: any;
  orderBy: string;
  totalCount: number;
  selectable: boolean;
  selectedCount: number;
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasCollapsedContent: boolean;
};

const TableHeading = <T extends Record<string, any>>({
  columns,
  onRequestSort,
  order,
  orderBy,
  totalCount,
  selectable,
  selectedCount,
  onSelect,
  hasCollapsedContent,
}: Props<T>) => {
  const onSortOrderChange =
    (columnKey: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort?.(event, columnKey);
    };
  const { classes } = useStyles();

  const ColumnTooltip = ({ content }) => (
    <Tooltip title={content} placement="top">
      <InfoOutlined className={classes.tooltipIcon} />
    </Tooltip>
  );

  return (
    <TableRow>
      {selectable ? (
        <TableCell padding="checkbox" className={`${classes.tableCellRoot}`}>
          <Checkbox
            color="primary"
            indeterminate={!!(selectedCount && selectedCount < totalCount)}
            checked={!!(selectedCount && selectedCount === totalCount)}
            onChange={onSelect}
            inputProps={{
              'aria-label': 'select all rows',
            }}
          />
        </TableCell>
      ) : null}
      {columns && columns.length
        ? columns.map((column, index) =>
            column.sort === false ? (
              <TableCell
                size={column.size}
                key={index}
                className={`${classes.tableCellRoot} ${
                  column.className ?? ''
                } ${column.centered ? classes.centered : ''}`}
              >
                {column.label}
                {column.tooltipText && (
                  <ColumnTooltip content={column.tooltipText} />
                )}
              </TableCell>
            ) : (
              <TableCell
                key={index}
                size={column.size}
                className={`${classes.tableCellRoot} ${
                  column.className ? column.className : ''
                } ${column.centered ? classes.centered : ''}`}
                sortDirection={orderBy === column.key ? order : false}
              >
                <TableSortLabel
                  hideSortIcon={!column.key || !onRequestSort}
                  active={column.key ? orderBy === column.key : false}
                  direction={orderBy === column.key ? order : 'asc'}
                  onClick={onSortOrderChange(column.key)}
                  className={`${
                    (!column.key || !onRequestSort) &&
                    classes.noSortingColumnSpacing
                  }`}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ),
          )
        : []}
      {hasCollapsedContent && (
        <TableCell
          className={`${classes.tableCellRoot} ${classes.tableCellCollapsible}`}
        />
      )}
    </TableRow>
  );
};

export default TableHeading;
