// TODO: Refactor the drop image / preview image method to a function
import { useEffect, useState } from 'react';
import PageContainer from 'components/PageContainer';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FIRM } from 'adminConstants/routes';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import FundPageInformation from './FundPageInformation';
import AccountManagersTable from './AccountManagersTable';
import useFileUpload from 'hooks/ui/useFileUpload';
import { useNotification } from 'hooks/ui/useNotification';
import WhitelabellingSettings from './WhitelabellingSettings';
import GeneralInformation from './GeneralInformation';
import FirmLogos from './FirmLogos';
import BankAccountDetailsForFees from './BankAccountDetailsForFees';
import BankAccountDetailsForIncomingPayments from './BankAccountDetailsForIncomingPayments';
import {
  CompanyBreakdownDisplayMode,
  TaxReliefDisplayMode,
  InvestmentIdentifierDisplayMode,
  TileType,
  ShowSubscriptionPagePerformanceChartMode,
  SyndicatesArrangement,
} from 'further-types/firm';
import { UploadType } from 'further-types/files';
import InvestorPortalSettings from './InvestorPortalSettings';
import WithdrawalFees from './WithdrawalFees';
import PageContent from 'components/PageContent';
import PageSection from 'components/PageSection';
import ButtonGroup from 'components/ButtonGroup';
import RecordView from 'components/RecordView';
import WebhookSettings from './WebhookSettings';
import { Error } from 'components/FormElements/LayoutComponents';
import { investmentFirmSchema, InvestmentFirmFormData } from './schema';

const InvestmentFirmAddEditScreen: React.FC = () => {
  const { uploadFile } = useFileUpload();
  const [accountManagerData, setKeyManagersData] = useState([]);
  const { id } = useParams<{ id?: string }>();
  const { error, success } = useNotification();
  const history = useHistory();

  const isCreatePage = !id;

  const { firm, createFirm, updateFirm, createAccountManager } = useFirm({
    params: { firmId: id },
  });

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Firms', link: '/firm' },
    {
      label: id ? 'Update Firm' : 'Add Firm',
      link: '/',
      isActive: true,
    },
  ];

  const [previewImages, setPreviewImages] = useState<Record<string, any>>({
    firmLogo: null,
    investmentFirmLogo: null,
    whiteLabelConfig: {
      colorBgLogo: {
        url: null,
      },
      whiteBgLogo: {
        url: null,
      },
    },
  });

  useEffect(() => {
    if (firm.data) {
      setPreviewImages({
        firmLogo: firm.data?.firmLogo,
        investmentFirmLogo: firm.data?.investmentFirmLogo,
        whiteLabelConfig: {
          colorBgLogo: {
            url: firm.data?.whiteLabelConfig?.colorBgLogo?.url,
          },
          whiteBgLogo: {
            url: firm.data?.whiteLabelConfig?.whiteBgLogo?.url,
          },
        },
      });
      //@ts-expect-error
      setKeyManagersData(firm.data.accountManagers ?? []);
    }
  }, [firm.data]);

  const formMethods = useForm<InvestmentFirmFormData>({
    shouldFocusError: true,
    resolver: zodResolver(investmentFirmSchema),
    criteriaMode: 'all',
    values: {
      //@ts-expect-error
      withdrawalFees: firm.data?.withdrawalFees ?? [],
      webhookSettings: {
        newInvestmentWebhookUrl:
          firm.data?.webhookSettings?.newInvestmentWebhookUrl,
      },
      firmName: firm.data?.firmName ?? '',
      keyContactName: firm.data?.keyContactName ?? '',
      keyContactEmail: firm.data?.keyContactEmail ?? '',
      secondaryContactName: firm.data?.secondaryContactName,
      secondaryContactEmail: firm.data?.secondaryContactEmail,
      summaryAttachments: firm.data?.summaryAttachments,
      excelFilesPassword: firm.data?.excelFilesPassword,
      systemEmailRecipient: firm.data?.systemEmailRecipient,
      firmLogo: firm.data?.firmLogo,
      investmentFirmLogo: firm.data?.investmentFirmLogo,
      domain: firm.data?.domain ?? '',
      //@ts-expect-error
      whiteLabelConfig: {
        ...firm.data?.whiteLabelConfig,
        syndicatesArrangement:
          (firm.data?.whiteLabelConfig
            ?.syndicatesArrangement as SyndicatesArrangement) ??
          SyndicatesArrangement.TaxYear,
        enableKyb: firm.data?.whiteLabelConfig?.enableKyb ?? true,
        allowPortalCashWithdrawals:
          firm?.data?.whiteLabelConfig?.allowPortalCashWithdrawals === undefined //want to default to true, so if not set will set value to true
            ? true
            : firm?.data?.whiteLabelConfig?.allowPortalCashWithdrawals,
        configurableTile1:
          firm?.data?.whiteLabelConfig?.configurableTile1 ?? TileType.MULTIPLE,
        configurableTile2:
          firm?.data?.whiteLabelConfig?.configurableTile2 ??
          TileType.SUBSCRIPTIONS,
        hideCashBalanceBefore: firm?.data?.whiteLabelConfig
          ?.hideCashBalanceBefore
          ? new Date(firm.data.whiteLabelConfig.hideCashBalanceBefore as string)
          : null,
        investmentIdentifierDisplayMode:
          firm?.data?.whiteLabelConfig?.investmentIdentifierDisplayMode ??
          InvestmentIdentifierDisplayMode.SubscriptionDate,
        taxReliefDisplayMode:
          firm?.data?.whiteLabelConfig?.taxReliefDisplayMode ??
          TaxReliefDisplayMode.Actual,
        companyBreakdownDisplayMode:
          firm?.data?.whiteLabelConfig?.companyBreakdownDisplayMode ??
          CompanyBreakdownDisplayMode.UnderSummaryTable,
        showSubscriptionPagePerformanceChart:
          firm?.data?.whiteLabelConfig?.showSubscriptionPagePerformanceChart ??
          ShowSubscriptionPagePerformanceChartMode.Always,
      },
      //@ts-expect-error
      contactDetails: firm.data?.contactDetails,
      //@ts-expect-error
      bankAccountForFeesExport: firm.data?.bankAccountForFeesExport,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = async (data) => {
    try {
      if (isCreatePage) {
        //@ts-expect-error
        const response = await createFirm.mutateAsync({ data });
        if (response.status === 200) {
          success(response.data.responseMsg);
          //@ts-expect-error
          const managerResponse = await createAccountManager.mutateAsync({
            accountManagerData,
            firmId: response.data.data._id,
          });
          setKeyManagersData(managerResponse.data);
        }
      } else {
        const response = await updateFirm.mutateAsync(data);
        success(response.responseMsg);
      }

      history.push(FIRM);
    } catch (err: any) {
      error(err);
    }
  };

  const handleImageUpload = async (files, onSuccess) => {
    if (!files?.[0]?.path) return;
    try {
      const { url } = await uploadFile(files[0], UploadType.FirmLogos);
      onSuccess(url);
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded");
    }
  };

  const handleFileUpload = async (files, onSuccess) => {
    if (!files?.[0]?.path) return;
    try {
      const { filePath, url } = await uploadFile(
        files[0],
        UploadType.FirmDocuments,
      );

      onSuccess(files?.[0]?.path, filePath, url);
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded");
    }
  };

  if (firm.isLoading && !isCreatePage) return <div>Loading...</div>;

  return (
    <PageContainer
      heading={id ? 'Firm: Firm Details' : 'Add Firm'}
      breadcrumbs={breadcrumbs}
    >
      <PageContent>
        <FormProvider {...formMethods}>
          <form>
            <RecordView size="full">
              <PageSection title="General information">
                <GeneralInformation
                  firm={firm}
                  handleFileUpload={handleFileUpload}
                />
              </PageSection>
              <PageSection title="Firm logos" showDivider>
                <FirmLogos
                  handleImageUpload={handleImageUpload}
                  previewImages={previewImages}
                  setPreviewImages={setPreviewImages}
                />
              </PageSection>
              <PageSection title="White labelling settings" showDivider>
                <WhitelabellingSettings />
              </PageSection>
              <PageSection title="Webhook settings" showDivider>
                <WebhookSettings />
              </PageSection>
              <PageSection title="Investor portal settings" showDivider>
                <InvestorPortalSettings />
              </PageSection>
              <PageSection title="Withdrawal fees" showDivider>
                <WithdrawalFees />
              </PageSection>

              {!isCreatePage && (
                <PageSection
                  title="Fund bank account details for incoming payments"
                  showDivider
                >
                  <BankAccountDetailsForIncomingPayments firm={firm.data} />
                </PageSection>
              )}

              <PageSection
                title="Fund bank account details for fee exports"
                showDivider
              >
                <BankAccountDetailsForFees />
              </PageSection>

              <PageSection
                title="Individual manager information"
                tooltip="Anyone in this list may be featured in the Manager’s update section of your investor reporting."
                showDivider
              >
                <AccountManagersTable
                  accountManagerData={accountManagerData ?? []}
                  setKeyManagersData={setKeyManagersData}
                />
              </PageSection>

              <PageSection title="Fund page information" showDivider>
                <FundPageInformation
                  firmId={id}
                  portfolioData={firm.data?.firmPortfolio ?? []}
                  isCreatePage={isCreatePage}
                />
              </PageSection>

              <ButtonGroup>
                <Link to={FIRM}>
                  <Button variant="outlined">Cancel</Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  disabled={
                    updateFirm.isPending ||
                    createAccountManager.isPending ||
                    createFirm.isPending
                  }
                >
                  {isCreatePage ? 'Save' : 'Update'}
                </Button>
              </ButtonGroup>

              {Object.values(errors).length > 0 && (
                <Error>
                  There is an error with one or more fields above. Please review
                  all errors and try again.
                </Error>
              )}
            </RecordView>
          </form>
        </FormProvider>
      </PageContent>
    </PageContainer>
  );
};

export default InvestmentFirmAddEditScreen;
