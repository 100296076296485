import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { COLUMNS_CONFIG_KEY_SUFFIX } from 'components/InteractiveTable';
import { FILTERS_KEY_SUFFIX } from 'hooks/ui/useFilters';
import { PAGINATION_KEY_SUFFIX } from 'hooks/ui/usePagination';
import api from 'lib/trpcClient';

export default function useSwitchOrganisation() {
  const { loginWithRedirect } = useAuth0();
  const queryClient = useQueryClient();
  const { invalidate } = api.useUtils();

  return (org: { auth0OrgId?: string }) => {
    Object.keys(localStorage).forEach((key) => {
      if (key.endsWith(FILTERS_KEY_SUFFIX)) {
        localStorage.removeItem(key);
      }
      if (key.endsWith(PAGINATION_KEY_SUFFIX)) {
        localStorage.removeItem(key);
      }
      if (key.endsWith(COLUMNS_CONFIG_KEY_SUFFIX)) {
        localStorage.removeItem(key);
      }
    });

    queryClient.clear();
    invalidate();

    loginWithRedirect({
      authorizationParams: {
        organization: org.auth0OrgId,
      },
      appState: {
        returnTo: '/',
      },
    });
  };
}
