import {
  manuallyPassAppropriateness,
  updateInvestorQuestion,
} from '@redux/actions/Investor';
import { useDispatch } from 'react-redux';
import { useGetRole } from 'hooks/ui/useGetRole';
import {
  signUpSourceLabel,
  getSignUpDetail,
} from 'helpers/investor/getSignUpSourceLabel';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { dateToLabel, getCurrentAppropriatenessStatus } from 'further-ui/utils';
import RecordRow from 'components/RecordRow';
import Button from 'components/Button';

type Props = any;

const InvestorAppropriateness: React.FC<Props> = ({
  downloadQuestion,
  id,
  investorDetail,
  refetchInvestor,
}) => {
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();
  const { isSuperAdmin } = useGetRole();
  const { questionnaireData, answerList } = investorDetail;

  const appropriateness = questionnaireData?.length
    ? questionnaireData[0]
    : null;
  const hasAnswerList = answerList?.length > 0;

  const appropriatenessPassCount =
    answerList?.filter((answers) => answers.status === true).length || 0;
  const appropriatenessFailureCount =
    answerList?.length - appropriatenessPassCount;
  const showManuallyPassButton =
    isSuperAdmin &&
    !appropriateness?.isManuallyPassedByAdmin &&
    !appropriateness?.status;

  return (
    <>
      <RecordRow label="Account created by" direction="row">
        <span>{signUpSourceLabel[investorDetail?.signUpSource]}</span>
        <span>{getSignUpDetail(investorDetail, isSuperAdmin)}</span>
      </RecordRow>
      <RecordRow label="Appropriateness assessment" direction="row">
        {hasAnswerList ? (
          <>
            <span>Passes: {appropriatenessPassCount}</span>
            <span>Failures: {appropriatenessFailureCount}</span>
          </>
        ) : (
          <span>Attempts: {appropriateness?.attempt || 0}</span>
        )}
      </RecordRow>
      <RecordRow label="Current appropriateness status" direction="row">
        <span>{getCurrentAppropriatenessStatus(appropriateness)}</span>
        <span>{dateToLabel(appropriateness?.updatedAt, undefined, true)}</span>
        {appropriateness?.attemptBlocked && (
          <Button
            size="extra-small"
            variant="outlined"
            color="primary"
            onClick={async () => {
              await handleApiRequest(async (showSuccess) => {
                const response = await dispatch<any>(
                  updateInvestorQuestion(id),
                );
                showSuccess(response?.responseMsg);
              });
            }}
          >
            Allow appropriateness
          </Button>
        )}
        {showManuallyPassButton && (
          <Button
            size="extra-small"
            variant="outlined"
            color="primary"
            onClick={async () => {
              await handleApiRequest(async (showSuccess) => {
                const response = await dispatch<any>(
                  manuallyPassAppropriateness(id),
                );
                showSuccess(response?.responseMsg);
                refetchInvestor();
              });
            }}
          >
            Manually pass appropriateness
          </Button>
        )}
        {questionnaireData?.length > 0 && (
          <Button
            size="extra-small"
            variant="outlined"
            color="primary"
            onClick={downloadQuestion}
          >
            Download appropriateness results
          </Button>
        )}
      </RecordRow>
    </>
  );
};

export default InvestorAppropriateness;
