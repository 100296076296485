import { Step, StepLabel, Stepper } from '@mui/material';
import PageContainer from 'components/PageContainer';
import { EIS } from 'adminConstants/routes';
import { FC, useEffect, useState } from 'react';
import FundSelector from './FundSelector';
import { useHistory, useParams } from 'react-router-dom';
import { SignatureType } from 'further-types/ki-fund-certificate';

import useStyles from './styles';
import DetailsForm, { FormValues } from './DetailsForm';
import Summary from './Summary';
import useKiFundCertificateBatch from 'hooks/data/kiFundCertificate/useKiFundCertificateBatch';
import FullPageSuccess from 'components/FullPageSuccess';
import { toRequestDate } from 'further-ui/utils';
import useDownloadKiFundCertificates from 'hooks/data/kiFundCertificate/useDownloadKiFundCertificates';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'S/EIS Tasklist', link: EIS },
  {
    label: 'Create KI fund certificates',
    isActive: true,
  },
];

const steps = ['Select tranche', 'Form data input', 'Summary', 'Success'];

type FundDetails = {
  fundId: string;
  fundName: string;
  fundCloseDate: Date;
};

const KiFundCertificateBatchForm: FC = () => {
  const history = useHistory();
  const { classes } = useStyles();
  const downloadEis5Certficates = useDownloadKiFundCertificates();
  const { batchId } = useParams<{ batchId: string }>();
  const isEditMode = !!batchId;

  const {
    getCertificateBatch,
    createCertificateBatch,
    updateCertificateBatch,
  } = useKiFundCertificateBatch();
  const certificateBatch = getCertificateBatch(batchId);

  const [activeStep, setActiveStep] = useState(0);
  const [certificatesBatchId, setCertificatesBatchId] = useState(batchId);
  const [selectedFundDetails, setSelectedFundDetails] = useState<FundDetails>();
  const [formValues, setFormValues] = useState<FormValues>({
    fundReferenceNumber: '',
    fundManagerAddress: {
      address1: '',
      address2: '',
      city: '',
      postcode: '',
      country: '',
    },
    fundManagerSignatoryName: '',
    capicityInWhichSigned: '',
    //@ts-expect-error
    signatureDate: undefined,
    signatureType: SignatureType.PlainText,
    notifyInvestors: false,
  });

  useEffect(() => {
    if (!certificateBatch.data) return;

    setFormValues({
      ...certificateBatch.data,
      signatureDate: new Date(certificateBatch.data.signatureDate),
      notifyInvestors: false,
    });
  }, [certificateBatch.data]);

  const handleBack = () => {
    if (activeStep === 0) {
      history.push(EIS);
    } else {
      setActiveStep((currentStep) => currentStep - 1);
    }
  };

  const handleSelectFund = (fundDetails: FundDetails) => {
    setSelectedFundDetails(fundDetails);
    handleNext();
  };

  const handleSubmitDetails = (values: FormValues) => {
    setFormValues(values);
    handleNext();
  };

  const handleSubmit = async () => {
    if (!formValues) return;

    const result = isEditMode
      ? await updateCertificateBatch.mutateAsync({
          ...certificateBatch.data,
          ...formValues,
          //@ts-expect-error
          signatureDate: toRequestDate(formValues.signatureDate),
        })
      : await createCertificateBatch.mutateAsync({
          //@ts-expect-error
          fundId: selectedFundDetails.fundId,
          ...formValues,
          //@ts-expect-error
          signatureDate: toRequestDate(formValues.signatureDate),
        });

    if (result.data) {
      setCertificatesBatchId(result.data._id);
      handleNext();
    }
  };

  const handleNext = () => {
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleDownload = () => {
    downloadEis5Certficates.download(certificatesBatchId);
  };

  const heading = isEditMode
    ? 'S/EIS Certificates: Update KI fund certificates'
    : 'S/EIS Certificates: Create KI fund certificates';

  const isLoading =
    createCertificateBatch.isPending ||
    updateCertificateBatch.isPending ||
    (isEditMode && certificateBatch.isLoading);

  if (activeStep === 3) {
    return (
      <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
        <FullPageSuccess
          title={`Congratulations, you have ${
            isEditMode ? 'updated' : 'created'
          } new EIS KI certificates`}
          additionalText="Your investors’ certificates have been uploaded to their investor portals."
          proceedLink={EIS}
          proceedText="Back to completed wizards"
          secondaryCallback={handleDownload}
          secondaryText="Download these certificates"
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer
      heading={heading}
      breadcrumbs={breadcrumbs}
      loading={isEditMode && isLoading}
    >
      <PageContent>
        <RecordView>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((stepLabel) => {
              return (
                <Step key={stepLabel}>
                  <StepLabel>{stepLabel}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 0 && (
            <FundSelector
              onNext={handleSelectFund}
              isEditMode={isEditMode}
              onBack={handleBack}
              defaultFundId={certificateBatch.data?.fundId}
            />
          )}
          {activeStep === 1 && selectedFundDetails && (
            <DetailsForm
              onNext={handleSubmitDetails}
              onBack={handleBack}
              selectedFund={selectedFundDetails}
              defaultValues={formValues}
            />
          )}
          {activeStep === 2 && selectedFundDetails && (
            <Summary
              onNext={handleSubmit}
              onBack={handleBack}
              values={formValues}
              selectedFund={selectedFundDetails}
              isLoading={isLoading}
            />
          )}
        </RecordView>
      </PageContent>
    </PageContainer>
  );
};

export default KiFundCertificateBatchForm;
