import React, { PropsWithChildren } from 'react';
import {
  Breakpoint,
  IconButton,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'components/Button';

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.grey[500],
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

const ActionsContainer = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 6),
  display: 'flex',
  justifyContent: 'flex-end',
}));

type Props = {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  title?: string;
  content?: React.ReactNode;
  btnLabels?: {
    cancel: string;
    confirm: string;
  };
  size?: Breakpoint;
  pending?: boolean;
};

const Dialog: React.FC<PropsWithChildren<Props>> = ({
  open = false,
  onClose,
  onConfirm,
  title,
  children,
  btnLabels = {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  size = 'sm',
  pending,
}) => (
  <MuiDialog
    open={open}
    onClose={onClose}
    aria-labelledby="dialog-title"
    maxWidth={size}
    fullWidth
  >
    <DialogTitle id="dialog-title">{title}</DialogTitle>
    <CloseButton aria-label="close" onClick={onClose} size="large">
      <CloseIcon />
    </CloseButton>
    <DialogContent dividers>{children}</DialogContent>
    <ActionsContainer>
      <Button onClick={onClose} color="primary" variant="outlined">
        {btnLabels.cancel}
      </Button>
      {btnLabels.confirm ? (
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          loading={pending}
        >
          {btnLabels.confirm}
        </Button>
      ) : null}
    </ActionsContainer>
  </MuiDialog>
);

export default Dialog;
