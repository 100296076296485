import { TextField } from '@mui/material';
import Dialog from 'components/Dialog';
import Spacing from 'components/Spacing';
import { transactionType as rules } from 'further-ui/validations';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import useTransactionTypesManagement from 'hooks/lp/data/accounts/useTransactionTypesManagement';
import useAccountSelectorOptions from 'hooks/form/useAccountSelectorOptions';
import AccountSelector from 'components/FormElements/AccountSelector';

type FormValues = z.infer<typeof rules.CreateTransactionType>;

type Props = {
  vehicleId: string;
  onClose: () => void;
  selectedAccountId?: string;
};

const AddTransactionType: React.FC<Props> = ({
  vehicleId,
  onClose,
  selectedAccountId,
}) => {
  const { accountOptions } = useAccountSelectorOptions();
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(rules.CreateTransactionType),
    defaultValues: {
      accountId: selectedAccountId,
      name: '',
    },
  });

  const { createTransactionType, updateTransactionType } =
    useTransactionTypesManagement({
      onSuccess: onClose,
    });

  const onSubmit = (data: FormValues) => {
    createTransactionType.mutate({
      ...data,
      vehicleId,
    });
  };

  return (
    <Dialog
      title="Add transaction type"
      open
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
      pending={
        createTransactionType.isPending || updateTransactionType.isPending
      }
    >
      <form>
        <Spacing size="sm">
          <Controller
            name="accountId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AccountSelector
                error={error}
                {...field}
                accountOptions={accountOptions}
                disabled={!!selectedAccountId}
              />
            )}
          />

          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                inputRef={ref}
                placeholder="Transaction type name"
                error={!!error}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Spacing>
      </form>
    </Dialog>
  );
};

export default AddTransactionType;
