import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  centered?: boolean;
  spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  fullWidth?: boolean;
};

const spacingMap = {
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
  xl: 10,
};

const StyledColumn = styled('div', {
  shouldForwardProp: (prop) =>
    !['fullWidth', 'centered', 'spacing'].includes(prop.toString()),
})(({ theme, centered, spacing, fullWidth }) => ({
  display: 'flex',
  alignItems: centered ? 'center' : undefined,
  gap: theme.spacing(spacing),
  flexDirection: 'column',
  width: fullWidth ? '100%' : undefined,
}));

const Column: React.FC<PropsWithChildren<Props>> = ({
  children,
  spacing,
  className,
  fullWidth,
  ...props
}) => {
  return (
    <StyledColumn
      className={className}
      spacing={spacing ? spacingMap[spacing] : 0}
      fullWidth={fullWidth}
      {...props}
    >
      {children}
    </StyledColumn>
  );
};

export default Column;
