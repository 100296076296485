import { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RestrictedRoute from './RestrictedRoute';
import CommonRoutes from './CommonRoutes';
import * as paths from 'adminConstants/routes';
import Vehicles from 'routes/LpPages/Vehicle/ListVehicles';

import GeneralLedger from 'routes/LpPages/FundAccounting';
import AddEditVehicle from 'routes/LpPages/Vehicle/AddEditVehicle';
import FundSetup from 'routes/LpPages/FundSetup';
import AddEditIncomeOrExpense from 'routes/LpPages/IncomeAndExpenses/AddEditIncomeOrExpense';
import ListIncomeOrExpense from 'routes/LpPages/IncomeAndExpenses/ListIncomeAndExpenses';

const LpRoutes: FC = () => (
  <Switch>
    <Redirect path="/" exact to={paths.GENERAL_LEDGER} />
    <RestrictedRoute component={Vehicles} path={paths.VEHICLES} />
    <RestrictedRoute component={AddEditVehicle} path={paths.VEHICLE_ADD} />
    <RestrictedRoute component={AddEditVehicle} path={paths.VEHICLE_EDIT} />
    <RestrictedRoute component={GeneralLedger} path={paths.GENERAL_LEDGER} />
    <RestrictedRoute component={FundSetup} path={paths.FUND_SETUP} />
    <RestrictedRoute
      component={AddEditIncomeOrExpense}
      path={paths.INCOME_OR_EXPENSE_ADD}
    />
    <RestrictedRoute
      component={AddEditIncomeOrExpense}
      path={paths.INCOME_OR_EXPENSE_EDIT}
    />
    <RestrictedRoute
      component={ListIncomeOrExpense}
      path={paths.INCOME_OR_EXPENSE_LIST}
    />
    {...CommonRoutes}
  </Switch>
);

export default LpRoutes;
