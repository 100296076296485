import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/investment-cool-off';
import { api } from 'lib/httpClient';

export function useInvestmentCoolOff(investorId: string) {
  return useQuery({
    queryKey: ['investment-cool-off', investorId],
    queryFn: async () => {
      const { data } = await api.get<
        ApiResponse<Api.GetInvestmentCoolOffForInvestorResponse>
      >(`investment-cool-off/${investorId}`);
      return data.data;
    },
    enabled: !!investorId,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}

export default useInvestmentCoolOff;
