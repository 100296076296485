import { FC } from 'react';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import { styled, Checkbox } from '@mui/material';
import { AppTextInput } from 'components/FormElements';
import { Controller, Control, FieldArrayWithId } from 'react-hook-form';
import { FormValues } from './types';

const TableCell = styled('td')(({ theme }) => ({
  paddingRight: theme.spacing(2),
}));

type Props = {
  fields: Array<FieldArrayWithId<FormValues, 'allocations', 'id'>>;
  control: Control<FormValues>;
  isFixed: boolean;
};

const AllocationsFormBody: FC<Props> = ({ fields, control, isFixed }) => {
  return (
    <>
      {fields.map((field, index) => (
        <tr key={field.id}>
          <TableCell>{field.investorName}</TableCell>
          <TableCell>{numberToCurrencyString(field.totalCommitment)}</TableCell>
          <TableCell>
            {numberToCurrencyString(field.unallocatedCommitment)}
          </TableCell>
          <TableCell>{dateToLabel(field.closeDate)}</TableCell>
          <TableCell>
            <Controller
              control={control}
              name={`allocations.${index}.allocationAmount`}
              render={({ field: { value, onChange } }) => (
                <AppTextInput
                  narrow
                  value={value}
                  disabled={isFixed}
                  onChange={(e) => onChange(Number(e.target.value))}
                />
              )}
            />
          </TableCell>
          <TableCell>
            <Controller
              control={control}
              name={`allocations.${index}.allocationPercentage`}
              render={({ field: { value, onChange } }) => (
                <AppTextInput
                  narrow
                  value={value}
                  disabled={isFixed}
                  onChange={(e) => onChange(Number(e.target.value))}
                />
              )}
            />
          </TableCell>
          <TableCell>
            <Controller
              control={control}
              name={`allocations.${index}.excludeFromAllocation`}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  disabled={isFixed}
                  checked={value}
                  onChange={() => onChange(!value)}
                />
              )}
            />
          </TableCell>
        </tr>
      ))}
    </>
  );
};

export default AllocationsFormBody;
