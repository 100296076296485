import { Button } from '@mui/material';
import { round } from 'lodash';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  button: {
    marginLeft: '6px',
    paddingTop: '0.25rem !important',
    paddingBottom: '0.25rem !important',
  },
}));

const ClearColumnButton = ({ keyToClear, setShareholdings, disable }) => {
  const { classes } = useStyles();

  const handleClick = () => {
    setShareholdings((prevState) => {
      if (keyToClear === 'sharesAllocatedForSale') {
        return prevState.map((sh) => {
          return {
            ...sh,
            sharesAllocatedForSale: 0,
            totalSaleValue: 0,
            performanceFee: 0,
            accruedFeesCharged: 0,
            totalToBeReturned: 0,
            netTaxableValue: 0,
            grossTaxableValue: 0,
          };
        });
      }
      if (keyToClear === 'performanceFee') {
        return prevState.map((sh) => {
          let netTaxableValue = round(
            sh.grossTaxableValue - sh.accruedFeesCharged,
            2,
          );
          let totalToBeReturned = round(
            sh.totalSaleValue - sh.accruedFeesCharged,
            2,
          );

          if (
            sh.previousAccruedFeesCharged ||
            sh.previousPerformanceFeesCharged
          ) {
            totalToBeReturned -=
              sh.previousAccruedFeesCharged + sh.previousPerformanceFeesCharged;
            netTaxableValue -=
              sh.previousAccruedFeesCharged + sh.previousPerformanceFeesCharged;
          }

          return {
            ...sh,
            performanceFee: 0,
            totalToBeReturned,
            netTaxableValue,
          };
        });
      }
      return prevState;
    });
  };

  return (
    <Button
      className={classes.button}
      variant="outlined"
      size="small"
      color="primary"
      disabled={disable}
      onClick={handleClick}
    >
      Clear all
    </Button>
  );
};

export default ClearColumnButton;
