import { styled, Typography } from '@mui/material';
import Heading from 'components/Heading';
import InteractiveTable, { RowsGroup } from 'components/InteractiveTable';
import PageFilters, { Filter } from 'components/PageFilters';
import PageLoader from 'components/PageLoader';
import SearchField from 'components/SearchField';
import Spacing from 'components/Spacing';
import { useLayoutContext } from 'contexts/LayoutContext';
import useFilters from 'hooks/ui/useFilters';
import api from 'lib/trpcClient';
import AddEditAccount from './AddEditAccount';
import { Add, Download, UploadFile } from '@mui/icons-material';
import { AccountSectionLabels } from 'further-ui/labels';
import { useDisclosure } from 'further-ui/hooks';
import getColumns from './helpers/getColumns';
import AddTransactionType from './AddTransactionType';
import TransactionTypesTable from './TransactionTypesTable';
import { Row } from 'components/Layout';
import useAccountsManagement from 'hooks/lp/data/accounts/useAccountsManagement';
import Button from 'components/Button';
import { useMemo, useRef } from 'react';
import useTransactionTypesManagement from 'hooks/lp/data/accounts/useTransactionTypesManagement';
import { convertFileToBase64 } from 'further-ui/utils';

const pageId = 'fund-setup-chart-of-accounts';

const InnerTable = styled('div')({
  width: 'calc(((100% - 124px) / 2) + 124px)',
});

const AddTransactionTypeButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(3, 0, 0, 2),
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const ChartOfAccounts: React.FC = () => {
  const { selectedVehicleId } = useLayoutContext();
  const inputFile = useRef<HTMLInputElement>(null);

  const accountFormDisclosure = useDisclosure<{ accountId: string }>();
  const transactionTypeFormDisclosure = useDisclosure<{
    selectedAccountId?: string;
  }>();

  const { filters, handleChangeFilters } = useFilters(pageId, {
    defaultFilters: {
      searchQuery: '',
    },
  });

  const { deleteAccount, exportAccounts } = useAccountsManagement();
  const { importTransactionTypes } = useTransactionTypesManagement();

  const accounts = api.account.listAllAccounts.useQuery({
    vehicleId: selectedVehicleId ?? '',
    searchQuery: filters.searchQuery,
  });

  const groupedRows = useMemo(() => {
    const groupedRows: RowsGroup<{ transactionTypes: Array<any> }> = {};

    Object.keys(accounts.data ?? {}).forEach((section) => {
      groupedRows[section] = {
        title: AccountSectionLabels[section],
        rows:
          accounts.data?.[section]?.map((row) => ({
            ...row,
            isExpandable: true,
          })) ?? [],
      };
    });
    return groupedRows;
  }, [JSON.stringify(accounts.data)]);

  if (accounts.isLoading) {
    return <PageLoader />;
  }

  const selectedAccount = Object.values(accounts.data ?? [])
    .flat()
    .find(
      (account) => account.id === accountFormDisclosure?.actionProps?.accountId,
    );

  const handleDeleteAccount = (id: string) => {
    deleteAccount.mutate({
      id,
      vehicleId: selectedVehicleId ?? '',
    });
  };

  const handleExport = () => {
    exportAccounts.mutate({
      vehicleId: selectedVehicleId ?? '',
    });
  };

  const handleImport = async () => {
    const file = inputFile.current?.files?.[0];

    if (!file) return;

    const base64File = await convertFileToBase64(file);

    importTransactionTypes.mutate({
      file: base64File,
      vehicleId: selectedVehicleId ?? '',
    });
  };

  return (
    <Spacing>
      <Spacing size="sm">
        <Heading variant="h3" noMargin>
          Chart of accounts
        </Heading>
        <Typography variant="body1" color="text.secondary">
          You may add or edit accounts within this table. For bulk changes, you
          may export the chart, make changes, and import it again.
        </Typography>
      </Spacing>
      <PageFilters
        pageId={pageId}
        dropdownActions={[
          {
            label: 'Add Account',
            icon: Add,
            onClick: () => {
              accountFormDisclosure.onOpen();
            },
          },
          {
            label: 'Add Transaction Type',
            icon: Add,
            onClick: () => {
              transactionTypeFormDisclosure.onOpen();
            },
          },
          {
            label: 'Export',
            icon: Download,
            onClick: handleExport,
          },
          {
            label: 'Import',
            icon: UploadFile,
            onClick: () => inputFile.current?.click(),
          },
        ]}
      >
        <Filter>
          <SearchField
            autoSearchOnDebounce
            name="searchQuery"
            variant="standard"
            placeholder="Search by code or account name"
            fullWidth
            value={filters.searchQuery}
            onSearch={(searchQuery) => {
              handleChangeFilters({ searchQuery });
            }}
            allowEmptyQuery
            iconPlacement="left"
          />
        </Filter>
      </PageFilters>
      <input
        name="importTransactionTypesFile"
        type="file"
        ref={inputFile}
        hidden
        onChange={handleImport}
        onClick={(e) => {
          if (e.currentTarget) {
            e.currentTarget.value = '';
          }
        }}
      />
      <InteractiveTable
        fullHeight
        id={`${pageId}-table`}
        columns={getColumns({ handleDeleteAccount, accountFormDisclosure })}
        rows={groupedRows}
        sectionHeader="Code"
        sectionValueField="accountCode"
        loading={accounts.isFetching}
        disableTableConfiguration
        disableSorting
        getExpandedContent={(row) => {
          return (
            <Row justify="flex-end">
              <InnerTable>
                <TransactionTypesTable
                  vehicleId={selectedVehicleId ?? ''}
                  accountId={row.id ?? ''}
                  transactionTypes={row.transactionTypes}
                />

                <AddTransactionTypeButton
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => {
                    transactionTypeFormDisclosure.stageAction({
                      selectedAccountId: row.id,
                    });
                    transactionTypeFormDisclosure.onOpen();
                  }}
                >
                  Add new transaction type
                </AddTransactionTypeButton>
              </InnerTable>
            </Row>
          );
        }}
      />
      {accountFormDisclosure.isOpen && (
        <AddEditAccount
          vehicleId={selectedVehicleId ?? ''}
          account={selectedAccount}
          onClose={accountFormDisclosure.onClose}
        />
      )}
      {transactionTypeFormDisclosure.isOpen && (
        <AddTransactionType
          vehicleId={selectedVehicleId ?? ''}
          selectedAccountId={
            transactionTypeFormDisclosure.actionProps?.selectedAccountId
          }
          onClose={transactionTypeFormDisclosure.onClose}
        />
      )}
    </Spacing>
  );
};

export default ChartOfAccounts;
