import Table, { TableActions } from 'components/Table';
import {
  numberToCurrencyString,
  renderMultiple,
  numberToDisplayString,
} from 'further-ui/utils';
import { Link } from 'react-router-dom';
import {
  createOrganisationViewRoute,
  ORGANISATION,
} from 'adminConstants/routes';
import topOrganisationTableStyles from './styles';
import useTopParentOrganisations from 'hooks/data/dashboards/useTopParentOrganisations';
import { Tooltip } from '@mui/material';
import { InfoOutlined, Visibility } from '@mui/icons-material';
import LoadingError from '../LoadingError';

const TopParentOrganisationTable = ({ firmId, height }) => {
  const { classes } = topOrganisationTableStyles();
  const {
    data: organisations,
    isFetching,
    isRefetching,
    isError,
  } = useTopParentOrganisations({
    params: { firmId },
  });

  const isLoading = isFetching || isRefetching;
  const columns = [
    {
      label: 'Name',
      key: 'name',
      sort: false,
    },
    {
      label: 'Subscription amount',
      key: 'totalInvestmentAmount',
      render: (organisation) =>
        numberToCurrencyString(organisation.totalInvestmentAmount),
      sort: false,
    },
    {
      label: 'Current value AUM',
      key: 'currentValueAum',
      render: (organisation) =>
        numberToCurrencyString(organisation.currentValueAum),
      sort: false,
    },
    {
      label: 'Total portfolio value',
      key: 'totalPortfolioValue',
      render: (organisation) =>
        numberToCurrencyString(organisation.totalPortfolioValue),
      sort: false,
    },
    {
      label: (
        <div className={classes.tooltipContainer}>
          Blended multiple{' '}
          <Tooltip title="This is the live multiple on investment across all subscriptions linked to this adviser. The multiple considers only paid-up funds and does not include unpaid but committed capital.">
            <InfoOutlined className={classes.tooltipIcon} />
          </Tooltip>
        </div>
      ),
      key: 'blendedMultiple',
      render: (organisation) => renderMultiple(organisation.blendedMultiple),
      sort: false,
    },
    {
      label: 'Subscriptions',
      key: 'totalInvestments',
      render: (organisation) =>
        numberToDisplayString(organisation.totalInvestments),
      sort: false,
    },
    {
      label: 'Clients',
      key: 'investorCount',
      render: (organisation) =>
        numberToDisplayString(organisation.investorCount),
      sort: false,
    },
    {
      label: '',
      key: 'actions',
      sort: false,
      render: (organisation) => (
        <TableActions
          actions={[
            {
              label: 'View',
              icon: Visibility,
              link: createOrganisationViewRoute(organisation._id),
              color: 'primary',
            },
          ]}
        />
      ),
    },
  ];

  if (isError) {
    return <LoadingError height={height} showCard={false} />;
  }

  return (
    <>
      <Link className={classes.link} to={ORGANISATION}>
        View all
      </Link>
      <Table
        columns={columns}
        tablebody={organisations ?? []}
        pagination={false}
        emptyMessage="No parent organisations found."
        variant="nohover"
        loading={isLoading}
        rowsPerPage={10}
      />
    </>
  );
};

export default TopParentOrganisationTable;
