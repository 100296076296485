import api from 'lib/trpcClient';
import { useLayoutContext } from 'contexts/LayoutContext';
import { getAccountLabel, AccountSectionLabels } from 'further-ui/labels';

const useAccountSelectorOptions = () => {
  const { selectedVehicleId } = useLayoutContext();

  const accounts = api.account.listAllAccounts.useQuery({
    vehicleId: selectedVehicleId ?? '',
  });

  if (accounts.isLoading) {
    return { accountOptions: [], isLoading: true };
  }

  const accountOptions = Object.keys(accounts.data ?? {}).flatMap((key) => [
    { label: AccountSectionLabels[key], value: null },
    ...(accounts.data?.[key] ?? []).map((account) => ({
      label: getAccountLabel(account),
      value: account.id,
    })),
  ]);

  return { accountOptions, isLoading: false };
};

export default useAccountSelectorOptions;
