import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { round } from 'lodash';
import Grid from '@mui/material/Grid2';

import RecordView from 'components/RecordView';
import GridContainer from 'components/GridContainer';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import InputDetailsForm from './InputDetailsForm';
import AllocationsTable from '../AllocationsTable';

const StepAddAndAllocate = ({ vehicles, contacts, onCompletionStep1B }) => {
  const { setValue, watch, handleSubmit } = useFormContext();
  const [showAllocationSubStep, setShowAllocationSubStep] = useState(false);
  const formValues = watch();

  useEffect(() => {
    if (formValues.amountExVat !== null && formValues.vatRate !== null) {
      const amountIncVat = round(
        formValues.amountExVat * (1 + formValues.vatRate / 100),
        2,
      );
      if (formValues.amountIncVat !== amountIncVat) {
        setValue('amountIncVat', amountIncVat);
      }
    }
  }, [formValues.amountExVat, formValues.vatRate]);

  const onCompletionStep1A = () => {
    setShowAllocationSubStep(true);
  };

  const onAllocationsChange = () => {};

  return (
    <RecordView size="full">
      <GridContainer>
        <Grid size={{ sm: 12, md: 12, lg: 8 }}>
          <PageSection title="A. Add income or expense">
            <Spacing size="lg">
              <p>
                Use this section to add a one-off (non-recurring) income,
                expense or refund.
              </p>
              <form>
                <Spacing>
                  <InputDetailsForm
                    vehicles={vehicles}
                    incomeOrExpense={formValues.incomeOrExpense}
                    contacts={contacts}
                  />
                  <ButtonGroup>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleSubmit(onCompletionStep1A)}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </Spacing>
              </form>
            </Spacing>
          </PageSection>
        </Grid>
      </GridContainer>

      {showAllocationSubStep && (
        <>
          <PageSection title="B. Create allocation">
            <AllocationsTable
              onAllocationsChange={onAllocationsChange}
              vehicleId={formValues.vehicleId}
              inputAmount={formValues.amountIncVat}
              inputLabel="income/expense"
              currency={formValues.currencyCode}
            />
          </PageSection>
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={onCompletionStep1B}
            >
              Proceed to summary
            </Button>
          </ButtonGroup>
        </>
      )}
    </RecordView>
  );
};

export default StepAddAndAllocate;
