import RecordView from 'components/RecordView';
import GridContainer from 'components/GridContainer';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import Grid from '@mui/material/Grid2';
import { FieldRow } from 'components/FormElements';
import { IncomeAndExpensesLabels } from 'further-ui/labels';
import { numberToCurrencyString } from 'further-ui/utils';
import TransactionAllocator, {
  Transaction,
} from 'components/TransactionAllocator';
import PageLoader from 'components/PageLoader';
import useTransactionTypeSelectorOptions from 'hooks/form/useTransactionTypeSelectorOptions';
import api from 'lib/trpcClient';
import { ActionRef } from 'further-types/lp/action';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';

type Props = {
  id?: string;
  onPreviousStep: () => void;
  onSuccess: () => void;
  vehicles: Array<{
    _id: string;
    vehicleName: string;
  }>;
};

const StepReviewAndConfirm: React.FC<Props> = ({
  id,
  onPreviousStep,
  onSuccess,
  vehicles,
}) => {
  const { getValues, setValue, handleSubmit, getFieldState } = useFormContext();
  const values = getValues();
  const vehicle = vehicles.find((v) => v._id === values.vehicleId);

  const defaultTransactionTypes =
    api.action.getTransactionTypesForAction.useQuery({
      vehicleId: values.vehicleId,
      actionRef: ActionRef.IncomeOrExpense,
      actionType: values.incomeOrExpenseType,
      amountIncVat: values.amountIncVat,
      amountExVat: values.amountExVat,
    });

  const mutationOptions = useHandleTrpcMutation()({
    onSuccess,
    invalidationHandler: (utils) => {
      utils.generalLedger.invalidate();
      utils.incomeAndExpenses.invalidate();
    },
  });
  const {
    mutate: createIncomeOrExpense,
    isPending: createIncomeOrExpensePending,
  } = api.incomeAndExpenses.create.useMutation(mutationOptions);

  const {
    mutate: updateIncomeOrExpense,
    isPending: updateIncomeOrExpensePending,
  } = api.incomeAndExpenses.update.useMutation(mutationOptions);

  const { transactionTypeOptions, isLoading: transactionTypesLoading } =
    useTransactionTypeSelectorOptions();

  if (transactionTypesLoading || defaultTransactionTypes.isLoading) {
    return <PageLoader />;
  }

  if (!defaultTransactionTypes.data) {
    return (
      <Typography>
        To set up account mappings, please first create some transaction types.
      </Typography>
    );
  }

  const handleChangeTransactions = (transactions: Array<Transaction>) => {
    setValue('transactions', transactions);
  };

  const onSubmit = (data: any) => {
    if (id) {
      updateIncomeOrExpense({ ...data, id, allocations: [] });
    } else {
      createIncomeOrExpense({ ...data, allocations: [] });
    }
  };

  const isPending =
    createIncomeOrExpensePending || updateIncomeOrExpensePending;

  const defaultTransactions = id
    ? defaultTransactionTypes.data.map((transaction) => {
        const existingTransaction = values.transactions.find(
          (t: Transaction) => t.operationType === transaction.operationType,
        );

        return {
          ...transaction,
          id: existingTransaction?.id,
          transactionTypeId: getFieldState('incomeOrExpenseType').isDirty
            ? transaction.transactionTypeId
            : existingTransaction?.transactionTypeId ??
              transaction.transactionTypeId,
        };
      })
    : defaultTransactionTypes.data;

  return (
    <RecordView>
      <Spacing size="lg">
        <GridContainer>
          <Grid size={{ sm: 12, md: 12, lg: 8 }}>
            <PageSection title="Summary of income/expense being processed">
              <Spacing size="sm">
                <FieldRow title="Vehicle">{vehicle?.vehicleName}</FieldRow>
                <FieldRow title="Counterparty">
                  {values.counterparty?.name}
                </FieldRow>
                <FieldRow title="Type">
                  {IncomeAndExpensesLabels[values.incomeOrExpenseType]}
                </FieldRow>
                <FieldRow title="Amount">
                  {numberToCurrencyString(values.amountIncVat)}
                </FieldRow>
              </Spacing>
            </PageSection>
          </Grid>
        </GridContainer>

        <PageSection title="A: Review allocation" />

        <PageSection title="B: Review account transactions">
          <TransactionAllocator
            transactionTypeOptions={transactionTypeOptions}
            defaultTransactions={defaultTransactions}
            onChange={handleChangeTransactions}
          />
        </PageSection>
        <ButtonGroup>
          <Button
            variant="outlined"
            onClick={onPreviousStep}
            loading={isPending}
          >
            Previous step
          </Button>
          <ButtonGroup>
            <Button variant="outlined" color="primary" loading={isPending}>
              Save draft
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={isPending}
            >
              Save and approve
            </Button>
          </ButtonGroup>
        </ButtonGroup>
      </Spacing>
    </RecordView>
  );
};

export default StepReviewAndConfirm;
