import { useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useReportingFundsProgress } from 'hooks/data/investorReporting/useReportingFundsProgress';
import useStyles from './styles';
import { BarChart } from '@mui/icons-material';
import { format } from 'date-fns';
import { round } from 'lodash';

const AccordianProgressIndicator = ({
  isFetching,
  isFetched,
  enableData,
  roundedValue,
}) => {
  const { classes, cx } = useStyles();

  if (!isFetched && !isFetching) {
    return (
      <span className={classes.progressIndication}>
        <Tooltip title="View tranche progress">
          <IconButton onClick={enableData} size="small">
            <BarChart fontSize="small" />
          </IconButton>
        </Tooltip>
      </span>
    );
  }

  if (isFetching) {
    return (
      <span className={classes.progressIndication}>
        <CircularProgress size={14} />
      </span>
    );
  }

  const displayValue = roundedValue === '-0.00' ? '0.00' : roundedValue;
  const positiveProgress = Math.sign(Number(displayValue)) >= 0;

  return (
    <span
      className={cx(
        classes.progressIndication,
        positiveProgress ? classes.colorGreen : classes.colorRed,
      )}
    >
      {`${positiveProgress ? '+' + displayValue : displayValue}%`}
    </span>
  );
};

const AccordionTitle = ({ item, firmName, submitDate }) => {
  const { classes } = useStyles();
  const [isEnabled, setIsEnabled] = useState(false);

  const { data, isFetching, isFetched } = useReportingFundsProgress(
    [item.fundId || item._id],
    submitDate ? format(submitDate, 'dd/MM/yyyy') : null,
    isEnabled,
  );

  const roundedValue = round(
    data?.fundsProgress?.find(({ _id }) => (item.fundId || item._id) === _id)
      ?.progress || 0,
    2,
  );

  return (
    <div className={classes.accordionHeader}>
      <span className={classes.accordionHeaderFundName}>
        Tranche: {firmName}
        {' - '}
        {item?.fundName}
      </span>
      <AccordianProgressIndicator
        isFetching={isFetching}
        isFetched={isFetched}
        roundedValue={roundedValue}
        enableData={() => setIsEnabled(true)}
      />
    </div>
  );
};

export default AccordionTitle;
