import { FC } from 'react';
import AlertDialog from 'components/AlertDialog';
import { Disclosure } from 'further-ui/hooks/useDisclosure';

type Props = {
  disclosure: Disclosure;
  onConfirm: () => void;
};

const UpdatedDefaultAdviserWarningModal: FC<Props> = ({
  disclosure,
  onConfirm,
}) => (
  <AlertDialog
    onClose={disclosure.onClose}
    onConfirm={() => {
      onConfirm();
      disclosure.onClose();
    }}
    open={disclosure.isOpen}
    title="Editing default adviser"
    content={
      <p>
        This adviser is currently the investor’s default adviser. If they are
        removed from this subscription, they will cease to be the investor’s
        default adviser, but will still be connected to other existing
        subscriptions made by this investor. To edit the default adviser
        directly, please visit the Investor page.
      </p>
    }
    confirmBtnProps={{ variant: 'contained', color: 'primary' }}
    cancelBtnProps={{ variant: 'outlined', color: 'primary' }}
    btnLabels={{
      cancel: 'Go back',
      confirm: 'Proceed',
    }}
  />
);

export default UpdatedDefaultAdviserWarningModal;
