import { IconButton } from '@mui/material';
import { GridExpandMoreIcon, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Row } from '.';

const DetailPanelToggle: React.FC<GridRenderCellParams<Row>> = ({
  value,
  row,
}) => {
  if (!row.isExpandable) {
    return null;
  }

  const isExpanded = !!value;

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <GridExpandMoreIcon
        sx={(theme) => ({
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
        })}
        fontSize="inherit"
      />
    </IconButton>
  );
};

export default DetailPanelToggle;
