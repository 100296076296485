import React, { useRef } from 'react';
import {
  Area,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useStyles as usePieChartStyles } from 'components/PieChart';
import {
  dateToLabel,
  numberToCurrencyString,
  numberToDisplayString,
} from 'further-ui/utils';
import { pluralize } from 'utils/pluralize';

type Props = {
  data: Array<any>;
  isFetchingUnrealisedValue: boolean;
  renderCurrentAum?: boolean;
};

const InvestmentInflowChart: React.FC<Props> = ({
  data,
  isFetchingUnrealisedValue,
  renderCurrentAum = true,
}) => {
  const { classes } = usePieChartStyles();
  const colors = {
    investmentAmount: '#56B26C',
    investors: '#84DAB9',
    currentAum: '#ECC9A2',
  };

  const renderCustomAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text y={0} x={-40} dy={16} textAnchor="start" fill="#999">
          {dateToLabel(payload.value)}
        </text>
      </g>
    );
  };

  const height = useRef(window.innerWidth < 960 ? 450 : '100%').current;

  const maxDomainValue = Math.max(
    ...data.map((item) =>
      Math.max(item.unrealisedValue || 0, item.investmentAmountCumulative || 0),
    ),
  );

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart
        height={270}
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="date" tick={renderCustomAxisTick} minTickGap={40} />

        <YAxis
          hide
          yAxisId="investors"
          domain={[0, (dataMax) => dataMax * 1.5]}
        />
        {!isFetchingUnrealisedValue && renderCurrentAum && (
          <YAxis hide yAxisId="currentAum" domain={[0, maxDomainValue]} />
        )}
        <YAxis
          hide
          yAxisId="investmentAmount"
          orientation="right"
          domain={[0, maxDomainValue]}
        />
        <Tooltip
          content={({ payload }) => {
            const item = payload?.[0]?.payload;
            if (!item) return null;
            return (
              <div className={classes.tooltipWrapper}>
                {!isFetchingUnrealisedValue && renderCurrentAum && (
                  <>
                    <p>
                      <strong>Current value AUM</strong>
                    </p>
                    <p>
                      <strong style={{ color: colors.currentAum }}>
                        {numberToCurrencyString(item.unrealisedValue, {
                          hidePenniesIfInteger: true,
                        })}
                      </strong>
                    </p>
                  </>
                )}
                <p
                  style={{
                    marginTop:
                      !isFetchingUnrealisedValue && renderCurrentAum
                        ? '0.5rem'
                        : 0,
                  }}
                >
                  <strong>Total capital raised</strong>
                </p>
                <p>
                  <strong style={{ color: colors.investmentAmount }}>
                    {numberToCurrencyString(item.investmentAmountCumulative, {
                      hidePenniesIfInteger: true,
                    })}
                  </strong>{' '}
                  from{' '}
                  <strong style={{ color: colors.investors }}>
                    {numberToDisplayString(item.investorsCumulative)}{' '}
                    {pluralize('investor', item.investorsCumulative)}
                  </strong>
                </p>
                <p style={{ marginTop: '0.5rem' }}>
                  <strong>Capital raised on {dateToLabel(item.date)}</strong>
                </p>
                <p>
                  <span>
                    {numberToCurrencyString(item.investmentAmount, {
                      hidePenniesIfInteger: true,
                    })}
                  </span>{' '}
                  from {numberToDisplayString(item.investors)}{' '}
                  {pluralize('investor', item.investors)}
                </p>
              </div>
            );
          }}
        />
        <defs>
          <linearGradient
            id="investmentAmountGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="15%"
              stopColor={colors.investmentAmount}
              stopOpacity={0.6}
            />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.5} />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="investorGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="15%" stopColor={colors.investors} stopOpacity={0.6} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.5} />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="currentAumGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="15%"
              stopColor={colors.currentAum}
              stopOpacity={0.6}
            />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.5} />
          </linearGradient>
        </defs>
        <Area
          yAxisId="investmentAmount"
          name="Subscription Amount (cumulative)"
          type="monotone"
          dataKey="investmentAmountCumulative"
          stroke={colors.investmentAmount}
          strokeWidth={3}
          fill="url(#investmentAmountGradient)"
        />

        {!isFetchingUnrealisedValue && renderCurrentAum && (
          <Area
            yAxisId="currentAum"
            name="Current AUM"
            type="monotone"
            dataKey="unrealisedValue"
            stroke={colors.currentAum}
            strokeWidth={3}
            fill="url(#currentAumGradient)"
          />
        )}
        <Area
          yAxisId="investors"
          name="Investors (cumulative)"
          type="monotone"
          dataKey="investorsCumulative"
          stroke={colors.investors}
          strokeWidth={3}
          fill="url(#investorGradient)"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default InvestmentInflowChart;
