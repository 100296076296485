import { styled } from '@mui/material';
import { Column } from 'components/InteractiveTable';
import { ActionRefLabels } from 'further-ui/labels';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import { ApiResponses } from 'lib/trpcClient';

type Transaction =
  ApiResponses['generalLedger']['listTransactions']['sections'][string]['transactions'][number];

type TransactionColumn = Column & {
  field: keyof Transaction;
};

const Link = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const getColumns = ({
  totalIncVat,
  totalExVat,
  accountLabel,
}: {
  totalIncVat: number;
  totalExVat: number;
  accountLabel: string;
}): Array<TransactionColumn> => [
  {
    field: 'accountLabel',
    headerName: 'Account',
    flex: 1,
    summary: `Subtotal - ${accountLabel}`,
  },
  {
    field: 'transactionCurrency',
    headerName: 'Currency (TCY)',
    width: 150,
  },
  {
    field: 'sourceActionId',
    headerName: 'Action',
    width: 150,
    renderCell: (cell) => {
      return (
        //TODO: replace link with URL of specific action in the action history when ready
        <Link target="_blank" href={`#${cell.value}`}>
          {ActionRefLabels[cell.row.sourceActionRef]}
        </Link>
      );
    },
  },
  {
    field: 'transactionDate',
    headerName: 'Date',
    renderCell: (cell) => {
      return dateToLabel(cell.value);
    },
  },
  {
    field: 'transactionType',
    headerName: 'Transaction type',
    width: 150,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
  },
  {
    field: 'amountIncVat',
    headerName: 'Amount (LCY)',
    width: 150,
    summary: numberToCurrencyString(totalIncVat),
  },
  {
    field: 'amountExVat',
    headerName: 'Amount (TCY)',
    width: 150,
    summary: numberToCurrencyString(totalExVat),
  },
  {
    field: 'counterpartyLabel',
    headerName: 'Counterparty',
  },
  {
    field: 'bankAccountLabel',
    headerName: 'Bank account',
  },
  {
    field: 'ledgerComment',
    headerName: 'Action comment',
    width: 150,
  },
];

export default getColumns;
