import SearchField from 'components/SearchField';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import PageFilters, { Filter } from 'components/PageFilters';

type FiltersType = {
  search?: string;
  startDate?: Date;
  endDate?: Date;
};

type Props = {
  filters: FiltersType;
  onFiltersChange: (filters: FiltersType) => void;
};

const BulkPaymentAssignmentFilters: React.FC<Props> = ({
  filters,
  onFiltersChange,
}) => {
  return (
    <PageFilters pageId="bulk-payment-assignment">
      <Filter>
        <SearchField
          variant="outlined"
          placeholder="Search by email, subscription ID or investor name"
          allowEmptyQuery
          autoSearchOnDebounce
          onSearch={(search: string) => onFiltersChange({ search })}
          fullWidth
        />
      </Filter>
      <Filter>
        <MuiDateRangePicker
          dateRange={{ startDate: filters.startDate, endDate: filters.endDate }}
          onDateRangeChange={({ startDate, endDate }) =>
            onFiltersChange({ startDate, endDate })
          }
          placeholder="Filter by subscription date"
        />
      </Filter>
    </PageFilters>
  );
};

export default BulkPaymentAssignmentFilters;
