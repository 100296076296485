import { GridColumnsInitialState } from '@mui/x-data-grid-pro';
import { useState } from 'react';

export const COLUMNS_CONFIG_KEY_SUFFIX = '-filters';

export default function useColumnsConfig(tableId: string) {
  const storageKey = `interactive-table-${tableId}-${COLUMNS_CONFIG_KEY_SUFFIX}`;
  const [columnsConfig, setColumnsConfig] = useState<GridColumnsInitialState>(
    () => {
      const savedConfig = localStorage.getItem(storageKey);

      try {
        return savedConfig ? JSON.parse(savedConfig) : {};
      } catch (_) {
        return {};
      }
    },
  );

  const handleConfigChange = (config: GridColumnsInitialState) => {
    setColumnsConfig(config);
    localStorage.setItem(storageKey, JSON.stringify(config));
  };

  return {
    columnsConfig,
    handleConfigChange,
  };
}
