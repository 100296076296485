import React, { ComponentType, MouseEventHandler, ReactNode } from 'react';
import {
  Box,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import IconButton from '@mui/material/IconButton';
import Button from 'components/Button';
import Spacing from 'components/Spacing';
import Heading from 'components/Heading';

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.grey[500],
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

const Content = styled(Box)(({ theme }) => ({
  fontSize: theme.fontSizes?.lg,
  color: theme.palette.text.secondary,
  letterSpacing: 0,
  textAlign: 'center',
}));

const DialogIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontSize: 65,
  margin: '0 auto',
  display: 'block',
}));

const Actions = styled(DialogActions, {
  shouldForwardProp: (prop) => prop !== 'alignActionsVertically',
})<{ alignActionsVertically?: boolean }>(
  ({ theme, alignActionsVertically }) => ({
    justifyContent: 'center',
    padding: theme.spacing(0, 6, 6),
    gap: theme.spacing(2),
    flexDirection: alignActionsVertically ? 'column' : 'row',
    marginTop: theme.spacing(2),
  }),
);

type Props = Pick<DialogProps, 'open'> & {
  size?: 'sm' | 'md';
  onConfirm?: MouseEventHandler;
  onAlternateConfirm?: MouseEventHandler;
  onClose?: MouseEventHandler;
  title?: string;
  content?: ReactNode;
  btnLabels?: {
    cancel: string | boolean;
    confirm: string | boolean;
    alternateConfirm?: string | boolean;
  };
  icon?: ComponentType;
  cancelBtnProps?: ButtonProps & { whiteSpace?: 'nowrap' | 'normal' };
  confirmBtnProps?: ButtonProps & {
    isFetching?: boolean;
    whiteSpace?: 'nowrap' | 'normal';
  };
  alternateConfirmBtnProps?: ButtonProps & {
    whiteSpace?: 'nowrap' | 'normal';
  };
  confirmBtnComponent?: ReactNode;
  alignActionsVertically?: boolean;
};

const AlertDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  onAlternateConfirm,
  content,
  icon,
  cancelBtnProps,
  confirmBtnProps,
  alternateConfirmBtnProps,
  confirmBtnComponent,
  open = false,
  title = 'Confirm Delete',
  btnLabels = {
    cancel: 'Cancel',
    confirm: 'Confirm',
    alternateConfirm: false,
  },
  size = 'md',
  alignActionsVertically = false,
}) => {
  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      maxWidth={size}
      fullWidth
    >
      <CloseButton aria-label="close" onClick={onClose} size="large">
        <CloseIcon />
      </CloseButton>

      <DialogContent>
        <Spacing>
          <DialogIcon as={icon ? icon : WarningIcon} />
          <DialogContentText>
            <Heading variant="h1" centered noMargin>
              {title}
            </Heading>
          </DialogContentText>
          <Content>{content}</Content>
        </Spacing>
      </DialogContent>
      <Actions alignActionsVertically={alignActionsVertically}>
        <Button onClick={onClose} variant="outlined" {...cancelBtnProps}>
          {btnLabels.cancel}
        </Button>
        {btnLabels.alternateConfirm && (
          <Button
            onClick={onAlternateConfirm}
            variant="outlined"
            {...alternateConfirmBtnProps}
          >
            {btnLabels.alternateConfirm}
          </Button>
        )}
        {btnLabels.confirm && !confirmBtnComponent ? (
          <>
            <Button
              onClick={onConfirm}
              color="primary"
              variant="contained"
              loading={confirmBtnProps?.isFetching}
              {...confirmBtnProps}
            >
              {btnLabels.confirm}
            </Button>
          </>
        ) : null}
        {confirmBtnComponent ? confirmBtnComponent : null}
      </Actions>
    </Dialog>
  );
};

export default AlertDialog;
