import React, { forwardRef } from 'react';
import {
  TextField,
  InputAdornment,
  TextFieldProps,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  input: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& .clearButton': {
      color: '#666666',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#999999',
      },
    },
    '& input::-webkit-search-cancel-button': {
      WebkitAppearance: 'none',
      display: 'none',
    },
    '& input[type="search"]::-ms-clear': {
      display: 'none',
      width: 0,
      height: 0,
    },
    '& input[type="search"]::-ms-reveal': {
      display: 'none',
      width: 0,
      height: 0,
    },
  },
  narrow: {
    '& .MuiInputBase-root': {
      minHeight: '29px',
    },
  },
});

type Props = TextFieldProps & {
  gbpStartAdornment?: boolean;
  percentEndAdornment?: boolean;
  narrow?: boolean;
};

const AppTextInput: React.FC<Props> = forwardRef(
  (
    {
      disabled,
      type = 'text',
      name,
      id,
      fullWidth = true,
      size = 'small',
      value,
      onChange,
      helperText = '',
      variant = 'outlined',
      error = false,
      onKeyPress,
      gbpStartAdornment,
      percentEndAdornment,
      className,
      narrow,
      ...rest
    },
    ref,
  ) => {
    const { classes } = useStyles();
    const onKeyPressNumber = (event) => {
      if (type === 'number') {
        let needPrevent = false;
        if (event.key === '-') {
          needPrevent = true;
        } else if (event.key === '.') {
          if (value?.toString().includes('.')) {
            needPrevent = true;
          }
        } else if (isNaN(parseInt(event.key))) {
          needPrevent = true;
        }
        if (needPrevent) {
          event.preventDefault();
        }
      }
    };

    const inputProps = {
      ...rest.InputProps,
      ...(gbpStartAdornment
        ? {
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }
        : {}),
      ...(percentEndAdornment
        ? {
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }
        : {}),
      ...(type === 'number' && !rest.InputProps
        ? {
            inputProps: {
              min: 0,
            },
          }
        : {}),
      endAdornment:
        value && type === 'search' ? (
          <InputAdornment position="end">
            <IconButton
              className="clearButton"
              onClick={() =>
                onChange?.({
                  target: { value: '' },
                } as React.ChangeEvent<HTMLInputElement>)
              }
              edge="end"
              size="small"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : (
          rest.InputProps?.endAdornment || null
        ),
    };

    return (
      <TextField
        {...rest}
        className={`${classes.input} ${className} ${
          narrow ? classes.narrow : ''
        }`}
        type={type}
        name={name}
        id={id || name}
        size={size}
        fullWidth={fullWidth}
        value={value}
        variant={variant}
        onChange={onChange}
        error={error || helperText !== ''}
        helperText={helperText}
        disabled={disabled}
        onKeyPress={onKeyPress ?? onKeyPressNumber}
        ref={ref}
        slotProps={{
          input: inputProps,
        }}
      />
    );
  },
);

export default AppTextInput;
