import { useLayoutContext } from 'contexts/LayoutContext';
import api from 'lib/trpcClient';
import Heading from 'components/Heading';
import Spacing from 'components/Spacing';
import PageLoader from 'components/PageLoader';
import { Typography } from '@mui/material';
import useTransactionTypeSelectorOptions from 'hooks/form/useTransactionTypeSelectorOptions';
import AccountMappingGrid from './AccountMappingGrid';

const AccountMapping: React.FC = () => {
  const { selectedVehicleId } = useLayoutContext();
  const { transactionTypeOptions, isLoading: transactionTypesLoading } =
    useTransactionTypeSelectorOptions();

  const vehicle = api.vehicle.getById.useQuery({
    vehicleId: selectedVehicleId ?? '',
  });

  const actionTypes = api.action.listTypes.useQuery();

  const defaultValues = vehicle.data?.defaultAccountMappings ?? [];

  // add any rows for actions that don't already have accounts linked
  actionTypes.data?.forEach((action) => {
    if (
      !defaultValues.find((mapping) => mapping.actionType === action.actionType)
    ) {
      defaultValues.push({
        actionRef: action.action,
        actionType: action.actionType,
        debitTransactionTypeId: '',
        creditTransactionTypeId: '',
      });
    }
  });

  if (transactionTypesLoading || vehicle.isLoading || actionTypes.isLoading) {
    return <PageLoader />;
  }

  if (transactionTypeOptions.length === 0) {
    return (
      <Typography>
        To set up account mappings, please first create some transaction types.
      </Typography>
    );
  }

  return (
    <Spacing>
      <Heading variant="h3" noMargin>
        Account mapping
      </Heading>
      <AccountMappingGrid
        vehicleId={selectedVehicleId ?? ''}
        transactionTypes={transactionTypeOptions}
        actionTypes={actionTypes.data ?? []}
        defaultAccountMappings={defaultValues}
      />
    </Spacing>
  );
};

export default AccountMapping;
