import {
  AccountBalance,
  SupervisedUserCircle,
  LockOutlined,
  LockOpenOutlined,
  HowToReg,
  BusinessCenter,
  TableChart,
  FlashOn,
  Business,
  Description,
  ShowChart,
  GroupOutlined,
  LocalOffer,
  Settings,
  ArrowBack,
  Mail,
  ViewQuilt,
  ThumbUp,
  History,
  Payment,
  CarCrash,
  ReceiptOutlined,
  PriceCheck,
  MonetizationOn,
  SyncAlt,
} from '@mui/icons-material';
import {
  ADVISER,
  ADVISER_ADD,
  BULK_PAYMENT_ASSIGNMENT,
  COMPANY,
  DASHBOARD_ALL_FIRMS,
  DASHBOARD_MY_FIRM,
  EIS,
  EIS_ADD,
  EXITS,
  EXITS_ADD,
  INTEREST_PAYMENTS,
  INVESTMENT,
  INVESTOR_REPORTING,
  INVESTOR_REPORTING_ADD,
  INVESTOR_UPLOAD,
  ORGANISATION,
  ORGANISATION_ADD,
  TRANCHE,
  WITHDRAWALS,
  WITHDRAWALS_ADD,
  ADD_CUSTOM_UPDATE,
  INVESTMENT_TRANSFERS,
  INVESTOR_BULLETIN,
  INVESTMENT_ADD,
  KI_EIS_ADD,
  TAX_REPORT_AUDIT,
  TAG,
  CHANGE_LOG,
  USER,
  PROFILE,
  FIRM,
  INVESTOR,
  INCOMPLETE_REGISTER,
  INCOMPLETE_TRANSACTION,
  FEE_SUMMARY,
  FEE_DISCOUNT_ADD,
  UPLOAD_SHEET,
  ALL_FUNDS,
  ONE_OFF_FEES_AND_DISCOUNTS,
  CASH_TRANSACTIONS_REPORT,
  INVESTMENT_HOLDINGS_REPORT,
  INVESTMENT_TRANSACTIONS_REPORT,
  GENERAL_LEDGER,
  VEHICLES,
  FUND_SETUP,
  INCOME_OR_EXPENSE_ADD,
  INCOME_OR_EXPENSE_LIST,
} from 'adminConstants/routes';

export type MenuItemType = {
  name: string;
  type: string;
  link?: string;
  icon?: React.ReactNode;
  onlyShownForRoles?: string[];
  children?: Array<MenuItemType>;
  permission?: string;
  isLogout?: boolean;
  onClick?: () => void;
};

export const itemsContainUrl = (children: Array<MenuItemType>, url: string) => {
  if (!children?.length) {
    return false;
  }

  for (let i = 0; i < children.length; i++) {
    if (children[i].children) {
      //@ts-expect-error
      if (itemsContainUrl(children[i].children, url)) {
        return true;
      }
    }

    //@ts-expect-error
    if (children[i].link === url || url.includes(children[i].link)) {
      return true;
    }
  }
  return false;
};

export const createLpSidebarNav = (): Array<MenuItemType> => [
  {
    name: 'Fund Administration',
    type: 'section',
    children: [
      {
        name: 'Fund Setup',
        icon: <PriceCheck />,
        link: FUND_SETUP,
        type: 'item',
      },
      {
        name: 'Fund Accounting',
        icon: <MonetizationOn />,
        type: 'collapse',
        children: [
          {
            name: 'General Ledger',
            link: GENERAL_LEDGER,
            type: 'item',
          },
        ],
      },
      {
        name: 'Income and Expenses',
        icon: <SyncAlt />,
        type: 'collapse',
        children: [
          {
            name: 'Add income or expense',
            link: INCOME_OR_EXPENSE_ADD,
            type: 'item',
          },
          {
            name: 'View income and expenses',
            link: INCOME_OR_EXPENSE_LIST,
            type: 'item',
          },
        ],
      },
    ],
  },
  {
    type: 'section',
    name: 'Settings',
    children: [
      {
        name: 'Vehicles (temp)',
        icon: <CarCrash />,
        link: VEHICLES,
        type: 'item',
      },
      {
        name: 'Profile',
        icon: <Settings />,
        link: PROFILE,
        permission: 'read:me',
        type: 'item',
      },
      {
        name: 'Logout',
        icon: <ArrowBack />,
        type: 'item',
        isLogout: true,
      },
    ],
  },
];

export const createEisSidebarNav = (
  openTrancheDashboardLinks?: Array<MenuItemType>,
  firmDashboardLinks?: Array<MenuItemType>,
): Array<MenuItemType> => [
  {
    name: 'Summary',
    type: 'section',
    children: [
      {
        name: 'Admin Dashboard',
        icon: <ViewQuilt />,
        link: DASHBOARD_ALL_FIRMS,
        type: 'item',
        onlyShownForRoles: ['super-admin', 'further-staff'],
      },
      firmDashboardLinks?.length
        ? {
            name: 'Firm Dashboard',
            type: 'collapse',
            icon: <ViewQuilt />,
            children: firmDashboardLinks,
          }
        : {
            name: 'Firm Dashboard',
            type: 'item',
            icon: <ViewQuilt />,
            link: DASHBOARD_MY_FIRM,
          },
      ...(openTrancheDashboardLinks?.length
        ? [
            {
              name: 'Open Tranches',
              type: 'collapse',
              icon: <LockOpenOutlined />,
              children: openTrancheDashboardLinks,
            },
          ]
        : []),
      {
        name: 'All Tranches',
        type: 'item',
        icon: <LockOutlined />,
        link: ALL_FUNDS,
      },
    ],
  },
  {
    name: 'Fund Administration',
    type: 'section',
    children: [
      {
        name: 'My Firm',
        type: 'item',
        icon: <Business />,
        link: FIRM,
        permission: 'read:firm',
        onlyShownForRoles: ['firm-manager'],
      },
      {
        name: 'Firms',
        type: 'item',
        icon: <Business />,
        link: FIRM,
        permission: 'read:firm',
        onlyShownForRoles: ['super-admin', 'further-staff'],
      },
      {
        name: 'Tranches',
        type: 'item',
        icon: <Description />,
        link: TRANCHE,
        permission: 'read:firm',
      },

      {
        name: 'Investors',
        type: 'collapse',
        icon: <HowToReg />,
        permission: 'read:investor',
        children: [
          {
            name: 'View Investors',
            type: 'item',
            link: INVESTOR,
          },
          {
            name: 'Upload Investors',
            type: 'item',
            link: INVESTOR_UPLOAD,
          },
          {
            name: 'Incomplete Registrations',
            type: 'item',
            link: INCOMPLETE_REGISTER,
            onlyShownForRoles: ['super-admin', 'further-staff'],
          },
        ],
      },

      {
        name: 'Subscriptions',
        type: 'collapse',
        icon: <ShowChart />,
        permission: 'read:investment',
        children: [
          {
            name: 'View Subscriptions',
            type: 'item',
            link: INVESTMENT,
          },
          {
            name: 'Upload Subscriptions',
            type: 'item',
            link: INVESTOR_UPLOAD,
          },
          {
            name: 'Create Subscription',
            type: 'item',
            link: INVESTMENT_ADD,
          },
          {
            name: 'Interest Payments',
            type: 'item',
            link: INTEREST_PAYMENTS,
          },
          {
            name: 'Incomplete Subscriptions',
            type: 'item',
            link: INCOMPLETE_TRANSACTION,
          },
          {
            name: 'Transfer Record',
            type: 'item',
            link: INVESTMENT_TRANSFERS,
          },
        ],
      },

      {
        name: 'Payments',
        type: 'collapse',
        icon: <Payment />,
        permission: 'read:investment',
        children: [
          {
            name: 'Bulk Payment Assignment',
            type: 'item',
            link: BULK_PAYMENT_ASSIGNMENT,
          },
        ],
      },

      {
        name: 'Fees',
        type: 'collapse',
        icon: <MonetizationOn />,
        permission: 'read:investment',
        children: [
          {
            name: 'Fees Summary',
            type: 'item',
            link: FEE_SUMMARY,
          },
          {
            name: 'One-Off Fees / Discounts',
            type: 'item',
            link: ONE_OFF_FEES_AND_DISCOUNTS,
          },
          {
            name: 'Add One-Off Fee / Discount',
            type: 'item',
            link: FEE_DISCOUNT_ADD,
          },
        ],
      },

      {
        name: 'Withdrawals',
        type: 'collapse',
        icon: <AccountBalance />,
        permission: 'read:investment',
        children: [
          {
            name: 'Request Withdrawals',
            type: 'item',
            link: WITHDRAWALS_ADD,
          },
          {
            name: 'Review Withdrawals',
            type: 'item',
            link: WITHDRAWALS,
          },
        ],
      },

      {
        name: 'Advisers',
        type: 'collapse',
        icon: <GroupOutlined />,
        permission: 'read:advisers',
        children: [
          {
            name: 'View Advisers',
            type: 'item',
            link: ADVISER,
          },
          {
            name: 'Create Adviser',
            type: 'item',
            link: ADVISER_ADD,
          },
          {
            name: 'View Organisations',
            type: 'item',
            link: ORGANISATION,
          },
          {
            name: 'Create Adviser Organisation',
            type: 'item',
            link: ORGANISATION_ADD,
          },
        ],
      },
      {
        name: 'System Exports',
        type: 'collapse',
        icon: <ReceiptOutlined />,
        permission: '',
        children: [
          {
            name: 'Cash Transactions',
            type: 'item',
            link: CASH_TRANSACTIONS_REPORT,
          },
          {
            name: 'Investment Holdings',
            type: 'item',
            link: INVESTMENT_HOLDINGS_REPORT,
          },
          {
            name: 'Investment Transactions',
            type: 'item',
            link: INVESTMENT_TRANSACTIONS_REPORT,
          },
        ],
      },
    ],
  },
  {
    name: 'Portfolio',
    type: 'section',
    children: [
      {
        name: 'Companies',
        type: 'item',
        icon: <BusinessCenter />,
        link: COMPANY,
        permission: 'read:companies',
      },
      {
        name: 'Allocations',
        type: 'collapse',
        icon: <TableChart />,
        children: [
          {
            name: 'Upload Shareholding',
            type: 'item',
            link: UPLOAD_SHEET,
          },
        ],
      },
      {
        name: 'S/EIS Certificates',
        type: 'collapse',
        icon: <FlashOn />,
        permission: 'read:seis',
        children: [
          {
            name: 'View S/EIS Tasklist',
            type: 'item',
            link: EIS,
          },
          {
            name: 'Create S/EIS Certificates',
            type: 'item',
            link: EIS_ADD,
          },
          {
            name: 'Create KI Fund Certificates',
            type: 'item',
            link: KI_EIS_ADD,
          },
        ],
      },
      {
        name: 'Exits',
        type: 'collapse',
        icon: <ThumbUp />,
        children: [
          {
            name: 'View Exits',
            type: 'item',
            link: EXITS,
          },
          {
            name: 'Process New Exit',
            type: 'item',
            link: EXITS_ADD,
          },
        ],
      },
      {
        name: 'Investor Communications',
        type: 'collapse',
        icon: <Mail />,
        permission: 'read:investor',
        children: [
          {
            name: 'Investor Reporting',
            type: 'item',
            link: INVESTOR_REPORTING,
          },
          {
            name: 'Create Periodic Update',
            type: 'item',
            link: INVESTOR_REPORTING_ADD,
          },
          {
            name: 'Create Custom Update',
            type: 'item',
            link: ADD_CUSTOM_UPDATE,
          },
          {
            name: 'Investor Bulletin',
            type: 'item',
            link: INVESTOR_BULLETIN,
          },
          {
            name: 'Tax Report Audit',
            type: 'item',
            link: TAX_REPORT_AUDIT,
          },
        ],
      },
    ],
  },
  {
    type: 'section',
    name: 'Settings',
    children: [
      {
        name: 'Fund Types',
        type: 'item',
        icon: <LocalOffer />,
        link: TAG,
        onlyShownForRoles: ['super-admin', 'further-staff'],
      },
      {
        name: 'Change Log',
        type: 'item',
        icon: <History />,
        link: CHANGE_LOG,
        onlyShownForRoles: ['super-admin'],
      },
      {
        name: 'Users',
        type: 'item',
        icon: <SupervisedUserCircle />,
        link: USER,
        permission: 'read:users',
        onlyShownForRoles: ['super-admin', 'further-staff'],
      },
      {
        name: 'Profile',
        type: 'item',
        icon: <Settings />,
        link: PROFILE,
      },
      {
        name: 'Logout',
        type: 'item',
        isLogout: true,
        icon: <ArrowBack />,
      },
    ],
  },
];
