import {
  ButtonGroup,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grow,
  Paper,
  Popper,
  styled,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import Button from 'components/Button';
import { Column } from 'components/Layout';
import { useRef, useState } from 'react';

const CheckboxContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  minWidth: 200,
}));

const ArrowButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
}));

type Props<T> = {
  label: string;
  options: Array<{
    value: T;
    label: string;
  }>;
  value: Array<T>;
  onChange: (value: Array<T>) => void;
};

const DropdownCheckboxList = <T,>({
  label,
  options,
  value,
  onChange,
}: Props<T>): JSX.Element => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleChange = (option: T) => {
    const newValue = value.includes(option)
      ? value.filter((v) => v !== option)
      : [...value, option];
    onChange(newValue);
  };

  return (
    <>
      <ButtonGroup variant="outlined" ref={anchorRef}>
        <Button onClick={handleToggle}>{label}</Button>
        <ArrowButton
          size="small"
          aria-controls={open ? 'checkbox-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </ArrowButton>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 99 }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <CheckboxContainer>
              <ClickAwayListener onClickAway={handleClose}>
                <Column spacing="xs">
                  {options.map((option) => (
                    <FormControlLabel
                      key={option.label}
                      control={
                        <Checkbox
                          checked={value.includes(option.value)}
                          onChange={() => handleChange(option.value)}
                        />
                      }
                      label={option.label}
                    />
                  ))}
                </Column>
              </ClickAwayListener>
            </CheckboxContainer>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropdownCheckboxList;
