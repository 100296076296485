import { pluralize } from './pluralize';
import { numberToCurrencyString } from 'further-ui/utils';
import { dateToLabel } from 'further-ui/utils';

const getAllocationLabel = (allocation: {
  date: string;
  totalInvestmentAmount: number;
  numberOfTranches: number;
  numberOfInvestments: number;
}) => {
  const date = dateToLabel(allocation.date);

  const amountInvested = numberToCurrencyString(
    allocation.totalInvestmentAmount,
  );

  const noOfTranches = `${allocation.numberOfTranches} ${pluralize(
    'tranche',
    allocation.numberOfTranches,
  )}`;

  const noOfInvestments = `${allocation.numberOfInvestments} ${pluralize(
    'subscription',
    allocation.numberOfInvestments,
  )}`;

  return `${date} - ${amountInvested} - ${noOfTranches} - ${noOfInvestments}`;
};

export default getAllocationLabel;
