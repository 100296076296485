import { useEffect } from 'react';
import api from 'lib/trpcClient';
import { styled, MenuItem, Select } from '@mui/material';
import { useLayoutContext } from 'contexts/LayoutContext';

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.brandColor}15`,
  color: theme.palette.common.brandColor,
  fontWeight: 600,

  '&:hover': {
    '& fieldset': {
      borderColor: `${theme.palette.common.brandColor}40 !important`,
    },
  },

  '& fieldset': {
    borderColor: `${theme.palette.common.brandColor}15`,
  },
}));

const VehicleSwitcher: React.FC = () => {
  const { selectedVehicleId, setSelectedVehicle } = useLayoutContext();

  const vehicles = api.vehicle.listAllVehicles.useQuery({
    order: 'desc',
    orderBy: 'vehicleName',
  });

  useEffect(() => {
    const noVehicleSelected = !selectedVehicleId;
    // in case the vehicle is deleted or the user has been removed from the vehicle
    const invalidVehicleSelected =
      vehicles.data &&
      !vehicles.data.result.some(
        (vehicle) => vehicle._id === selectedVehicleId,
      );

    if (
      vehicles.data?.result?.length &&
      (noVehicleSelected || invalidVehicleSelected)
    ) {
      setSelectedVehicle(vehicles.data.result[0]);
    }
  }, [vehicles.data]);

  return (
    <StyledSelect
      fullWidth
      value={selectedVehicleId}
      onChange={(e) => {
        setSelectedVehicle(
          vehicles.data?.result.find(
            (vehicle) => vehicle._id === e.target.value,
          ) ?? null,
        );
      }}
    >
      {vehicles.data?.result.map((vehicle) => (
        <MenuItem key={vehicle._id} value={vehicle._id}>
          {vehicle.vehicleName}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default VehicleSwitcher;
