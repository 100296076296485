import { CurrencyCode } from 'further-types/lp/vehicle';
import React, { useState, useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type Vehicle = {
  _id: string;
  vehicleName: string;
  baseCurrency: CurrencyCode;
};

type LayoutContextType = {
  isSidebarOpen: boolean;
  setSidebarOpen: (isSidebarOpen: boolean) => void;
  selectedVehicle: Vehicle | null;
  selectedVehicleId: string | null;
  setSelectedVehicle: (vehicle: Vehicle | null) => void;
  isVehicleSwitching: boolean;
};

const LayoutContext = React.createContext<LayoutContextType>({
  isSidebarOpen: false,
  setSidebarOpen: () => {},
  selectedVehicle: null,
  selectedVehicleId: null,
  setSelectedVehicle: () => {},
  isVehicleSwitching: false,
});

const LayoutContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useLocalStorage<
    string | null
  >('selectedVehicleId', null);
  const [selectedVehicle, _setSelectedVehicle] =
    useLocalStorage<Vehicle | null>('selectedVehicle', null);
  const [isVehicleSwitching, setIsVehicleSwitching] = useState(false);

  const setSelectedVehicle = async (vehicle: Vehicle | null) => {
    setIsVehicleSwitching(true);
    // await a 500s timeout
    await new Promise((resolve) => setTimeout(resolve, 500));
    setSelectedVehicleId(vehicle?._id || null);
    _setSelectedVehicle(vehicle);
    setIsVehicleSwitching(false);
  };

  const layoutContextValue = {
    isSidebarOpen,
    setSidebarOpen,
    selectedVehicle,
    selectedVehicleId,
    setSelectedVehicle,
    isVehicleSwitching,
  };

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);

export default LayoutContextProvider;
