import { useState, useEffect } from 'react';
import { useDebounce } from 'usehooks-ts';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid2';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import PageContainer from 'components/PageContainer';
import TextField from 'components/FormElements/AppTextInput';
import AlertDialog from 'components/AlertDialog';
import {
  numberToCurrencyString,
  numberToDisplayString,
} from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';

import { ORGANISATION_ADD } from 'adminConstants/routes';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import useFilters from 'hooks/ui/useFilters';
import { usePagination } from 'hooks/ui/usePagination';
import {
  useOrganisations,
  useOrganisationExport,
} from 'hooks/data/organisation/useOrganisations';
import { useGetRole } from 'hooks/ui/useGetRole';
import TableActions from './TableActions';
import { useNotification } from 'hooks/ui/useNotification';
import { useDeleteOrganisation } from 'hooks/data/organisation/useOrganisation';
import downloadFile from 'utils/downloadFile';
import PageContent from 'components/PageContent';

const useStyles = makeStyles()((theme) => ({
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  tableMt: {
    marginTop: 10,
  },
  marginLeft: {
    marginLeft: 10,
  },
  exportSpinner: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: 10,
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Organisations', isActive: true },
];

const Organisation: React.FC = () => {
  const { classes } = useStyles();
  const { firmId } = useGetRole();
  const { error } = useNotification();

  const pagination = usePagination({
    id: 'organisations',
  });
  const { filters, handleChangeFilters } = useFilters('organisations', {
    onFiltersChange: pagination.toFirstPage,
  });

  const [search, setSearch] = useState(filters?.search || '');
  const debouncedSearch = useDebounce(search, 250);
  useEffect(() => {
    handleChangeFilters({ search: debouncedSearch });
  }, [debouncedSearch]);
  const params = {
    search: filters.search,
    firmId,
    type: pagination.order,
    keyName: pagination.orderBy,
    includeInvestmentValues: true,
  };
  const { data, isFetching, refetch } = useOrganisations({
    params: {
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      ...params,
    },
  });
  const { exportOrganisations, isExportLoading } = useOrganisationExport({
    params: { ...params },
  });
  const deleteOrg = useDeleteOrganisation();

  const [createPermission] = useGetPermissions(['create:organisations']);
  const deleteDisclosure = useDisclosure();

  const columns = [
    { label: 'Organisation name', key: 'name' },
    {
      label: 'Parent organisation',
      key: 'parentId.name',
      sort: false,
      render: (elm) => elm?.parentId?.name ?? '-',
    },
    {
      label: 'Advisers',
      key: 'linkedAdvisersCount',
      sort: false,
      render: (elm) => numberToDisplayString(elm?.linkedAdvisersCount),
    },
    {
      label: 'Clients',
      key: 'investorsCount',
      sort: false,
      render: (elm) => numberToDisplayString(elm?.investorsCount),
    },
    {
      label: 'Subscriptions',
      key: 'investmentsCount',
      sort: false,
      render: (elm) => numberToDisplayString(elm?.investmentsCount),
    },
    {
      label: 'Total initial value',
      key: 'initialValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm?.initialValue),
    },
    {
      label: 'Total current value',
      key: 'currentValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm?.currentValue),
    },
    {
      label: 'Realised value',
      key: 'realisedValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm?.realisedValue),
    },
    {
      label: 'Advice fees',
      key: 'adviceFees',
      sort: false,
      render: (elm) => numberToCurrencyString(elm?.adviceFees),
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (row) => (
        <TableActions row={row} deleteDisclosure={deleteDisclosure} />
      ),
    },
  ];

  const onDeleteOrganisation = async () => {
    const organisationId = deleteDisclosure.actionProps.id;
    await deleteOrg.mutateAsync(organisationId);
    deleteDisclosure.onClose();
    refetch();
  };

  const handleExport = async () => {
    try {
      const data = await exportOrganisations();
      if (data) {
        downloadFile(data, `Organisation-${Date.now()}.xlsx`);
      }
    } catch (exportError: any) {
      if (exportError) {
        error(exportError.message);
        return;
      }
    }
  };

  return (
    <PageContainer heading="Organisations" breadcrumbs={breadcrumbs}>
      <PageContent>
        <GridContainer>
          <Grid
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <TextField
              type="search"
              name="search"
              placeholder="Search by email, name, FCA number"
              fullWidth
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              md: 8,
            }}
          >
            <div className={classes.actionButtonContainer}>
              {createPermission && (
                <Link to={ORGANISATION_ADD}>
                  <Button color="primary" variant="contained">
                    Add Organisation
                  </Button>
                </Link>
              )}
              <Button
                color="primary"
                variant="contained"
                className={classes.marginLeft}
                disabled={isExportLoading}
                onClick={handleExport}
              >
                {isExportLoading ? (
                  <CircularProgress
                    className={classes.exportSpinner}
                    size={18}
                  />
                ) : null}
                Export
              </Button>
            </div>
          </Grid>
        </GridContainer>

        <div className={classes.tableMt}>
          <Table
            columns={columns}
            onRequestSort={pagination.handleRequestSort}
            order={pagination.order}
            orderBy={pagination.orderBy}
            tablebody={data?.organisation ?? []}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            count={data?.totalOrganisation ?? 0}
            pagination={true}
            loading={isFetching}
            variant="nohover"
          />
        </div>
      </PageContent>
      <AlertDialog
        onClose={deleteDisclosure.onClose}
        open={deleteDisclosure.isOpen}
        title="Are you sure you wish to delete this organisation?"
        content="This cannot be undone and all information relating to the organisation will be lost."
        btnLabels={{ confirm: 'Delete organisation', cancel: 'Go back' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
        confirmBtnProps={{
          variant: 'outlined',
          color: 'primary',
          disabled: deleteOrg.isPending,
        }}
        onConfirm={onDeleteOrganisation}
      />
    </PageContainer>
  );
};
export default Organisation;
