import { FileType } from 'further-ui/utils';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import api from 'lib/trpcClient';

type Props = {
  onSuccess?: () => void;
};

const useMutationOpts = (successMessage: string, onSuccess?: () => void) =>
  useHandleTrpcMutation()({
    successMessage,
    invalidationHandler: (utils) => {
      utils.account.invalidate();
      onSuccess?.();
    },
  });

export default function useAccountsManagement({ onSuccess }: Props = {}) {
  const deleteAccount = api.account.remove.useMutation(
    useMutationOpts('Account deleted successfully', onSuccess),
  );
  const createAccount = api.account.create.useMutation(
    useMutationOpts('Account created successfully', onSuccess),
  );
  const updateAccount = api.account.update.useMutation(
    useMutationOpts('Account updated successfully', onSuccess),
  );

  const exportAccounts = api.transactionType.exportList.useMutation({
    onSuccess: (data) => {
      const mediaType = `data:${FileType.ExcelAlt};base64,`;

      const aElement = document.createElement('a');
      aElement.setAttribute('download', 'Transaction types.xlsx');
      aElement.href = `${mediaType}${data.file}`;
      aElement.setAttribute('target', '_blank');
      aElement.click();
    },
  });

  return { createAccount, updateAccount, deleteAccount, exportAccounts };
}
