import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DropzoneComponent from 'components/Dropzone';
import FieldRow from 'components/FormElements/FieldRow';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';
import { TranchePromotion } from '.';

const useStyles = makeStyles()(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type Props = {
  handleCancel: () => void;
  handleClickTranchePromotion: () => void;
  id: string;
  onChangeTranchePromotion: (files: File[], field: string) => void;
  setTranchePromotion: (tranchePromotion: TranchePromotion) => void;
  tranchePromotion: TranchePromotion;
  disabled: boolean;
  isSyndicate: boolean;
  isLP: boolean;
  additionalDocuments: {
    name: string;
    preview: string;
  }[];
  handleChangeAdditionalDocuments: (files: File[]) => void;
  handleRemoveAdditionalDocuments: (index: number) => void;
};

export default function TranchePromotionInformation({
  handleCancel,
  handleClickTranchePromotion,
  id,
  onChangeTranchePromotion,
  setTranchePromotion,
  tranchePromotion,
  disabled = false,
  isSyndicate = false,
  isLP = false,
  additionalDocuments,
  handleChangeAdditionalDocuments,
  handleRemoveAdditionalDocuments,
}: Props) {
  const { classes } = useStyles();

  return (
    <RecordView>
      <FieldRow
        title={`Legal agreement${isSyndicate ? '' : '*'}`}
        tooltipText="Please upload your subscription management agreement, limited partnership agreement or, if you have a prospectus, your application form."
      >
        <DropzoneComponent
          name="applicationFormFiles"
          defaultAcceptGroup="files"
          onDropFile={(files) =>
            onChangeTranchePromotion(files, 'applicationFormFile')
          }
          files={
            tranchePromotion?.applicationFormFile
              ? [
                  {
                    preview: tranchePromotion?.applicationFormFilePreview || '',
                    fileName: tranchePromotion?.applicationFormFile || '',
                  },
                ]
              : []
          }
          onRemove={() =>
            setTranchePromotion({
              ...tranchePromotion,
              applicationFormFile: null,
              applicationFormFilePreview: null,
            })
          }
        />
      </FieldRow>

      {isLP ? (
        <>
          <FieldRow
            title="Additional documents"
            tooltipText="Upload any other fund documentation here, for example your PPM, fundraising presentation, FAQ document, KID or other documents you wish to share."
          >
            <DropzoneComponent
              name="additionalDocuments"
              defaultAcceptGroup="files"
              onDropFile={handleChangeAdditionalDocuments}
              onRemove={handleRemoveAdditionalDocuments}
              maxFiles={10}
              files={additionalDocuments.map((file) => ({
                fileName: file.name,
                preview: file.preview,
              }))}
            />
          </FieldRow>
        </>
      ) : (
        <>
          <FieldRow
            title="Offering document"
            tooltipText="Please upload your core fund marketing document. This may be a prospectus, information memorandum or key fundraising presentation."
          >
            <DropzoneComponent
              name="prospectusFiles"
              defaultAcceptGroup="files"
              onDropFile={(files) =>
                onChangeTranchePromotion(files, 'prospectusFile')
              }
              files={
                tranchePromotion?.prospectusFile
                  ? [
                      {
                        preview: tranchePromotion?.prospectusFilePreview || '',
                        fileName: tranchePromotion?.prospectusFile || '',
                      },
                    ]
                  : []
              }
              onRemove={() =>
                setTranchePromotion({
                  ...tranchePromotion,
                  prospectusFile: null,
                  prospectusFilePreview: null,
                })
              }
            />
          </FieldRow>
          <FieldRow
            title="Key information document"
            tooltipText="Please upload your Key Information Document (KID) if you have been required to produce one."
          >
            <DropzoneComponent
              name="keyInfoFiles"
              defaultAcceptGroup="files"
              onDropFile={(files) =>
                onChangeTranchePromotion(files, 'keyInfoFile')
              }
              files={
                tranchePromotion?.keyInfoFile
                  ? [
                      {
                        preview: tranchePromotion?.keyInfoFilePreview || '',
                        fileName: tranchePromotion?.keyInfoFile || '',
                      },
                    ]
                  : []
              }
              onRemove={() =>
                setTranchePromotion({
                  ...tranchePromotion,
                  keyInfoFile: null,
                  keyInfoFilePreview: null,
                })
              }
            />
          </FieldRow>
        </>
      )}

      <ButtonGroup>
        <Button
          className={classes.marginLeftBtn}
          variant="outlined"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className={classes.marginLeftBtn}
          variant="contained"
          color="primary"
          disabled={
            (!isSyndicate && !tranchePromotion?.applicationFormFile) || disabled
          }
          onClick={handleClickTranchePromotion}
        >
          {id ? 'Update' : 'Save'}
        </Button>
      </ButtonGroup>
    </RecordView>
  );
}
