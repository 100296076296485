import { Controller, useFormContext } from 'react-hook-form';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import AppPasswordInput from 'components/FormElements/AppPasswordTextField';
import { useState } from 'react';
import { Box } from '@mui/material';
import Dropzone from 'components/Dropzone';
import Spacing from 'components/Spacing';
import { Error } from 'components/FormElements/LayoutComponents';
import FieldSwitch from 'components/FormElements/FieldSwitch';
import { InvestmentFirmFormData } from './schema';

const GeneralInformation = ({ firm, handleFileUpload }) => {
  const [showExcelFilesPasswordField, setShowExcelFilesPasswordField] =
    useState(false);

  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext<InvestmentFirmFormData>();

  const [summaryAttachmentFilePreviews, setSummaryAttachmentFilePreviews] =
    useState(
      firm.data?.summaryAttachments?.map(({ fileName, signedUrl }) => ({
        preview: signedUrl,
        fileName,
      })),
    );

  return (
    <Spacing>
      <FieldRow title="Firm name*" centerTitle>
        <Controller
          name="firmName"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="e.g. Acme Ventures"
              fullWidth
              error={!!errors.firmName}
              helperText={errors.firmName?.message}
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Key contact name*" centerTitle>
        <Controller
          name="keyContactName"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Who is your firm's main user of Further?"
              fullWidth
              error={!!errors.keyContactName}
              helperText={errors.keyContactName?.message}
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Key contact email address*" centerTitle>
        <Controller
          name="keyContactEmail"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Please enter their email address"
              fullWidth
              error={!!errors.keyContactEmail}
              helperText={errors.keyContactEmail?.message}
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Secondary contact name" centerTitle>
        <Controller
          name="secondaryContactName"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Who is your firm's secondary user of Further?"
              fullWidth
              error={!!errors.secondaryContactName}
              helperText={errors.secondaryContactName?.message}
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Secondary contact email address" centerTitle>
        <Controller
          name="secondaryContactEmail"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Please enter their email address"
              fullWidth
              error={!!errors.secondaryContactEmail}
              helperText={errors.secondaryContactEmail?.message}
            />
          )}
        />
      </FieldRow>

      <FieldRow
        title="Summary email attachment (PDF only)"
        informationText="Any file added here will be attached to your daily subscription summary emails. This attachment option can be used for daily AML sign off letters or other required confirmation files"
      >
        <Controller
          name="summaryAttachments"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Dropzone
              {...rest}
              maxFiles={10}
              onDropFile={async (files) => {
                await handleFileUpload(
                  files,
                  (fileName: string, filePath: string, signedUrl: string) => {
                    if (!value) {
                      setValue('summaryAttachments', [{ fileName, filePath }]);
                      setSummaryAttachmentFilePreviews([
                        { fileName, preview: signedUrl },
                      ]);
                    } else {
                      setValue('summaryAttachments', [
                        ...value,
                        { fileName, filePath },
                      ]);

                      setSummaryAttachmentFilePreviews([
                        ...summaryAttachmentFilePreviews,
                        { fileName, preview: signedUrl },
                      ]);
                    }
                  },
                );
              }}
              accept={{ 'application/pdf': ['.pdf'] }}
              files={
                summaryAttachmentFilePreviews
                  ? summaryAttachmentFilePreviews
                  : []
              }
              onRemove={(index) => {
                const newFiles = value?.filter((_, i) => i !== index);
                const newFilePreview = summaryAttachmentFilePreviews.filter(
                  (_, i) => i !== index,
                );
                setValue('summaryAttachments', newFiles);
                setSummaryAttachmentFilePreviews(newFilePreview);
              }}
            />
          )}
        />
        <Error>{errors.summaryAttachments?.message}</Error>
      </FieldRow>

      <FieldRow
        title="System email recipient"
        informationText="Separate emails by a semi-colon"
      >
        <Controller
          name="systemEmailRecipient"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter the email addresses you wish to be copied in to system emails, such as subscription confirmations sent to investors. Due to a potential large volume of emails, we recommend personal email addresses are not used. Please separate each email with a semi-colon. e.g. 'a@a.com;b@b.com'"
              fullWidth
              multiline
              minRows={4}
              error={!!errors.systemEmailRecipient}
              helperText={errors.systemEmailRecipient?.message}
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Password protect spreadsheets sent in emails?">
        <Controller
          name="excelFilesPassword"
          control={control}
          render={({ field: { ref, value, ...rest } }) => (
            <Box display="flex" gap={16} flexDirection="column">
              <FieldSwitch
                checked={showExcelFilesPasswordField || !!value}
                checkedLabel="Yes"
                uncheckedLabel="No"
                isControlled={false}
                onChange={(event) => {
                  setShowExcelFilesPasswordField(event.target.checked);
                }}
              />
              {showExcelFilesPasswordField || value ? (
                <AppPasswordInput
                  {...rest}
                  inputRef={ref}
                  value={value}
                  fullWidth
                  error={!!errors.excelFilesPassword}
                  helperText={errors.excelFilesPassword?.message}
                />
              ) : null}
            </Box>
          )}
        />
      </FieldRow>
    </Spacing>
  );
};

export default GeneralInformation;
