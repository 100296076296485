import api from 'lib/trpcClient';
import { useLayoutContext } from 'contexts/LayoutContext';
import { AccountSectionLabels } from 'further-ui/labels';

const useTransactionTypeSelectorOptions = () => {
  const { selectedVehicleId } = useLayoutContext();

  const accounts = api.account.listAllAccounts.useQuery({
    vehicleId: selectedVehicleId ?? '',
  });

  if (accounts.isLoading) {
    return { transactionTypeOptions: [], isLoading: true };
  }

  const transactionTypeOptions = Object.keys(accounts.data ?? {}).flatMap(
    (key) => [
      { label: AccountSectionLabels[key], value: null },
      ...(accounts.data?.[key] ?? []).flatMap((account) =>
        account.transactionTypes.map((transactionType) => ({
          label: `${account.accountCode} - ${account.accountName} - ${transactionType.name}`,
          value: transactionType.id,
        })),
      ),
    ],
  );

  return { transactionTypeOptions, isLoading: false };
};

export default useTransactionTypeSelectorOptions;
