export const stages = {
  PRE_SEED: 'Pre-Seed',
  SEED: 'Seed',
  SERIES_A: 'Series A',
  SERIES_B: 'Series B',
  SERIES_C: 'Series C',
  SERIES_D: 'Series D',
  SERIES_E: 'Series E+',
};

export const sectors = {
  ADVANCED_MATERIALS: 'Advanced Materials',
  AEROSPACE: 'Aerospace',
  AI: 'Artificial Intelligence',
  APPLIEDAI: 'Applied AI',
  APPLIEDPHYSICS: 'Applied Physics',
  BIOTECH: 'Biotech',
  BLOCKCHAIN: 'Blockchain',
  CLOUD_DATA_INFRASTRUCTURE: 'Cloud & Data Infrastructure',
  COMMERCE: 'Commerce',
  CONSTRUCTION: 'Construction',
  CONSUMER: 'Consumer',
  CONSUMER_TECHNOLOGY: 'Consumer Technology',
  CYBER_SEC: 'Cyber Security',
  C_AND_D: 'Content & Design',
  DATA_DRIVEN_HEALTH: 'Data-Driven Health',
  DEEPTECH: 'DeepTech',
  DEVOPS: 'DevOps',
  EDTECH: 'Edtech',
  EDU: 'Education',
  ENERGY: 'Energy',
  ENTERPRISE_SOFTWARE: 'Enterprise Software',
  FINTECH: 'Fintech',
  FIN_SERVICES: 'Financial Services',
  FOODTECH: 'Foodtech',
  FUTUREOFWORK: 'Future of work',
  GAMING: 'Gaming',
  HEALTHCARE: 'Healthcare',
  HEALTHTECH: 'Healthtech',
  HR: 'HR',
  HRTECH: 'HRtech',
  INDUSTRIAL: 'Industrial Applications',
  INSURTECH: 'Insurtech',
  IOT: 'IoT',
  LIFE_SCI: 'Life Sciences',
  LOGISTICS: 'Logistics',
  MARKETING_TECH: 'Marketing Tech',
  MEDIA: 'Media',
  MIDDLEWARE: 'Middleware',
  MOBILITY: 'Mobility',
  NEXT_GEN_MEDIA: 'Next Gen Media',
  PHOTONICS: 'Photonics',
  PRODUCTTOOLS: 'Product Tools',
  PROPTECH: 'Proptech',
  REAL_ESTATE: 'Real Estate',
  RETAILTECH: 'Retail Tech',
  REV_OPS: 'RevOps',
  ROBOTICS: 'Robotics',
  SAAS: 'SaaS',
  SALES: 'Sales',
  SALES_ENAB: 'Sales Enablement',
  SECURITY: 'Security',
  SPORTS: 'Sports',
  SUSTAINABILITY: 'Sustainability',
  TELECOMS: 'Telecommunications',
  TRANSPORT: 'Transport',
  TRAVEL: 'Travel',
  UTILITIES: 'Utilities',
  VERTICALSAAS: 'Vertical SaaS',
  WEB3: 'Web3',
  OTHER: 'Other',
};

export const companiesHouseUrl =
  'https://find-and-update.company-information.service.gov.uk/company/';
