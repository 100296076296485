import { api } from 'lib/httpClient';
import { UploadType } from 'further-types/files';

const awsUrl = process.env.REACT_APP_AWS_URL;

export type UploadFileResult = {
  url: string;
  filePath: string;
};

const sendFiletoAWS = (file: File, signedPutUrl: string) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedPutUrl);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        resolve(xhr.response);
      }
    });
    xhr.send(file);
  });
};

export const getFileSignedUrl = async (key: string): Promise<string> => {
  try {
    const response = await api.get(`${awsUrl}/signed-url/get-object`, {
      params: { key },
    });

    return response.data.data.signedUrl;
  } catch (_) {
    throw new Error(
      'Something went wrong when generating signed URL for the file.',
    );
  }
};

function useFileUpload() {
  const uploadFile = async (
    file: File,
    uploadType: UploadType,
    firmId?: string,
    fileName?: string,
  ): Promise<UploadFileResult> => {
    try {
      const { data: result } = await api.get(`${awsUrl}/get-signed-url`, {
        params: {
          firmId,
          uploadType,
          contentType: file.type,
          fileName,
        },
      });

      if (result.statusCode !== 200) {
        throw new Error('Error when fetching signed URL');
      }
      await sendFiletoAWS(file, result.data.signedUrl);

      return {
        url: result.data.isPrivate
          ? await getFileSignedUrl(result.data.filePath)
          : result.data.url,
        filePath: result.data.filePath,
      };
    } catch (_) {
      throw new Error('Something went wrong when uploading the file.');
    }
  };

  return {
    uploadFile,
  };
}

export default useFileUpload;
