import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { vehicle as rules } from 'further-ui/validations';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ActionRefLabels, ActionTypeLabels } from 'further-ui/labels';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import Table from 'components/Table';
import { z } from 'zod';
import api from 'lib/trpcClient';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import { ActionRef } from 'further-types/lp/action';
import { ApiResponses } from 'lib/trpcClient';

type ActionTypes = ApiResponses['action']['listTypes'];

type FormValues = z.infer<typeof rules.UpdateAccountMappings>;

type AccountMapping = {
  actionRef: ActionRef;
  actionType: ActionTypes[number]['actionType'];
  debitTransactionTypeId: string;
  creditTransactionTypeId: string;
};

type Props = {
  vehicleId: string;
  transactionTypes: Array<{
    label: string;
    value: string | null;
  }>;
  actionTypes: ActionTypes;
  defaultAccountMappings: Array<AccountMapping>;
};

const AccountMappingGrid: React.FC<Props> = ({
  vehicleId,
  defaultAccountMappings,
  transactionTypes,
}) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(rules.UpdateAccountMappings),
    defaultValues: {
      accountMappings: defaultAccountMappings,
    },
  });

  const editMappings = api.vehicle.updateAccountMappings.useMutation(
    useHandleTrpcMutation()({
      successMessage: 'Account mappings updated',
      invalidationHandler: (utils) => utils.vehicle.getById.invalidate(),
    }),
  );

  const onSubmit = (data: FormValues) => {
    editMappings.mutate({
      vehicleId,
      accountMappings: data.accountMappings,
    });
  };

  const { fields } = useFieldArray({
    control,
    name: 'accountMappings',
  });

  const columns = [
    {
      key: 'actionRef',
      label: 'Action',
      render: (row: AccountMapping, index: number) => (
        <Controller
          name={`accountMappings.${index}.actionRef`}
          control={control}
          defaultValue={row.actionRef}
          render={() => (
            <FormControl>
              <Typography variant="body2" color="text.primary" component="span">
                {ActionRefLabels[row.actionRef]}{' '}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                >
                  ({ActionTypeLabels[row.actionType]})
                </Typography>
              </Typography>
            </FormControl>
          )}
        />
      ),
    },
    {
      key: 'debitTransactionTypeId',
      label: 'Debit',
      render: (_: unknown, index: number) => (
        <Controller
          name={`accountMappings.${index}.debitTransactionTypeId`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error}>
              <Select
                {...field}
                placeholder="Select transaction type"
                fullWidth
              >
                {transactionTypes.map((option) =>
                  !option.value ? (
                    <ListSubheader key={option.label}>
                      {option.label}
                    </ListSubheader>
                  ) : (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
        />
      ),
    },
    {
      key: 'creditTransactionTypeId',
      label: 'Credit',
      render: (_: unknown, index: number) => (
        <Controller
          name={`accountMappings.${index}.creditTransactionTypeId`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error}>
              <Select
                {...field}
                placeholder="Select transaction type"
                fullWidth
              >
                {transactionTypes.map((option) =>
                  !option.value ? (
                    <ListSubheader key={option.label}>
                      {option.label}
                    </ListSubheader>
                  ) : (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
        />
      ),
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Spacing>
        <Table columns={columns} tablebody={fields} fixedColumns />
        <ButtonGroup>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            loading={editMappings.isPending}
          >
            Save changes
          </Button>
        </ButtonGroup>
      </Spacing>
    </form>
  );
};

export default AccountMappingGrid;
