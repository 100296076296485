import { FormControl, ListSubheader, MenuItem, Select } from '@mui/material';
import Table from 'components/Table';
import { TransactionOperation } from 'further-types/lp/transaction';
import { numberToCurrencyString } from 'further-ui/utils';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';

export type Transaction = {
  id?: string;
  transactionTypeId: string;
  operationType: TransactionOperation;
  amountExVat: number;
  amountIncVat: number;
};

type Props = {
  defaultTransactions: Array<Transaction>;
  transactionTypeOptions: Array<{
    label: string;
    value: string | null;
  }>;
  onChange: (transactions: Array<Transaction>) => void;
};

const TransactionAllocator: React.FC<Props> = ({
  defaultTransactions,
  transactionTypeOptions,
  onChange,
}) => {
  const { control } = useForm<{ transactions: Array<Transaction> }>({
    defaultValues: {
      transactions: defaultTransactions,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'transactions',
  });

  const { transactions } = useWatch({ control });

  useEffect(() => {
    if (!transactions) return;

    onChange(
      transactions.map((t) => ({
        id: t.id,
        transactionTypeId: t.transactionTypeId!,
        operationType: t.operationType!,
        amountExVat: t.amountExVat ?? 0,
        amountIncVat: t.amountIncVat ?? 0,
      })),
    );
  }, [transactions]);

  const columns = [
    {
      key: 'transactionTypeId',
      label: 'General ledger account and transaction type',
      render: (_: unknown, index: number) => (
        <Controller
          name={`transactions.${index}.transactionTypeId`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error}>
              <Select
                {...field}
                placeholder="Select transaction type"
                fullWidth
              >
                {transactionTypeOptions.map((option) =>
                  !option.value ? (
                    <ListSubheader key={option.label}>
                      {option.label}
                    </ListSubheader>
                  ) : (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
        />
      ),
    },
    {
      key: 'debitAmount',
      label: 'Debit amount',
      render: (row: Transaction) =>
        row.operationType === TransactionOperation.Debit
          ? numberToCurrencyString(row.amountIncVat)
          : '-',
    },
    {
      key: 'creditAmount',
      label: 'Credit amount',
      render: (row: Transaction) =>
        row.operationType === TransactionOperation.Credit
          ? numberToCurrencyString(row.amountIncVat)
          : '-',
    },
  ];

  return <Table columns={columns} tablebody={fields} fixedColumns />;
};

export default TransactionAllocator;
