import React from 'react';
import { DateRangePicker } from 'materialui-daterange-picker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import TextField from '../AppTextInput';
import { endOfDay, format, startOfDay } from 'date-fns';
import { InputAdornment, styled } from '@mui/material';

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledIconButton = styled(IconButton)({
  padding: '3px',
});

const formatString = 'dd/MM/yyyy';

export type DateRange = {
  startDate?: Date;
  endDate?: Date;
};

type Props = {
  dateRange?: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
  placeholder?: string;
  definedRanges?: Array<{ label: string; startDate: Date; endDate: Date }>;
};

export const MuiDateRangePicker: React.FC<Props> = ({
  dateRange,
  onDateRangeChange,
  placeholder,
  definedRanges,
}) => {
  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpen(!open);

  const getDisplayDateRange = (dateRange?: DateRange) => {
    const startDate = dateRange?.startDate
      ? format(dateRange.startDate, formatString)
      : undefined;

    const endDate = dateRange?.endDate
      ? format(dateRange.endDate, formatString)
      : undefined;

    return startDate || endDate ? `${startDate} - ${endDate}` : '';
  };

  return (
    <>
      <TextField
        placeholder={placeholder}
        value={getDisplayDateRange(dateRange)}
        onClick={toggle}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledIconButton size="large">
                <DateRangeIcon />
              </StyledIconButton>
              {getDisplayDateRange(dateRange) && (
                <StyledIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDateRangeChange({
                      startDate: undefined,
                      endDate: undefined,
                    });
                  }}
                  size="large"
                >
                  <CloseIcon />
                </StyledIconButton>
              )}
            </InputAdornment>
          ),
        }}
        slotProps={{
          input: {
            readOnly: true,
          },
        }}
      />
      <StyledModal
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div>
            <DateRangePicker
              open={true}
              toggle={toggle}
              onChange={(range) => {
                onDateRangeChange({
                  startDate: startOfDay(range.startDate ?? new Date()),
                  endDate: endOfDay(range.endDate ?? new Date()),
                });
                toggle();
              }}
              initialDateRange={dateRange}
              definedRanges={definedRanges}
              wrapperClassName="date-range-picker-wrapper"
              minDate={new Date('1900-01-01')}
            />
          </div>
        </Fade>
      </StyledModal>
    </>
  );
};
