import React, { useMemo } from 'react';
import Table, { Column } from 'components/Table';
import { Container, styled } from '@mui/material';
import { IncompleteInvestment } from 'further-types/investment';
import { TableRowType } from './types';
import Heading from 'components/Heading';

interface ChildRowContentProps {
  previousCarts: Array<IncompleteInvestment>;
  columns: Array<Column<TableRowType>>;
}

const EmptyContainer = styled(Container)(({ theme }) => ({
  marginBottom: '1rem',
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: '400',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px',
  '& table': {
    '& th': {
      backgroundColor: theme.palette?.table?.subHeadingColor,
    },
  },
}));

const StyledHeading = styled(Heading)({
  paddingBottom: '12px',
});

const ChildRowContent: React.FC<ChildRowContentProps> = ({
  previousCarts,
  columns,
}) => {
  const filteredColumns = useMemo(
    () =>
      columns.filter(
        (column) =>
          column.key !== 'fullName' &&
          column.key !== 'email' &&
          column.key !== 'firmName' &&
          column.key !== 'fundId',
      ),
    [columns],
  );

  if (!previousCarts?.length) {
    return (
      <EmptyContainer>
        <div className="centered">
          No previous incomplete subscriptions found
        </div>
      </EmptyContainer>
    );
  }

  return (
    <Container>
      <StyledHeading variant="h5" noMargin>
        Previous incomplete subscriptions
      </StyledHeading>
      <Table
        columns={filteredColumns}
        tablebody={previousCarts as unknown as TableRowType[]}
        variant="nohover"
      />
    </Container>
  );
};

export default ChildRowContent;
