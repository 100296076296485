import { useMutation } from '@tanstack/react-query';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { numberToCurrencyString } from 'further-ui/utils';
import { dateToLabel } from 'further-ui/utils';
import formatExitedAtToLabel from '../utils/format-exited-at-to-label';
import { Api } from 'further-types/investment';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';

const useExcelExport = (params: Api.GetIncompleteInvestmentsParams) => {
  const exportMutation = useMutation({
    mutationFn: async () => {
      const { data } = await api.get<
        ApiResponse<Api.GetIncompleteInvestmentsResult>
      >('investment/incomplete-investment', {
        params: { ...params, isExport: true },
      });

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Incomplete subscriptions');

      worksheet.columns = [
        { header: 'Investor name', key: 'fullName', width: 25 },
        { header: 'Email', key: 'email', width: 35 },
        { header: 'Firm', key: 'firmName', width: 25 },
        { header: 'Tranche', key: 'fundName', width: 25 },
        {
          header: 'Total subscription amount',
          key: 'investmentAmount',
          width: 25,
        },
        { header: 'Exited', key: 'step', width: 30 },
        { header: 'Subscription date', key: 'InvestmentDate', width: 20 },
      ];

      data.data.result.forEach((item) => {
        const { mainRecord, previousCarts } = item;
        const flattenedPreviousCarts = [mainRecord, ...previousCarts];
        flattenedPreviousCarts.forEach((cart) => {
          worksheet.addRow({
            fullName: cart?.fullName,
            email: cart?.investor?.email,
            firmName: cart?.firmName,
            fundName: cart?.fundName,
            investmentAmount: numberToCurrencyString(
              item?.mainRecord?.investmentAmount,
            ),
            step: formatExitedAtToLabel({
              step: cart?.step,
              adviserId: cart?.adviserId,
              kycStatusLabel: cart?.kycStatusLabel,
            }),
            InvestmentDate: dateToLabel(item?.mainRecord?.createdAt),
          });
        });
      });

      worksheet.getRow(1).font = { bold: true };

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'Incomplete investments.xlsx',
      );
    },
  });

  return {
    exportExcelSheet: () => exportMutation.mutate(),
    isExporting: exportMutation.isPending,
  };
};

export default useExcelExport;
