import { Realm } from 'further-types/admin-user';

import EisRoutes from './RouteSetup/EisRoutes';
import LpRoutes from './RouteSetup/LpRoutes';
import PageLoader from 'components/PageLoader';
import useAuth from 'hooks/auth/useAuth';
import DownloadTrayContainer from 'components/DownloadTray/DownloadTrayContainer';
import VehicleSelectionGate from 'layouts/AppLayout/VehicleSelectionGate';

const Routes: React.FC = () => {
  const { isLoading, realm } = useAuth();

  if (isLoading) {
    return <PageLoader />;
  }

  if (realm === Realm.Lp) {
    return (
      <VehicleSelectionGate>
        <LpRoutes />
      </VehicleSelectionGate>
    );
  }

  if (realm === Realm.Eis || realm === Realm.Super) {
    return (
      <>
        <EisRoutes />
        <DownloadTrayContainer />
      </>
    );
  }
  return null;
};

export default Routes;
