import { startCase, lowerCase, take } from 'lodash';
import Table, { Column } from 'components/Table';
import PageContainer from 'components/PageContainer';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import AlertDialog from 'components/AlertDialog';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import { investorReportingType } from 'adminConstants/investorReporting';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useDisclosure } from 'further-ui/hooks';
import {
  INVESTOR_REPORTING_ADD,
  ADD_CUSTOM_UPDATE,
} from 'adminConstants/routes';
import ActionTools from './ActionTools';
import { usePagination } from 'hooks/ui/usePagination';
import { useInvestorReporting } from 'hooks/data/investorReporting/useInvestorReporting';
import { useFirm } from 'hooks/data/firm/useFirm';
import useFilters from 'hooks/ui/useFilters';
import { useExitStatement } from 'hooks/data/exit';
import { dateToLabel } from 'further-ui/utils';
import PageContent from 'components/PageContent';
import React from 'react';
import PageFilters, { Filter } from 'components/PageFilters';

type InvestorReport = {
  _id: string;
  subject: string;
  managerOverViewTitle: string;
  updateType: string;
  status: string;
  viewable: boolean;
  sendTo: Array<string>;
  submitDate: string;
  firmId?: {
    firmName: string;
  };
};

const staticColumns: Array<Column<InvestorReport>> = [
  {
    label: 'Update date',
    key: 'submitDate',
    sort: true,
    render: (elm) => <>{dateToLabel(elm?.submitDate)} </>,
  },
  {
    label: 'Update type',
    sort: true,
    key: 'updateType',
  },
  {
    label: 'Status',
    key: 'status',
    sort: true,
    render: (elm) => <div>{startCase(lowerCase(elm?.status))} </div>,
  },
  {
    label: 'Firm name',
    key: 'firmId',
    sort: false,
    render: (elm) => <>{elm?.firmId?.firmName} </>,
  },
  {
    label: 'Report name',
    key: 'reportName',
    sort: false,
    render: (elm) => {
      const title =
        elm.updateType === investorReportingType.DEPLOYMENT_UPDATE ||
        elm.updateType === investorReportingType.CUSTOM_UPDATE
          ? elm.subject
          : elm.managerOverViewTitle;
      return <>{title}</>;
    },
  },
  {
    label: 'Sent to',
    key: 'sendTo',
    sort: false,
    render: (elm) => (
      <>
        {take(elm.sendTo, 2).map((record, index) => (
          <span key={index}>
            {record}
            {elm.sendTo.length !== 1 && index !== 1 ? ', ' : ''}
            {elm.sendTo.length > 2 && index === 1
              ? ` and ${elm.sendTo.length - 2} more.`
              : ''}
          </span>
        ))}
      </>
    ),
  },
];

const InvestorReporting: React.FC = () => {
  const { firmId, isSuperAdmin } = useGetRole();
  const deleteDisclosure = useDisclosure();
  const [canCreate, canUpdate, canDelete] = useGetPermissions([
    'create:report',
    'edit:report',
    'delete:report',
  ]);

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    toFirstPage,
    handleRequestSort,
  } = usePagination({
    id: 'investor-reporting',
    orderBy: 'submitDate',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters<{
    startDate?: Date;
    endDate?: Date;
    firmId?: string;
  }>('investor-reporting', {
    defaultFilters: { startDate: undefined, endDate: undefined, firmId },
    onFiltersChange: toFirstPage,
  });

  const onDeleteSuccess = () => {
    deleteDisclosure.onClose();
  };

  const {
    fetchInvestorReports,
    deleteInvestorReport: { mutate: deleteReport, isPending: isRemoving },
  } = useInvestorReporting({
    onDeleteSuccess,
  });

  const {
    deleteExitStatement: {
      mutate: deleteExitStatement,
      isPending: isRemovingExitStatement,
    },
  } = useExitStatement({
    idToDelete: deleteDisclosure.actionProps?.id,
    onDeleteSuccess,
  });

  const {
    firms: { data: firms },
  } = useFirm({ params: {} });

  const { data, isFetching } = fetchInvestorReports({
    page,
    perPage: rowsPerPage,
    type: order,
    keyName: orderBy,
    firmId: filters?.firmId,
    startDate: filters?.startDate,
    endDate: filters?.endDate,
  });

  return (
    <PageContainer
      heading="Investor Reporting"
      breadcrumbs={[
        { label: 'Dashboard', link: '/' },
        { label: 'Investor Reporting', isActive: true },
      ]}
    >
      <PageContent>
        <PageFilters
          pageId="investor-reporting"
          buttons={[
            {
              visible: canCreate,
              label: 'Create periodic update',
              link: INVESTOR_REPORTING_ADD,
            },
            {
              visible: canCreate,
              label: 'Create custom update',
              link: ADD_CUSTOM_UPDATE,
            },
          ]}
        >
          {isSuperAdmin && (
            <Filter>
              <Autocomplete
                id="firmId"
                options={firms?.result ?? []}
                getOptionLabel={(option) => {
                  return option.firmName;
                }}
                filterSelectedOptions
                value={(firms?.result ?? []).find(
                  (firm) => firm._id === filters?.firmId,
                )}
                onChange={(_, firm) => {
                  handleChangeFilters({ firmId: firm?._id });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search a firm"
                  />
                )}
              />
            </Filter>
          )}
          <Filter>
            <MuiDateRangePicker
              dateRange={filters}
              onDateRangeChange={(dateRange) => {
                handleChangeFilters(dateRange);
              }}
              placeholder="Select date"
            />
          </Filter>
        </PageFilters>

        <Table
          columns={[
            ...staticColumns,
            {
              label: 'Tools',
              key: 'actions',
              sort: false,
              render: (elm) => (
                <ActionTools
                  investorReport={elm}
                  canUpdate={canUpdate}
                  canDelete={canDelete}
                  deleteDisclosure={deleteDisclosure}
                />
              ),
            },
          ]}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          tablebody={data?.investorReportings ?? []}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          count={data?.total ?? 0}
          pagination={true}
          loading={isFetching}
          variant="nohover"
        />
      </PageContent>

      <AlertDialog
        onClose={deleteDisclosure.onClose}
        open={deleteDisclosure.isOpen}
        title={`Are you sure you want to delete this ${
          deleteDisclosure.actionProps?.isExitStatement
            ? 'exit statement'
            : 'investor report'
        }?`}
        content={`This action cannot be undone and all information relating to the ${
          deleteDisclosure.actionProps?.isExitStatement
            ? 'exit statement'
            : 'investor report'
        } will be lost.`}
        btnLabels={{
          confirm: `Delete ${
            deleteDisclosure.actionProps?.isExitStatement
              ? 'exit statement'
              : 'investor report'
          }`,
          cancel: 'Go back',
        }}
        cancelBtnProps={{
          variant: 'contained',
          color: 'primary',
          sx: { minWidth: '180px !important' },
        }}
        confirmBtnProps={{
          variant: 'outlined',
          color: 'primary',
          disabled: isRemoving || isRemovingExitStatement,
        }}
        onConfirm={() =>
          deleteDisclosure.actionProps?.isExitStatement
            ? deleteExitStatement(deleteDisclosure.actionProps.id)
            : deleteReport(deleteDisclosure.actionProps.id)
        }
      />
    </PageContainer>
  );
};
export default InvestorReporting;
