import { Autocomplete, createFilterOptions } from '@mui/material';
import { AppTextInput } from 'components/FormElements';
import { forwardRef } from 'react';

type Counterparty = {
  _id?: string;
  name: string;
};

type Props = {
  contacts: Array<{
    _id: string;
    name: string;
  }>;
  error?: {
    message?: string;
  };
  placeholder?: string;
  value?: Counterparty;
  onChange: (value: Counterparty) => void;
  name?: string;
  disabled?: boolean;
};

const CounterpartySelector = forwardRef<HTMLDivElement, Props>(
  ({ name, disabled, contacts, error, placeholder, value, onChange }, ref) => {
    const filter = createFilterOptions<Counterparty>();

    return (
      <Autocomplete
        freeSolo
        disableClearable
        clearOnBlur
        disabled={disabled}
        ref={ref}
        options={contacts}
        value={value}
        renderInput={(params) => (
          <AppTextInput
            {...params}
            name={name}
            error={!!error}
            helperText={error?.message?.toString()}
            placeholder={placeholder}
          />
        )}
        getOptionKey={(option) =>
          typeof option === 'string' ? option : option._id ?? ''
        }
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;

          if (!filtered.length && inputValue !== '') {
            filtered.push({
              _id: undefined,
              name: inputValue,
            });
          }

          return filtered;
        }}
        onChange={(_, newValue) => {
          const counterparty =
            typeof newValue === 'string'
              ? {
                  _id: undefined,
                  name: newValue,
                }
              : newValue;

          onChange(counterparty);
        }}
      />
    );
  },
);

export default CounterpartySelector;
