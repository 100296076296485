import { styled } from '@mui/material';

import { Menu } from '@mui/material';

import { MenuItem } from '@mui/material';
import AppTextInput from 'components/FormElements/AppTextInput';
import { useState } from 'react';

type SearchableMenuProps = {
  options: Array<any>;
  onSelect: (option: any) => void;
  getOptionLabel: (option: any) => string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  id: string;
  searchPlaceholder?: string;
};

const FilterContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4),
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  '& > div': {
    width: '100%',
    textAlign: 'center',
  },
}));

const SearchableMenu = ({
  options,
  onSelect,
  getOptionLabel,
  anchorEl,
  onClose,
  id,
  searchPlaceholder,
}: SearchableMenuProps) => {
  const [filterValue, setFilterValue] = useState('');

  const filteredOptions = options.filter((option) =>
    getOptionLabel(option).toLowerCase().includes(filterValue.toLowerCase()),
  );

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      id={id}
      sx={{
        '& .MuiMenu-list': {
          paddingTop: 0,
        },
      }}
    >
      <FilterContainer>
        <AppTextInput
          label={searchPlaceholder ?? 'Search'}
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </FilterContainer>
      {filteredOptions.map((option) => (
        <MenuItem key={getOptionLabel(option)} onClick={() => onSelect(option)}>
          {getOptionLabel(option)}
        </MenuItem>
      ))}
      {filteredOptions.length === 0 && (
        <MenuItem disabled>No options found</MenuItem>
      )}
    </Menu>
  );
};

export default SearchableMenu;
