import { IconButton, SvgIconTypeMap, Tooltip } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Actions from 'components/Actions';
import Action from 'components/Actions/Action';
import { Row } from 'components/Layout';
import { isString } from 'lodash';
import React, { ComponentType, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  showAsDropdown?: boolean;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  actions: Array<{
    label?: string;
    icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
    link?: string | { pathname: string; state: Record<string, any> };
    external?: boolean;
    onClick?: (e?: any) => void;
    disabled?: boolean;
    visible?: boolean;
    color?: 'primary' | 'error' | 'default';
    tooltip?: string;
    tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
    actionComponent?: ComponentType<{
      onClick?: () => void;
      disabled?: boolean;
    }>;
  }>;
};

const ActionsWrapper: React.FC<
  PropsWithChildren<{ showAsDropdown?: boolean }>
> = ({ children, showAsDropdown }) =>
  showAsDropdown ? (
    <Actions>{children}</Actions>
  ) : (
    <Row justify="flex-end" centered>
      {children}
    </Row>
  );

const TableActions: React.FC<Props> = ({
  actions,
  showAsDropdown,
  className,
  size = 'large',
}) => {
  return (
    <div className={className}>
      <ActionsWrapper showAsDropdown={showAsDropdown}>
        {actions.map(
          (
            {
              label,
              color,
              icon: Icon,
              link,
              onClick,
              visible = true,
              disabled,
              tooltip,
              external,
              tooltipPlacement,
              actionComponent: ActionComponent,
            },
            index,
          ) => {
            if (!visible) return null;
            const key = `table-actions-${index}`;
            const onClickHandler = link || disabled ? undefined : onClick;

            const button = showAsDropdown ? (
              ActionComponent ? (
                <ActionComponent
                  onClick={onClickHandler}
                  disabled={!!disabled}
                  key={key}
                />
              ) : (
                <Action
                  key={key}
                  label={label ?? ''}
                  icon={Icon}
                  color={color}
                  disabled={disabled}
                  onClick={onClickHandler}
                />
              )
            ) : (
              <IconButton
                key={key}
                title={label}
                color={color}
                size={size}
                onClick={onClickHandler}
                disabled={disabled}
              >
                {Icon && <Icon />}
              </IconButton>
            );

            const linkComponent =
              external && isString(link) ? (
                <a
                  key={key}
                  href={link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {button}
                </a>
              ) : (
                <Link key={key} to={link ?? '#'}>
                  {button}
                </Link>
              );

            const action = link ? linkComponent : button;

            return tooltip ? (
              <Tooltip
                title={tooltip}
                key={`table-actions-${index}`}
                placement={tooltipPlacement || 'right'}
              >
                {disabled ? <div>{action}</div> : action}
              </Tooltip>
            ) : (
              action
            );
          },
        )}
      </ActionsWrapper>
    </div>
  );
};

export default TableActions;
