import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import api from 'lib/trpcClient';

type Props = {
  onSuccess?: () => void;
};

const useMutationOpts = (successMessage: string, onSuccess?: () => void) =>
  useHandleTrpcMutation()({
    successMessage,
    invalidationHandler: (utils) => {
      utils.account.invalidate();
      onSuccess?.();
    },
  });

export default function useTransactionTypesManagement({
  onSuccess,
}: Props = {}) {
  const deleteTransactionType = api.transactionType.remove.useMutation(
    useMutationOpts('Transaction type deleted successfully.', onSuccess),
  );
  const createTransactionType = api.transactionType.create.useMutation(
    useMutationOpts('Transaction type created successfully.', onSuccess),
  );
  const updateTransactionType = api.transactionType.update.useMutation(
    useMutationOpts('Transaction type updated successfully.', onSuccess),
  );

  const importTransactionTypes = api.transactionType.importList.useMutation(
    useMutationOpts('Transaction types imported successfully.', onSuccess),
  );

  return {
    createTransactionType,
    updateTransactionType,
    deleteTransactionType,
    importTransactionTypes,
  };
}
