import { KycProcessStatus } from 'further-types/kyc';

const formatExitedAtToLabel = (cart: {
  step?: number;
  adviserId?: string;
  kycStatusLabel?: string;
}) => {
  const { step, adviserId, kycStatusLabel } = cart;

  const steps = {
    1: 'Appropriateness (1/5)',
    2: `${simplifyKycStatusLabel(kycStatusLabel)} (2/5)`,
    3: 'Bank and tax details (3/5)',
    4: 'Subscription selection (4/5)',
    5: 'Manual payment (5/5)',
    6: 'Open Banking (5/5)',
  };

  return step ? steps[step] ?? '' : adviserId ? 'Invited, no action taken' : '';
};

const simplifyKycStatusLabel = (kycStatusLabel?: string) => {
  if (kycStatusLabel === KycProcessStatus.Failed) return 'KYC referral';
  return 'KYC start';
};

export default formatExitedAtToLabel;
