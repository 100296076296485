import { styled, Theme, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  centered?: boolean;
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
};

const spacingMap = {
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
  xl: 10,
};

const StyledRow = styled('div', {
  shouldForwardProp: (prop) =>
    !['fullWidth', 'centered', 'spacing', 'justify'].includes(prop.toString()),
})<Props>(
  ({
    theme,
    centered,
    breakpoint,
    spacing,
    justify = 'flex-start',
    fullWidth,
  }) => ({
    display: 'flex',
    justifyContent: justify,
    alignItems: centered ? 'center' : undefined,
    gap: theme.spacing(spacing),
    flexDirection: 'row',
    width: fullWidth ? '100%' : undefined,

    [theme.breakpoints.down(breakpoint)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& > *': {
        width: '100%',
      },
    },
  }),
);

const Row: React.FC<PropsWithChildren<Props>> = ({
  children,
  spacing,
  className,
  fullWidth,
  ...props
}) => {
  return (
    <StyledRow
      className={className}
      spacing={spacing ? spacingMap[spacing] : 0}
      fullWidth={fullWidth}
      {...props}
    >
      {children}
    </StyledRow>
  );
};

export default Row;
