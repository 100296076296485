import { GridRowModes, GridRowModesModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';

export default function useRowsEdit() {
  const [editedRowId, setEditedRowId] = useState<string>();
  const [rowsEditModel, setRowsEditModel] = useState<GridRowModesModel>({});

  const editRow = (rowId: string) => {
    setEditedRowId(rowId);
    setRowsEditModel({ [rowId]: { mode: GridRowModes.Edit } });
  };

  const cancelRowEdit = () => {
    if (!editedRowId) return;
    setRowsEditModel({
      [editedRowId]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    setEditedRowId(undefined);
  };

  const saveRow = () => {
    if (!editedRowId) return;
    setRowsEditModel({ [editedRowId]: { mode: GridRowModes.View } });
    setEditedRowId(undefined);
  };

  return { editedRowId, rowsEditModel, editRow, cancelRowEdit, saveRow };
}
