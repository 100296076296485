import { makeStyles } from 'tss-react/mui';
import { round, sumBy } from 'lodash';
import { useDisclosure } from 'further-ui/hooks';
import AlertDialog from 'components/AlertDialog';
import AmendmentsUpload from './AmendmentsUpload';
import Button from 'components/Button';

const useStyles = makeStyles()(() => ({
  flex: {
    marginTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonsFlex: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '1rem',
  },
  editedExitDisclosureContent: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem',
  },
}));

type Props = any; //TODO: define types

const ButtonsAndErrorModals: React.FC<Props> = ({
  exitDetails,
  shareholdings,
  setShareholdings,
  onSubmit,
  onCancel,
  isLoading,
  disableTable,
  onAmendmentsFileUpload,
  isBeingEdited,
}) => {
  const { classes } = useStyles();
  const cancelConfirmDisclosure = useDisclosure();
  const checkEachShareholdingAllocationDisclosure = useDisclosure();
  const checkTotalSharesDisclosure = useDisclosure();
  const checkAccruedFeesMaxDisclosure = useDisclosure();
  const checkTotalsToBeReturnedDisclosure = useDisclosure();
  const editedExitDisclosure = useDisclosure();
  const negativeAdditionalValueDisclosure = useDisclosure();

  const checkTotalSharesAllocatedMatchesSharesToBeSold = () => {
    const totalSharesAllocated = round(
      sumBy(shareholdings, (item) =>
        //@ts-expect-error
        Number(item.sharesAllocatedForSale),
      ),
      8,
    );
    if (totalSharesAllocated !== exitDetails.sharesToBeSold) {
      return false;
    }
    return true;
  };

  const checkEachShareholdingAllocation = () =>
    shareholdings.every((sh) => sh.sharesAllocatedForSale <= sh.noOfShares);

  const checkEachAccruedFeesBelowMax = () =>
    shareholdings.every(
      (sh) => sh.accruedFeesCharged <= sh.investorTotalAccruedFees,
    );

  const checkEachTotalToBeReturnedAboveZero = () =>
    shareholdings.every((sh) => sh.totalToBeReturned >= 0);

  const checkEachAdditionalReceivedValueAboveZero = () =>
    shareholdings.every((sh) => sh.additionalGrossReceivedValue >= 0);

  const validate = () => {
    if (!isBeingEdited && !checkEachShareholdingAllocation()) {
      checkEachShareholdingAllocationDisclosure.onOpen();
      return false;
    }
    if (!checkEachAccruedFeesBelowMax()) {
      checkAccruedFeesMaxDisclosure.onOpen();
      return false;
    }
    if (!checkEachTotalToBeReturnedAboveZero()) {
      checkTotalsToBeReturnedDisclosure.onOpen();
      return false;
    }
    if (!checkTotalSharesAllocatedMatchesSharesToBeSold()) {
      checkTotalSharesDisclosure.onOpen();
      return false;
    }

    if (isBeingEdited) {
      if (!checkEachAdditionalReceivedValueAboveZero()) {
        negativeAdditionalValueDisclosure.onOpen();
      } else {
        editedExitDisclosure.onOpen();
      }
    } else {
      onSubmit();
    }
  };

  return (
    <>
      <div className={classes.flex} style={{ justifyContent: 'space-between' }}>
        <AmendmentsUpload
          shareholdings={shareholdings}
          setShareholdings={setShareholdings}
          onAmendmentsFileUpload={onAmendmentsFileUpload}
          disableTable={disableTable}
          isBeingEdited={isBeingEdited}
        />

        <div className={classes.buttonsFlex}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={cancelConfirmDisclosure.onOpen}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={validate}
            disabled={!shareholdings.length}
            loading={isLoading}
          >
            Submit
          </Button>
        </div>
      </div>
      <AlertDialog
        title="Are you sure you want to cancel?"
        content="You will lose all the data you have entered."
        open={cancelConfirmDisclosure.isOpen}
        onClose={cancelConfirmDisclosure.onClose}
        onConfirm={() => {
          onCancel();
          cancelConfirmDisclosure.onClose();
        }}
      />
      <AlertDialog
        title="The number of shares you have allocated for sale varies from your originally requested figure."
        content="Please go back and adjust individual allocations or re-process this exit with a revised number of shares."
        open={checkTotalSharesDisclosure.isOpen}
        onClose={checkTotalSharesDisclosure.onClose}
        btnLabels={{ confirm: false, cancel: 'OK' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
      />
      <AlertDialog
        title="One or more investors has a sale allocation greater than their shareholding."
        content="Please adjust the individual allocations shown in red."
        open={checkEachShareholdingAllocationDisclosure.isOpen}
        onClose={checkEachShareholdingAllocationDisclosure.onClose}
        btnLabels={{ confirm: false, cancel: 'OK' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
      />
      <AlertDialog
        title="One or more investors has an amount of accrued fees entered that is higher than their their outstanding accrued fees."
        content="Please adjust the accrued fees charged shown in red."
        open={checkAccruedFeesMaxDisclosure.isOpen}
        onClose={checkAccruedFeesMaxDisclosure.onClose}
        btnLabels={{ confirm: false, cancel: 'OK' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
      />
      <AlertDialog
        title="One or more investors has an amount to be returned below zero."
        content="Please amend the accrued fees to be charged."
        open={checkTotalsToBeReturnedDisclosure.isOpen}
        onClose={checkTotalsToBeReturnedDisclosure.onClose}
        btnLabels={{ confirm: false, cancel: 'OK' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
      />
      <AlertDialog
        title="Please note"
        content={
          <span>
            One or more rows in this exit have a negative{' '}
            <i>additional received value</i> figure. If you proceed, negative
            amounts will be deducted from each relevant investor's cash balance.
          </span>
        }
        open={negativeAdditionalValueDisclosure.isOpen}
        onClose={negativeAdditionalValueDisclosure.onClose}
        onConfirm={() => {
          negativeAdditionalValueDisclosure.onClose();
          editedExitDisclosure.onOpen();
        }}
        btnLabels={{ confirm: 'Proceed', cancel: 'Go back' }}
        cancelBtnProps={{ color: 'primary' }}
      />
      <AlertDialog
        title="Please note"
        content={
          <div className={classes.editedExitDisclosureContent}>
            <p>Upon processing this change:</p>
            <p>
              (i) No future edit can reduce the{' '}
              <i>Received value share price</i> below its revised level
            </p>
            <p>
              (ii) Any future increase to the <i>Taxable value share price</i>{' '}
              will trigger a new tax event in each client's annual tax report
            </p>
          </div>
        }
        open={editedExitDisclosure.isOpen}
        onClose={editedExitDisclosure.onClose}
        onConfirm={() => {
          editedExitDisclosure.onClose();
          onSubmit();
        }}
        btnLabels={{ confirm: 'Proceed', cancel: 'Go back' }}
        cancelBtnProps={{ color: 'primary' }}
      />
    </>
  );
};

export default ButtonsAndErrorModals;
