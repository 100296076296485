import { Grid2 } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  pageId?: string;
  disableGrow?: boolean;
};

const Filter: React.FC<PropsWithChildren<Props>> = ({
  children,
  disableGrow,
}) => {
  return (
    <Grid2 size={{ xs: 12, md: disableGrow ? 'auto' : 'grow' }}>
      {children}
    </Grid2>
  );
};

export default Filter;
