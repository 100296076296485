import { useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'lib/trpcClient';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import { Step, StepLabel, Stepper } from '@mui/material';
import Spacing from 'components/Spacing';
import { useLayoutContext } from 'contexts/LayoutContext';
import StepAddAndAllocate from './StepAddAndAllocate';
import StepReviewAndConfirm from './StepReviewAndConfirm';
import FullPageSuccess from 'components/FullPageSuccess';
import { INCOME_OR_EXPENSE_ADD } from 'adminConstants/routes';
import FormWrapper from './FormWrapper';

enum Steps {
  AddAndAllocate,
  ReviewAndConfirm,
  Success,
}

const stepLabels = {
  [Steps.AddAndAllocate]: 'Add and allocate',
  [Steps.ReviewAndConfirm]: 'Review and confirm',
};

const pageTitle = 'Income and expenses: Process income and expenses';

const AddEditIncomeOrExpense: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [activeStep, setActiveStep] = useState<Steps>(Steps.AddAndAllocate);
  const { selectedVehicle, selectedVehicleId } = useLayoutContext();

  const contacts = api.contact.list.useQuery();
  const vehicleData = api.vehicle.listAllVehicles.useQuery({
    order: 'desc',
    orderBy: 'vehicleName',
  });

  const { data: incomeOrExpense, isLoading: incomeOrExpenseLoading } =
    api.incomeAndExpenses.get.useQuery(
      {
        vehicleId: selectedVehicleId ?? '',
        id: id ?? '',
      },
      {
        enabled: !!id,
      },
    );

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Firms', link: '/firm' },
    {
      label: id ? 'Update Income or Expense' : 'Add Income or Expense',
      link: '/',
      isActive: true,
    },
  ];

  if (activeStep === Steps.Success) {
    return (
      <PageContainer heading={pageTitle} breadcrumbs={breadcrumbs}>
        <FullPageSuccess
          title="Your transaction has been processed successfully"
          additionalText="The General Ledger has been updated and this transaction will be available to add into the next capital call/distribution (if applicable)."
          proceedText="Process capital call/distribution"
          //TODO: Add link to process capital call/distribution when it's implemented
          proceedLink="#"
          secondaryText="Add another income/expense"
          secondaryLink={INCOME_OR_EXPENSE_ADD}
        />
      </PageContainer>
    );
  }

  const loading =
    vehicleData.isLoading || contacts.isLoading || incomeOrExpenseLoading;

  return (
    <PageContainer
      heading={pageTitle}
      loading={loading}
      breadcrumbs={breadcrumbs}
    >
      <PageContent>
        <Spacing size="lg">
          <GridContainer>
            <Grid size={{ sm: 12, md: 12, lg: 8 }}>
              <Stepper activeStep={activeStep}>
                {Object.values(stepLabels).map((stepLabel) => {
                  return (
                    <Step key={stepLabel}>
                      <StepLabel>{stepLabel}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
          </GridContainer>
          <FormWrapper
            values={incomeOrExpense}
            vehicleId={selectedVehicleId ?? ''}
            baseCurrency={selectedVehicle?.baseCurrency}
          >
            {activeStep === Steps.AddAndAllocate && (
              <StepAddAndAllocate
                vehicles={vehicleData.data?.result ?? []}
                contacts={contacts.data ?? []}
                onCompletionStep1B={() => {
                  setActiveStep(Steps.ReviewAndConfirm);
                }}
              />
            )}
            {activeStep === Steps.ReviewAndConfirm && (
              <StepReviewAndConfirm
                id={id}
                vehicles={vehicleData.data?.result ?? []}
                onPreviousStep={() => {
                  setActiveStep(Steps.AddAndAllocate);
                }}
                onSuccess={() => {
                  setActiveStep(Steps.Success);
                }}
              />
            )}
          </FormWrapper>
        </Spacing>
      </PageContent>
    </PageContainer>
  );
};

export default AddEditIncomeOrExpense;
