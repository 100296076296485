import { forwardRef } from 'react';
import { MenuItem, Select, SelectProps, styled } from '@mui/material';

type Props = SelectProps & {
  options: Array<{ value: string; label: string }>;
  placeholder?: string;
};

const Placeholder = styled('p')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const AppSimpleSelect = forwardRef<HTMLDivElement, Props>(
  ({ options = [], placeholder, ...rest }, ref) => {
    return (
      <Select
        displayEmpty
        native={false}
        renderValue={(value) =>
          value ? (
            options.find((option) => option.value === value)?.label
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )
        }
        ref={ref}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  },
);

export default AppSimpleSelect;
