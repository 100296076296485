import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';
import InteractiveTable, {
  Row,
  useTableContext,
} from 'components/InteractiveTable';
import getColumns from './getColumns';
import Subtransactions from './Subtransactions';
import { PaginationState } from 'further-types/utils';
import { round, sumBy } from 'lodash';
import { Transaction } from './types';

const StyledTable = styled(InteractiveTable)({
  borderRadius: 0,
  border: 'none',
});

const StyledAccordion = styled(Accordion)({
  fontFamily: 'IBMPlexSans',
  border: 'none',
});

const StickyAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.fontSize,
  zIndex: 9,
  borderBottom: `1px solid ${theme.palette.borderColor?.dark}`,
  height: '44px',
  minHeight: '44px',

  '.section-accordion.Mui-expanded &': {
    position: 'sticky',
    top: '64px',
  },

  '&.Mui-expanded': {
    height: '44px',
    minHeight: '44px',
  },
  '& .MuiAccordionSummary-content': {
    margin: '10px 0',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
});

const getExpandedContent = (row: Row) => (
  <Subtransactions
    parentTransactionId={row.id ?? ''}
    subtransactions={row.subtransactions}
  />
);

type Props = {
  accountLabel: string;
  accountId: string;
  pagination: PaginationState;
  loading?: boolean;
  transactions: Array<Transaction>;
};

const Account: React.FC<Props> = ({
  accountLabel,
  transactions,
  pagination,
  loading,
}) => {
  const tableId = `general-ledger-${accountLabel}`;
  const { columnsConfig, handleConfigChange } = useTableContext();

  const rows = transactions.map((row) => ({
    ...row,
    isExpandable: !!row.subtransactions?.length,
  }));

  const columns = getColumns({
    accountLabel,
    totalIncVat: round(sumBy(transactions, 'amountIncVat'), 2),
    totalExVat: round(sumBy(transactions, 'amountExVat'), 2),
  });

  return (
    <StyledAccordion defaultExpanded>
      <StickyAccordionSummary
        aria-controls={`general-ledger-account-${accountLabel}`}
        id={`general-ledger-account-${accountLabel}`}
      >
        {accountLabel}
      </StickyAccordionSummary>
      <StyledAccordionDetails>
        <StyledTable
          id={tableId}
          columns={columns}
          rows={rows}
          pagination={pagination}
          disablePagination
          loading={loading}
          getExpandedContent={getExpandedContent}
          fullHeight
          onColumnsConfigChange={handleConfigChange}
          columnsConfig={columnsConfig}
        />
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default Account;
