import { InvestmentCompletionStatus } from 'further-types/investment';
import { InvestmentArchiveStatus } from 'further-types/investment';

export const STATUS_OPTIONS = [
  { label: 'Show all', value: InvestmentCompletionStatus.All },
  { label: 'Completed', value: InvestmentCompletionStatus.Completed },
  { label: 'Incomplete', value: InvestmentCompletionStatus.Incomplete },
];

export const ARCHIVE_OPTIONS = [
  { label: 'Show all', value: InvestmentArchiveStatus.All },
  { label: 'Not archived', value: InvestmentArchiveStatus.NotArchived },
  { label: 'Archived', value: InvestmentArchiveStatus.Archived },
];

export const STEPS_MAP = {
  1: 'Appropriateness (1/5)',
  2: 'KYC (2/5)',
  3: 'Bank and tax details (3/5)',
  4: 'Subscription selection (4/5)',
  5: 'Manual payment (5/5)',
  6: 'Open Banking (5/5)',
};
