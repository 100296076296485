import { styled, TextField } from '@mui/material';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import { useAdvisers } from 'hooks/data/adviser/useAdvisers';
import { useGetRole } from 'hooks/ui/useGetRole';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { Api } from 'further-types/adviser';

type Props = {
  adviserId?: string | null;
  onChange: (adviser: Api.Adviser | null) => void;
  placeholder?: string;
  fullWidth?: boolean;
  labelRenderer?: (option: Api.Adviser) => string;
  id?: string;
};

const Container = styled('div')(({ fullWidth }: { fullWidth: boolean }) => ({
  maxWidth: fullWidth ? '100%' : '300px',
}));

const AdviserSelect: React.FC<Props> = ({
  adviserId,
  onChange,
  fullWidth,
  placeholder,
  labelRenderer,
  id,
}) => {
  const { firmId } = useGetRole();
  const { data, isFetching } = useAdvisers({ params: { firmId } });

  const adviser = data?.adviser.find(
    (adviser) => adviser._id.toString() === adviserId,
  );

  const sortedOptions = useMemo(
    () => sortBy(data?.adviser, ['fullName']),
    [data?.adviser],
  );

  return !isFetching ? (
    <Container fullWidth={fullWidth}>
      <Autocomplete
        id={id}
        name={id}
        options={sortedOptions}
        getOptionLabel={
          labelRenderer ?? ((option) => `${option.fullName} (${option.email})`)
        }
        filterSelectedOptions
        value={adviser ?? null}
        onChange={(_event, adviser) => {
          onChange?.(adviser);
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
      />
    </Container>
  ) : (
    <span>Loading...</span>
  );
};

export default AdviserSelect;
