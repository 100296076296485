import {
  createTRPCReact,
  TRPCLink,
  type inferReactQueryProcedureOptions,
} from '@trpc/react-query';
import type { ApiRouter } from 'further-api/trpc/routes';
import { history } from '@redux/store';
import { observable } from '@trpc/server/observable';
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

export type ReactQueryOptions = inferReactQueryProcedureOptions<ApiRouter>;
export type ApiInputs = inferRouterInputs<ApiRouter>;
export type ApiResponses = inferRouterOutputs<ApiRouter>;

const api = createTRPCReact<ApiRouter>();

export const logoutLink: TRPCLink<ApiRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          const authorisationError = err.data as {
            forceLogout?: boolean;
          };
          if (authorisationError?.forceLogout) {
            history.replace(
              `${history.location.pathname}${history.location.search}#refresh`,
            );
          }

          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};

export const versionLink: TRPCLink<ApiRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          // Skip version check for file downloads
          const response = value.context?.response;
          if (response instanceof Response) {
            const contentType = response.headers.get('content-type');
            const contentDisposition = response.headers.get(
              'content-disposition',
            );

            if (
              contentType?.includes('application/octet-stream') ||
              contentType?.includes('application/pdf') ||
              contentDisposition?.includes('attachment')
            ) {
              observer.next(value);
              return;
            }
          }

          const apiVersion =
            value.context?.response instanceof Response
              ? value.context.response.headers.get('x-app-version')
              : undefined;
          const reloadAttempted = localStorage.getItem('reload-attempted');

          if (
            apiVersion !== process.env.REACT_APP_API_VERSION_HASH &&
            !reloadAttempted
          ) {
            localStorage.setItem('reload-attempted', 'true');
            window.location.reload();
          } else if (apiVersion === process.env.REACT_APP_API_VERSION_HASH) {
            localStorage.removeItem('reload-attempted');
          }

          observer.next(value);
        },
        error: observer.error,
        complete: observer.complete,
      });
      return unsubscribe;
    });
  };
};

export const TrcpProvider = api.Provider;

export default api;
