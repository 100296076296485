import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Table, { useStyles as useTableStyles } from 'components/Table';
import PageContainer from 'components/PageContainer';
import { getShareholdingUploads } from '@redux/actions/UploadShareHolding';
import {
  numberToCurrencyString,
  numberToDisplayString,
} from 'further-ui/utils';
import { usePagination } from 'hooks/ui/usePagination';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import PageContent from 'components/PageContent';

const ViewUploadsheet: React.FC = () => {
  const { classes: tableClasses } = useTableStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>({ result: [] });
  const { id } = useParams<{ id: string }>();
  const pagination = usePagination({
    id: `shareholdings-uploads-${id}`,
  });

  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: 'View upload shareholding', isActive: true },
  ];

  const columns = [
    {
      label: 'Investor name',
      key: 'fullName',
      sort: false,
      render: (elm) => elm?.investorId?.fullName,
    },
    {
      label: 'Firm',
      key: 'firmId',
      sort: false,
      render: (elm) => elm?.firmId?.firmName,
    },
    {
      label: 'Tranche',
      key: 'fundId',
      sort: false,
      render: (elm) => elm?.fundId?.fundName,
    },
    {
      label: 'Company name',
      key: 'companyId',
      sort: false,
      render: (elm) => elm?.companyId?.tradingName,
    },
    {
      label: 'Shares',
      key: 'noOfShare',
      render: (elm) => numberToDisplayString(elm?.noOfShare),
      sort: false,
    },
    {
      label: 'Amount',
      key: 'subscriptionAmount',
      render: (elm) => numberToCurrencyString(elm?.subscriptionAmount),
      sort: false,
    },
    {
      label: 'Final deal',
      key: 'finalDeal',
      sort: false,
      render: (elm) => (elm.finalDeal ? 'Yes' : ''),
    },
  ];

  useEffect(() => {
    const getList = async () => {
      setLoading(true);
      await handleApiRequest(async () => {
        const existingShareholdingUploads = await dispatch<any>(
          getShareholdingUploads({
            perPage: pagination.rowsPerPage,
            page: pagination.page,
            id,
          }),
        );
        setResponse(existingShareholdingUploads?.data?.data);
      });

      setLoading(false);
    };
    getList();
  }, [pagination.page, dispatch, pagination.rowsPerPage, location]);

  return (
    <Fragment>
      <PageContainer
        heading="Allocations: View uploaded shareholdings"
        breadcrumbs={breadcrumbs}
      >
        <PageContent>
          <Table
            columns={columns}
            onRequestSort={pagination.handleRequestSort}
            tablebody={response?.shareAllocationUploadLog || []}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            count={response?.total}
            pagination={true}
            loading={loading}
            TableFooter={
              <tfoot>
                <tr className={tableClasses.tFootRow}>
                  <td colSpan={5} />
                  <td className={tableClasses.tFootCell}>
                    {numberToDisplayString(response?.totalSharesAllocated)}
                  </td>
                  <td className={tableClasses.tFootCell}>
                    {numberToCurrencyString(response?.totalSubscriptionAmount)}
                  </td>
                  <td />
                </tr>
              </tfoot>
            }
            variant="nohover"
          />
        </PageContent>
      </PageContainer>
    </Fragment>
  );
};
export default ViewUploadsheet;
