import axios from 'axios';
import { ERROR_404 } from 'adminConstants/routes';
import { history } from '@redux/store';

const baseURL = `${process.env.REACT_APP_API_URL}/v1/admin/`;
const awsURL = `${process.env.REACT_APP_AWS_URL}`;

export const api = axios.create({
  baseURL,
});

export const setupAxiosInstance = (getToken: () => Promise<string>) => {
  api.interceptors.request.use(
    async (axiosConfig) => {
      const token = await getToken();

      const { url } = axiosConfig;
      // don't add any additional headers if the url being called is not the further API.
      // e.g. it could be a call to the S3 bucket to fetch a file
      if (
        url?.startsWith('http') &&
        !url.startsWith(baseURL) &&
        !url.startsWith(awsURL)
      )
        return axiosConfig;

      if (token) {
        axiosConfig.headers['Authorization'] = `Bearer ${token}`;
      }

      return axiosConfig;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (response) => {
      // Skip version check for file downloads
      if (
        response.headers['content-type']?.includes(
          'application/octet-stream',
        ) ||
        response.headers['content-type']?.includes('application/pdf') ||
        response.headers['content-disposition']?.includes('attachment')
      ) {
        return response;
      }

      const apiVersion = response?.headers?.['x-app-version'];
      const reloadAttempted = localStorage.getItem('reload-attempted');

      if (
        apiVersion !== process.env.REACT_APP_API_VERSION_HASH &&
        !reloadAttempted
      ) {
        localStorage.setItem('reload-attempted', 'true');
        window.location.reload();
      } else if (apiVersion === process.env.REACT_APP_API_VERSION_HASH) {
        localStorage.removeItem('reload-attempted');
      }

      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        history.push(ERROR_404);
      }

      if (error?.response?.data?.isLogout) {
        const currentPath = `${history.location.pathname}${history.location.search}`;
        history.replace(`${currentPath}#refresh`);
      }

      return Promise.reject(error);
    },
  );
};
