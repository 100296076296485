import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useDebounce } from 'usehooks-ts';
import Table, { TableActions } from 'components/Table';
import PageContainer from 'components/PageContainer';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import AlertDialog from 'components/AlertDialog';
import { useDisclosure } from 'further-ui/hooks';
import {
  numberToCurrencyString,
  numberToDisplayString,
} from 'further-ui/utils';
import useFilters from 'hooks/ui/useFilters';

import { deleteAdviser } from '@redux/actions/Adviser';
import { ADVISER_ADD } from 'adminConstants/routes';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import { useNotification } from 'hooks/ui/useNotification';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useAdvisers, useAdvisersExport } from 'hooks/data/adviser/useAdvisers';
import { useOrganisations } from 'hooks/data/organisation/useOrganisations';
import usePlatformInvites from 'hooks/data/adviser/useAdviserPlatformInvites';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import downloadFile from 'utils/downloadFile';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';
import { Delete, Edit, Email, Visibility } from '@mui/icons-material';
import { canDeleteAdviser } from 'utils/adviserPermissions';
import {
  createAdviserEditRoute,
  createAdviserViewRoute,
} from 'adminConstants/routes';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Advisers', isActive: true },
];

const Adviser: React.FC = () => {
  const dispatch = useDispatch();
  const { firmId, isSuperAdmin } = useGetRole();
  const [createPermission, deletePermission] = useGetPermissions([
    'create:advisers',
    'delete:advisers',
  ]);

  const { success, error } = useNotification();

  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteDisclosure = useDisclosure();
  const handleApiRequest = useApiRequestHandler();

  const pagination = usePagination({
    orderBy: 'fullName',
    order: 'asc',
    id: 'advisers-list',
  });
  const { filters, handleChangeFilters } = useFilters('advisers-list', {
    onFiltersChange: pagination.toFirstPage,
  });

  const [search, setSearch] = useState(filters?.search || '');
  const debouncedSearch = useDebounce(search, 300);
  useEffect(() => {
    handleChangeFilters({ search: debouncedSearch });
  }, [debouncedSearch]);

  const params = {
    search: filters.search,
    organisationId: filters.organisationId?._id,
    type: pagination.order,
    keyName: pagination.orderBy,
    firmId,
  };

  const advisers = useAdvisers({
    params: {
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      ...params,
    },
  });
  const { exportAdviser, isExportLoading } = useAdvisersExport({
    params: { ...params },
  });

  const organisations = useOrganisations({
    firmId,
  });

  const { resendInvite } = usePlatformInvites({
    onResendInviteSuccess: () => {
      advisers.refetch();
      success('Adviser invitation sent successfully.');
    },
  });

  const handleExport = async () => {
    try {
      const data = await exportAdviser();
      if (data) {
        downloadFile(data, `Adviser-${Date.now()}.xlsx`);
      }
    } catch (exportError: any) {
      if (exportError) {
        error(exportError.message);
        return;
      }
    }
  };

  const columns = [
    { label: 'Name', key: 'fullName' },
    { label: 'Email', key: 'email' },
    {
      label: 'Organisation',
      key: 'organisationName',
      sort: false,
      render: (elm) => elm?.organisationId?.name,
    },
    {
      label: 'Clients',
      key: 'investorsCount',
      sort: false,
      render: (elm) => numberToDisplayString(elm.investorsCount),
    },
    {
      label: 'Subscriptions',
      key: 'investmentsCount',
      sort: false,
      render: (elm) => numberToDisplayString(elm.investmentsCount),
    },
    {
      label: 'Total initial value',
      key: 'initialValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.initialValue),
    },
    {
      label: 'Total current value',
      key: 'currentValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.currentValue),
    },
    {
      label: 'Realised value',
      key: 'realisedValue',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.realisedValue),
    },
    {
      label: 'Advice fees',
      key: 'adviceFees',
      sort: false,
      render: (elm) => numberToCurrencyString(elm.adviceFees),
    },
    {
      label: 'Actions',
      sort: false,
      key: 'actions',
      render: (adviser) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              label: 'View',
              icon: Visibility,
              color: 'primary',
              link: createAdviserViewRoute(adviser?._id),
            },
            {
              label: 'Edit',
              icon: Edit,
              color: 'primary',
              link: createAdviserEditRoute(adviser?._id),
            },
            {
              disabled: !canDeleteAdviser(
                isSuperAdmin,
                adviser,
                //@ts-expect-error
                firmId,
                deletePermission,
              ),
              tooltip: !canDeleteAdviser(
                isSuperAdmin,
                adviser,
                //@ts-expect-error
                firmId,
                deletePermission,
              )
                ? 'This adviser is linked with one or more subscriptions or more than one firm, so cannot be deleted.'
                : '',
              label: 'Delete',
              icon: Delete,
              color: 'error',
              onClick: () => deleteDisclosure.stageAction({ id: adviser._id }),
            },
            {
              label: 'Resend Invite',
              icon: Email,
              color: 'primary',
              tooltip: isSuperAdmin
                ? 'Resending adviser invites is only possible for firm admin users.'
                : 'Resend invitation to this adviser.',
              disabled: isSuperAdmin || resendInvite.isPending,
              onClick: (e) => {
                e.preventDefault();
                resendInvite.mutate(adviser._id);
              },
            },
          ]}
        />
      ),
    },
  ];

  const onDeleteAdviser = async () => {
    const adviserId = deleteDisclosure.actionProps.id;
    setIsDeleting(true);
    await handleApiRequest(async (showSuccess) => {
      // @ts-expect-error dispatch to be removed soon
      const response = await dispatch(deleteAdviser(adviserId));
      if (response) {
        showSuccess('Adviser deleted');
        deleteDisclosure.onClose();
        advisers.refetch();
      } else {
        setDisableDeleteBtn(true);
      }
    });
    setIsDeleting(false);
  };

  return (
    <PageContainer heading="Advisers" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="advisers-list"
          buttons={[
            {
              label: 'Add Adviser',
              link: ADVISER_ADD,
              visible: createPermission,
            },
            {
              label: 'Export',
              loading: isExportLoading,
              onClick: handleExport,
            },
          ]}
        >
          <Filter>
            <TextField
              type="search"
              name="search"
              placeholder="Search by email, name, FCA number"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </Filter>
          <Filter>
            <Autocomplete
              options={organisations?.data?.organisation}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              value={filters.organisationId}
              onChange={(_, newValue) => {
                handleChangeFilters({ organisationId: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select organisation"
                />
              )}
            />
          </Filter>
        </PageFilters>

        <Table
          columns={columns}
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={advisers.data?.adviser ?? []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={advisers.data?.totalAdviser ?? 0}
          pagination={true}
          loading={advisers.isFetching}
          emptyMessage="No advisers found"
          variant="nohover"
        />
      </PageContent>

      <AlertDialog
        onClose={deleteDisclosure.onClose}
        open={deleteDisclosure.isOpen}
        title="Are you sure you wish to delete this adviser?"
        content="This cannot be undone and all information relating to the adviser will be lost."
        btnLabels={{ confirm: 'Delete adviser', cancel: 'Go back' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
        confirmBtnProps={{
          variant: 'outlined',
          color: 'primary',
          disabled: isDeleting || disableDeleteBtn,
        }}
        onConfirm={onDeleteAdviser}
      />
    </PageContainer>
  );
};
export default Adviser;
