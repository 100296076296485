import { Divider } from '@mui/material';
import FieldRow from 'components/FormElements/FieldRow';
import { pluralize } from 'utils/pluralize';
import RecordView from 'components/RecordView';

type Props = any; //TODO: define props

const Summary: React.FC<Props> = ({
  data,
  classes,
  selectedTranchesOrCompanies,
  isTrancheSelected,
}) => {
  return (
    <>
      <RecordView>
        <FieldRow title={'Update type'}>Custom update</FieldRow>

        <FieldRow
          title={
            data.sendToAllUsers
              ? 'Recipients'
              : isTrancheSelected
              ? 'Tranches'
              : 'Companies'
          }
        >
          {data.sendToAllUsers
            ? 'Sent to all registered users'
            : selectedTranchesOrCompanies?.join(', ') || '-'}
        </FieldRow>
        <FieldRow title="Number of recipients">
          {data?.numberOfRecipients || '-'}
        </FieldRow>
      </RecordView>
      <Divider className={classes.divider} />
      <RecordView>
        <FieldRow title="Subject line">{data?.subject || '-'}</FieldRow>
        <FieldRow title="Body title">{data?.title || '-'}</FieldRow>
        <FieldRow title="Body text">
          <>
            <div>Dear $investorfirstname,</div>
            <br />
            <div
              className="text-editor"
              dangerouslySetInnerHTML={{
                __html: data?.emailBody,
              }}
            />
            <br />
            Thanks,
            <br />
            {data?.accountManager?.name}
          </>
        </FieldRow>

        {data.attachments?.length > 0 && (
          <FieldRow
            title={`${pluralize('Attachment', data?.attachments?.length)}`}
          >
            {data?.attachments?.map((attachment, index) => {
              return (
                <div key={index} className="mb-1">
                  <a
                    key={index}
                    href={attachment?.preview || attachment?.url}
                    rel="noreferrer"
                    target={'_blank'}
                    className={classes.attachmentLink}
                  >
                    {attachment?.title || 'Attachment'}
                  </a>
                </div>
              );
            })}
          </FieldRow>
        )}
      </RecordView>
    </>
  );
};

export default Summary;
