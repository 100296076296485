import SearchField from 'components/SearchField';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import PageFilters, { Filter } from 'components/PageFilters';

type FiltersType = {
  search?: string;
  startDate?: Date;
  finishDate?: Date;
};

type Props = {
  filters: FiltersType;
  onFiltersChange: (filters: FiltersType) => void;
  onBulkExport: () => void;
  showBulkExportButton: boolean;
  isBulkExportInProgress: boolean;
};

const Filters: React.FC<Props> = ({
  filters,
  onFiltersChange,
  showBulkExportButton,
  onBulkExport,
  isBulkExportInProgress,
}) => {
  return (
    <PageFilters
      pageId="investment-transfer-list"
      buttons={[
        {
          visible: showBulkExportButton,
          label: 'Download transfer statements',
          loading: isBulkExportInProgress,
          onClick: onBulkExport,
        },
      ]}
    >
      <Filter>
        <SearchField
          variant="outlined"
          placeholder="Search by transferor or transferee name"
          allowEmptyQuery
          autoSearchOnDebounce
          onSearch={(search: string) => onFiltersChange({ search })}
          fullWidth
        />
      </Filter>
      <Filter>
        <MuiDateRangePicker
          dateRange={{
            startDate: filters.startDate,
            endDate: filters.finishDate,
          }}
          onDateRangeChange={({ startDate, endDate }) => {
            onFiltersChange({
              startDate,
              finishDate: endDate,
            });
          }}
          placeholder="Select date"
        />
      </Filter>
    </PageFilters>
  );
};

export default Filters;
