import {
  FormControl,
  Select,
  ListSubheader,
  MenuItem,
  FormHelperText,
  BaseSelectProps,
} from '@mui/material';
import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

interface Props
  extends Omit<BaseSelectProps, 'error' | 'fullWidth' | 'placeholder'> {
  accountOptions: Array<{ label: string; value?: string | null }>;
  error?: FieldError;
}

const AccountSelector: React.FC<Props> = forwardRef(
  ({ accountOptions, error, ...rest }, ref) => {
    return (
      <FormControl fullWidth error={!!error}>
        <Select {...rest} placeholder="Select account" fullWidth ref={ref}>
          {accountOptions.map((option) =>
            !option.value ? (
              <ListSubheader key={option.label}>{option.label}</ListSubheader>
            ) : (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ),
          )}
        </Select>
        {!!error && (
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        )}
      </FormControl>
    );
  },
);

export default AccountSelector;
