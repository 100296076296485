import { ApiResponse } from 'further-types/api';
import { useMutation } from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import { Api as AuthApi } from 'further-types/auth';
import { api } from 'lib/httpClient';

export default function useResendVerificationLinkMutation() {
  const { success, error } = useNotification();

  return useMutation({
    mutationFn: async (data: {
      id: string;
      userType: number;
      domain?: string;
    }) => {
      return await api.post<ApiResponse<AuthApi.ResendVerificationResponse>>(
        'investor/resend-verification-link',
        data,
      );
    },
    onSuccess: async () => {
      success('Email verification link sent successfully.');
    },
    onError: (err: any) => {
      error(err.response.data.responseMsg);
    },
  });
}
